import { useEffect, useState } from 'react';
import { Grid, Slider } from '@mui/material';

function SliderDate(props) {
  const [sliderValue, setSliderValue] = useState(null);

  useEffect(() => {
    if (props.dates?.length > 0 && !sliderValue) {
      setSliderValue(props.dates);
    }
  }, [props.dates, sliderValue, props.market]);
  useEffect(() => {
    if (!props.market && !sliderValue) {
      setSliderValue([new Date('2023-01-01').getTime(), new Date('2023-12-30').getTime()]);
    }
  }, []);
  const handleChange = (event, newValue) => {
    const [newMinDate, newMaxDate] = newValue;

    // Enforce minimum and maximum date constraints
    if (newMinDate <= newMaxDate) {
      if (newMinDate < sliderValue[0]) {
        setSliderValue([newMinDate, sliderValue[1]]);
      } else if (newMaxDate > sliderValue[1]) {
        setSliderValue([sliderValue[0], newMaxDate]);
      } else {
        setSliderValue([newMinDate, newMaxDate]);
      }
    } else {
      setSliderValue([sliderValue[0], sliderValue[1]]);
    }
    props.onChange(newValue);
  };

  const valuetext = (value) => {
    const date = new Date(value);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate;
  };

  const style = {
    color: '#02A78B',
    marginLeft: 10,
  };
  const divStyle = {
    marginLeft: 10,
    marginRight: 10,
    maxWidth: 300,
  };
  const getDateString = (value) => {
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  return (
    <Grid container xs={divStyle}>
      <Slider
        value={sliderValue}
        onChange={handleChange}
        min={props.minDate ? new Date(props.minDate).getTime() : new Date('2023-01-01').getTime()}
        max={props.maxDate ? new Date(props.maxDate).getTime() : new Date('2023-12-30').getTime()}
        valueLabelDisplay="auto"
        valueLabelFormat={valuetext}
        disableSwap
        style={style}
      />
      <Grid container>
        <Grid lg={6} sx={{ display: 'flex', textAlign: 'start', fontSize: '9pt' }}>
          {getDateString(new Date(props.minDate ? props.minDate : '2023-01-01'))}
        </Grid>
        <Grid lg={6} sx={{ display: 'flex', textAlign: 'end', justifyContent: 'end', fontSize: '9pt' }}>
          {getDateString(new Date(props.maxDate ? props.maxDate : '2023-12-30'))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SliderDate;
