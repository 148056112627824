import PropTypes from 'prop-types';
// @mui

import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, ListItemText } from '@mui/material';
// utils
import { NavLink as RouterLink } from 'react-router-dom';
import { bgBlur } from '../../../utils/cssStyles';
// components

import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
import Nav from '../nav';
import { StyledNavItem } from '../../../components/nav-section/styles';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  // },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const user = useSelector((state) => state.user.user);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box>
          <Nav />
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {/* <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <LanguagePopover />
          <NotificationsPopover />
      
        </Stack> */}
      <div
      style={{
        columnGap: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '20px',

      }}
    >
      <StyledNavItem
        component={RouterLink}
        to={'/dashboard/farm'}
        sx={{
          padding: 1,
          fontSize:'17px',
          '&.active': {
            color: '#02A78B',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}

        <ListItemText disableTypography primary={'Farm'} />

        {/* {info && info} */}
      </StyledNavItem>
    </div>
        <Typography
          className="text-capitalize"
          variant="body1"
          sx={{ color: '#000000', mr: 3, textTransform: 'capitalize' }}
        >
          {user?.firstName}
        </Typography>

        <AccountPopover />
      </StyledToolbar>
    </StyledRoot>
  );
}
