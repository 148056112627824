import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { Autocomplete, Box, List, TextField, Card, MenuItem, useTheme } from '@mui/material';

const CitySelect = ({value, onChange, country,selectedValue,onSelectValue }) => {
  const theme = useTheme();
  //   const [country, setCountry] = useState('');
 
//   const [value, onChange] = useState('');
const [city, setCity] = useState('');
//   const handleSelect = async (value) => {
//     // alert(JSON.stringify(value))
//     // alert('e')
//     onChange(value.description);

//     const results = await geocodeByAddress(value.mainText);
//     const latLng = await getLatLng(results[0]);
//     setCity({ name: value.description, latLng });
//     // onChange({ name: value.description, latLng });
//   };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      searchOptions={{ types: ['(cities)'], componentRestrictions: { country } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        suggestions = suggestions && suggestions?.length > 0 ? suggestions : [];

        return (
          <>
            <Autocomplete
              disablePortal
              options={suggestions}
              sx={noOutline}
              value={city.name}
              renderInput={(params) => {
                const value = params.inputProps.value
                delete params.inputProps.value;
                delete params.inputProps.onChange;
               
                return (
                  <TextField
                    autoFocus={false}
                    sx={noOutline2}
                    placeholder="Enter city name"
                  
                    {...params}
                    inputProps={getInputProps({
                      ...params.inputProps,
                      style: {
                        height: 10,
                        // backgroundColor:'red'
                      },
                      placeholder: 'Enter city name',
                      className: 'location-search-input',
                      autoComplete: 'new-password', // disable autocomplete and autofill,
                      
                    })}
                    onChange={(e)=>{onChange(e.target.value)}}
                    value={value}
                    size="small"
                  />
                );
              }}
         
              onChange={(event, newValue) => {
                onSelectValue(newValue)
                // alert(JSON.stringify(newValue.mainText .secondaryText))
                // onChange(newValue.description);
              }}
              getOptionLabel={(option) => option?.description}
              renderOption={(props, option) => (
                <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.formattedSuggestion.mainText} {option.formattedSuggestion.secondaryText}
                </Box>
              )}
            />
          </>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default CitySelect;
const noOutline = {
  height: 40,
  outline: 'none',
  '& .MuiInputBase-root': {
    height: 40,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #484850',
    borderRadius: '5px',
  },
};
const noOutline2 = {
  width: 180,
  height: 40,
  marginTop: 1,
  outline: 'none',
  '&.Mui-focused .fieldset': { border: 'none' },
  '& .MuiInputBase-root': {
    height: 40,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #484850',
    borderRadius: '5px',
  },
};
