import React from 'react';
import { createTheme, Tooltip} from '@mui/material';
import { makeStyles } from '@mui/styles';


const theme = createTheme()
const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '300px', // Adjust the width as per your requirement
    backgroundColor: '#ffffff', // Set the background color to white
    color: 'black', // Set the text color
    fontSize: '14px', // Set the font size
    padding: '0px', // Set the padding
    borderRadius: '8px', // Set the border radius,
    display:'flex',
    alignItems:'center', // Set the
    justifyContent: 'center', // Set the
    fontWeight:'300'
  },
}));

const LargeTooltip = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
  
      // title={
      //   <div className={classes.tooltip}>
      //     {/* Place your additional information or content here */}
      //    <div style={{textAlign:'center'}}> <span style={{fontSize:'14px'}}>
      //     Prediction Uncertainty
      //     </span>
      //       <br/>
      //     <span style={{fontSize:'11px'}}>
      //     9.4-12.9% risk
      //     </span></div>

      //   </div>
      // }
      placement="top"
    
      PopperProps={{
        sx: {
          backgroundColor: '#ffffff', // Set the background color of the tooltip
          '& .MuiTooltip-arrow': {
            color: '#ffffff', // Set the text color of the tooltip
            // border:'1px solid #ededed'
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#ffffff', // Set the background color of the tooltip
            color: 'black', // Set the text color of the tooltip
            fontSize: '14px', // Set the font size of the tooltip
            padding: '12px', // Set the padding of the tooltip
            borderRadius: '8px', // Set the border radius of the tooltip
            boxShadow: theme.shadows[1], // Apply a box shadow to the tooltip
          },
        },
      }}
      arrow
    
    >
      <span>{props.title}</span>
    </Tooltip>
  );
};

export default LargeTooltip;
