/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, styled, Typography, Paper, Button, TextField, Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import MultiSelect from '../../components/MultiSelect';
// import MultiSelect from '../components/MultiSelect';
import Checked from '../assets/images/Checked.png';
import Star from '../assets/images/Star.png';
import Warning from '../assets/images/Warning.png';
// import ListCard from '../../components/ListCard';
import ListCard from '../components/ListCard';
import data from '../utils/mockData';

import SliderDate from '../components/slider-date-filter';
import { addCrop, setLoading, stopLoading } from '../store/farms';
import MultiSelect from '../components/chip-multi-select';
import { addCropData } from '../firebase/fucntions';
import MultiColorRangeInput from '../components/RangeWithColors';

export default function Step2(props) {
  console.log(data);
  const dispatch = useDispatch();

  const [selectedCrop, setSelectedCrop] = useState([]);
  const [index, setIndex] = useState(null);
  const gardefilters = [
    { value: 'A', label: 'A', isselected: false },
    { value: 'B', label: 'B', isselected: false },
    { value: 'C', label: 'C', isselected: false },
  ];
  const user = useSelector((state) => state.user.user);
  const [listtofilter2, setlisttofilter2] = useState([...data.Varieties]);
  const [arrayfilter2, setarrayfilter2] = useState([]);
  const [allData, setAllData] = useState([]);
  const history = useNavigate();
  const location = useLocation();
  console.log(location);
  const hashParams = new URLSearchParams(location.hash.slice(1));
  // const data = hashParams.get('data');

  useEffect(() => {
    setIndex(location.hash.replace(/^#/, ''));
  }, [location]);

  useEffect(() => {
    if (arrayfilter2.length > 0) {
      const targetNames = arrayfilter2;
      const crops = data.Varieties?.filter((crop) => {
        console.log(crop.name);
        return targetNames.includes(crop.value);
      });
      setAllData(crops);
    } else {
      setAllData(data.Varieties);
    }
  }, [arrayfilter2]);

  const handleEdit = async () => {
    const promises = selectedCrop.map(async (data) => {
      await addCropData(user.uid, location.state.uid, data).then((id) => {
        dispatch(addCrop(data.Area, data.Pictures, data.value, data.weekStart2, location.state.uid, id));
        data.name = data.Variety;
      });
    });

    try {
      dispatch(setLoading());
      await Promise.all(promises).then(() => {
        dispatch(stopLoading());
        history('/dashboard/farm');
      });
      // Handle the resolved values here, if needed
      console.log('All promises resolved successfully');
    } catch (error) {
      // Handle errors from any of the promises
      console.error('Error in Promise.all:', error);
    }
  };
  // const allFarms = dispatch(getFarms('i3XD5nLObZYCjvUxrMNMhsqHGQA2'));
  // console.log(dispatch(getFarms('i3XD5nLObZYCjvUxrMNMhsqHGQA2')));

  const products = data.Varieties;

  const change = (bool, data) => {
    if (bool) {
      setSelectedCrop((prev) => [...prev, data]);
    } else {
      if (setSelectedCrop.length > 0) {
        setSelectedCrop(
          selectedCrop.filter((person) => {
            return person.label !== data.label;
          })
        );
      }
      console.log();
    }
  };

  const remove = (data) => {
    setSelectedCrop(
      selectedCrop.filter((person) => {
        return person !== data;
      })
    );
  };

  const onChange = (val, data) => {
    data.Area = parseInt(val, 10);
  };

  const [listOfFilter, setListOfFilter] = React.useState([]);
  const [listOfCropFilter, setListOfCropFilter] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const farmData = useSelector((state) => state.farms);
  const [plotSelectedFilters, setPlotSelectedFilters] = React.useState([]);
  const [cropSelectedFilters, setCropSelectedFilters] = React.useState([]);
  const [ratingSelectedFilters, setRatingSelectedFilters] = React.useState([]);
  const [minWeek, setMinWeek] = React.useState(0);
  const [maxWeek, setMaxWeek] = React.useState(52);
  const [arrayTypeCrop, setArrayTypeCrop] = React.useState([]);

  useEffect(() => {
    const arrayTypeCropTemp = [];
    setListOfFilter(allData);
    setFilteredData(allData);
    allData.forEach((crop) => {
      const cropType = crop.value.split(',')[0].trim();
      console.log(cropType);
      const typeLabel = cropType;
      const typeExists = arrayTypeCropTemp.some((typeObj) => typeObj.label === typeLabel);
      if (!typeExists) {
        arrayTypeCropTemp.push({ label: typeLabel });
      }
      setArrayTypeCrop(arrayTypeCropTemp);
      crop.type = cropType;
    });
  }, [allData]);

  useEffect(() => {
    const arrayTypeCropTemp = [];
    allData.forEach((crop) => {
      const cropType = crop.value.split(',')[0].trim();

      const typeLabel = cropType;
      const typeExists = arrayTypeCropTemp.some((typeObj) => typeObj.label === typeLabel);
      if (!typeExists) {
        arrayTypeCropTemp.push({ label: typeLabel });
      }
      setArrayTypeCrop(arrayTypeCropTemp);
      crop.type = cropType;
    });
    const array = [];
    cropSelectedFilters.forEach((element1) => {
      if (!element1.label.includes(','))
        allData.forEach((element2) => {
          if (element2.label.includes(element1.label)) {
            array.push(element2);
          }
        });
      setArrayTypeCrop([...arrayTypeCropTemp, ...array]);
    });

    setFilteredData(filterFarmsByWeek(onFilterRatingChange(filterCrops(allData))));
    setListOfCropFilter(allData);
  }, [ratingSelectedFilters, plotSelectedFilters, cropSelectedFilters, minWeek, maxWeek, allData]);
  function filterFarmsByWeek(farms) {
    return farms;
  }

  const checkCropLabel = (array, label) => {
    console.log(array);
    const hasLabel = array.some((obj) => obj.label === label);
    const comma = `${label.toString()},`;
    const hasLabelComma = array.some((obj) => obj.label.includes(comma));
    if (hasLabel && hasLabelComma) {
      return { type: label, bool: true };
    }
    return { bool: false };
  };

  const filterCrops = (filteredData) => {
    if (cropSelectedFilters.length > 0) {
      const filteredCrops = filteredData.filter((crop) => {
        const cropType = crop.value.split(',')[0].trim();
        const checkCropLabelValue = checkCropLabel(cropSelectedFilters, cropType);

        if (checkCropLabelValue.bool) {
          // alert(JSON.stringify(checkCropLabelValue))
          return cropSelectedFilters.some((filter) => crop.value === filter.label);
        }
        return cropSelectedFilters.some((filter) => crop.value.includes(filter.label));
      });
      return filteredCrops;
    }
    return filteredData;
  };
  const onFilterRatingChange = (filteredData) => {
    if (ratingSelectedFilters.length > 0) {
      const filteredFarms = filteredData.filter((farm) => {
        return ratingSelectedFilters.some((filter) => filter.label === farm.rating);
      });
      return filteredFarms;
    }
    return filteredData;
  };
  function getWeek(date) {
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const diff = date - yearStart + (yearStart.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    const week = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return week;
  }
  const onDateChange = (date) => {
    const minWeek = getWeek(new Date(date[0]));
    const maxWeek = getWeek(new Date(date[1]));
    setMinWeek(minWeek);
    setMaxWeek(maxWeek);
    console.log(minWeek, maxWeek);
  };

  const onChangeRange = (value, data) => {
    data.weekStart2 = parseInt(value, 10);
    // setData({ ...props.data, weekStart2: parseInt(value, 10) });
    // console.log(props.data);
  };

  function getCurrentWeekNumber() {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const currentWeek = Math.ceil(((now - startOfYear) / 86400000 + startOfYear.getDay() + 1) / 7);
    return currentWeek;
  }


  function getRandomValue() {
    const minValue = 45;
    const maxValue = 95;
    return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  }
  function getRandomValueRecommended() {
    const minValue = 67;
    const maxValue = 95;
    return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  }

  function getStatusColor(value) {
    if (value >= 0 && value <= 50) {
      return 'error'; // 'error' is equivalent to 'danger' in some Material-UI versions
    }
    if (value > 50 && value <= 75) {
      return 'warning';
    }
    return 'primary';
  }

  
  const randomValueFirst = props.index < 3 ? getRandomValueRecommended() : getRandomValue();
  const colorVariantFirst = getStatusColor(randomValueFirst);
  const randomValueSecond = props.index < 3 ? getRandomValueRecommended() : getRandomValue();
  const colorVariantSecond = getStatusColor(randomValueSecond);
  const randomValueThird = props.index < 3 ? getRandomValueRecommended() : getRandomValue();
  const colorVariantThird = getStatusColor(randomValueThird);


  return (
    <div style={{ overflow: 'hidden' }}>
      <Grid container spacing={4} sx={{ ...gridStyles, ...tallGrid }}>
        <Grid item xs={11} md={2}>
          <Contents>
            <Typography sx={typographyStyle} variant="span">
              Crops
            </Typography>
            <MultiSelect onFilterChange={setCropSelectedFilters} listOfFilterProp={arrayTypeCrop} />
          </Contents>

          <Contents>
            <Typography variant="span">Ratings</Typography>
            <MultiSelect onFilterChange={setRatingSelectedFilters} listOfFilterProp={ratingFilters} rate />
          </Contents>
          {/* <Contents>
          <Typography variant="span">Date Range</Typography>
          <SliderDate onChange={onDateChange} />
        </Contents> */}
        </Grid>
        <Grid item xs={11} md={7} sx={{ overflow: 'auto' }}>
          <div style={{ overflow: 'auto', maxHeight: '100vh' }}>
            {filteredData.map((product, i) => (
              <ListCard change={change} index={i} data={product}
              
 randomValueFirst=  {randomValueFirst}
colorVariantFirst=  {colorVariantFirst}
randomValueSecond=  {randomValueSecond}
colorVariantSecond=  {colorVariantSecond}
randomValueThird=  {randomValueThird}
colorVariantThird=  {colorVariantThird}
/>
            ))}
          </div>
        </Grid>
        <Grid item xs={11} justifyContent={'center'} md={3} style={{ color: '#75918D', fontSize: '12pt' }}>
          <Grid container>
            <Grid item xs={6} md={6}>
              {' '}
              <Grid container>
                <CheckBoxIcon src={Checked} alt="dskfjh" style={{ marginRight: '10px' }} /> Selected Crops:{' '}
                {selectedCrop.length} crops
              </Grid>
            </Grid>
          </Grid>
          {selectedCrop.map((data, index) => (
            <Grid container sx={{ display: 'flex' }} alignItems={'center'}>
              <Grid md={1} item>
                <img alt="" src={Star} style={{ marginBottom: '2px' }} />
              </Grid>
              <Grid md={6} item style={{ lineHeight: '15px', fontSize: '10pt' }}>
                <p
                  className="d-flex align-items-center"
                  style={{
                    fontSize: '11pt',
                    fontWeight: '600',
                    marginTop: '15px',
                    lineHeight: '25px',
                    marginBottom: '3px',
                    color: 'black',
                    width: '230px',
                    // lineHeight: '18px',
                  }}
                >
                  {data.label}
                </p>
                {data.Variety}
              </Grid>
              <Grid item md={4}>
                <Grid container>
                  <Grid md={10} item>
                    <Grid container sx={{ fontSize: '10pt' }}>
                      {location.state.farmName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <TextField
                    sx={{ width: 50 }}
                    placeholder={'0'}
                    defaultValue={'0'}
                    onChange={(event) => {
                      onChange(event.target.value, data);
                    }}
                  />
                  <Box
                    sx={{
                      fontStyle: 'italic',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 2,
                      fontSize: '15pt',
                    }}
                  >
                    Ha
                  </Box>
                </Grid>
              </Grid>
              <Grid md={8} item>
                <MultiColorRangeInput
                  startWeek={getCurrentWeekNumber()}
                  yellowWidth={data?.early?.percentage ? data.early.percentage : 30}
                  greenWidth={data?.optimal?.percentage ? data.optimal.percentage : 40}
                  redWidth={data?.late?.percentage ? data.late.percentage : 15}
                  greyWidth={0}
                  data={data}
                  canChange
                  onChange={onChangeRange}
                />
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            onClick={() => {
              handleEdit();
            }}
            sx={buttonStyle}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
const buttonStyle = {
  color: 'white',
  backgroundColor: '#02A78B',
  height: 40,
  marginTop: 5,
  width: 140,
  '&:hover': {
    color: '#02A78B',
    backgroundColor: '#fff',
    border: '1px solid #02A78B',
  },
};
const ratingFilters = [
  {
    label: '1',
  },
  {
    label: '2',
  },
  {
    label: '2',
  },
  {
    label: '3',
  },
  {
    label: '4',
  },
  {
    label: '5',
  },
];
const gridStyles = {
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 1,
  paddingBottom: 1,
  paddingLeft: 0,
};

const Contents = styled(Paper)(({ theme }) => ({
  marginBottom: 20,
}));

const typographyStyle = {
  fontWeight: '350',
  marginBottom: 5,
};
const tallGrid = {
  height: '100%',
};
