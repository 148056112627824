import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import store from './store';
import data from './utils/mockData';
import './App.css'
	
import "react-toastify/dist/ReactToastify.css";
// ----------------------------------------------------------------------

export default function App() {
  const [selected, setSelected] = useState(1);
  const [auth, setauth] = useState(false);
  const [visible, setvisible] = useState('hide');
  const [actionsVisible, setactionsVisible] = useState(false);
  const [deliverTo, setdeliverTo] = useState('Pick a location');
  const [datetime, setdatetime] = useState('Now');
  const [timevalue, settimevalue] = useState(null);
  const [repeatvalue, setrepeatvalue] = useState('Doesn`t repeat');
  const [currency, setcurrency] = useState('LBP');
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [deliveryTotal, setdeliveryTotal] = useState(100000.0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [orderBy, setorderBy] = useState('price-asc');
  const [splitDelivery, setsplitDelivery] = useState(false);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [deliveryLocations, setdeliveryLocations] = useState([]);
  const [signoutvisible, setsignoutvisible] = useState(false);
  const [searchedText, setsearchedText] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [greaterthan, setgreaterthan] = useState(1000);
  const [lessthan, setlessthan] = useState(25000);

  const [categoriesfilter, setcategoriesfilter] = useState([]);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);

  const [userEmail, setUserEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [plots, setPlots] = useState(data.Farm);
  const [tempPlots, setTempPlots] = useState(data.Farm);
  const [mainInterface, setmainInterface] = useState('Crops');

  const AppContext = React.createContext(null);

//   window.localStorage.setItem(
//     "markers",
//     JSON.stringify([]),
// )

  return (
    <Provider store={store}>
      <HelmetProvider>
        <AppContext.Provider
          value={{
            tempPlots,
            setTempPlots,
            selected,
            setSelected,
            openCreate,
            setOpenCreate,
            plots,
            setPlots,
            auth,
            setauth,
            visible,
            setvisible,
            actionsVisible,
            setactionsVisible,
            signoutvisible,
            setsignoutvisible,
            deliverTo,
            setdeliverTo,
            datetime,
            setdatetime,
            timevalue,
            settimevalue,
            currency,
            quantity,
            setQuantity,
            totalPrice,
            setTotalPrice,
            selectedItem,
            setSelectedItem,
            items,
            setItems,
            deliveryTotal,
            setdeliveryTotal,
            orderBy,
            setorderBy,
            splitDelivery,
            setsplitDelivery,
            remainingQuantity,
            setRemainingQuantity,
            deliveryLocations,
            setdeliveryLocations,
            repeatvalue,
            setrepeatvalue,
            greaterthan,
            setgreaterthan,
            lessthan,
            setlessthan,
            role,
            setRole,
            searchedText,
            setsearchedText,
            userName,
            setUserName,
            userEmail,
            setUserEmail,
            mainInterface,
            setmainInterface,
          }}
        >
          <BrowserRouter>
            <ThemeProvider>
              <ScrollToTop />
              <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
              <Router />
            </ThemeProvider>
          </BrowserRouter>
        </AppContext.Provider>
      </HelmetProvider>
    </Provider>
  );
}