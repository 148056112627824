import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Typography, Tabs, Tab, LinearProgress, Tooltip, IconButton, Divider, Avatar, Grid } from '@mui/material';
import { createStyles, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/system';
import Chart from 'react-apexcharts';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LargeTooltip from '../../../tool-tip';
import AppContext from '../Context';

const theme = createTheme();

function RightCalendarDrawer(props) {
  const { filteredData } = useContext(AppContext);
  const { open2 } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const ContentContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
  }));

  useEffect(() => {
    console.log('================================');
    console.log(filteredData);
  }, [filteredData]);
  const progressData = filteredData.flatMap((item) =>
   {

    return item.crops.map((cropItem) => {

      // alert(JSON.stringify(cropItem.Pictures));
      return{
      label: cropItem.label || cropItem.value || 'Unknown',
      image: cropItem.Pictures , // Modify this based on your data structure
      value: Number(cropItem.Area) * 100 || 0, // Modify this based on your data structure
    }})}
  );
  const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    'grey',
    '#FFA07A',
    '#9370DB',
    '#32CD32',
    '#8A2BE2',
    '#FF4500',
    '#48D1CC',
    '#800080',
    '#20B2AA',
    '#FF1493',
    '#778899',
    '#FFD700',
    '#00CED1',
    '#FF69B4',
    '#708090',
    '#FF6347',
    '#4682B4',
    '#FF8C00',
    '#66CDAA',
    '#8B008B',
    '#00FA9A',
    '#FF00FF',
    '#6A5ACD',
    '#FF7F50',
    '#40E0D0',
    '#C71585',
  ];
  
  // You can continue adding more colors if needed...
  const colors2 = [
    '#04a78b',
    '#f2bb42',
    '#cc1c00',
    '#b1b0b0'
  ]

  const TimeInsightsContent = React.memo(({ activeTab }) => {
    const state = useMemo(
      () => ({
        options: {
          // ... other options for the chart
          chart: {
            toolbar: {
              show: false, // Show the toolbar (including legend and other elements)
            },
          },

          legend: {
            show: false, // Hide the legend
            position: 'bottom', // Set the position of the legend to 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
                labels: {
                  show: false,
                },
              },
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          labels: filteredData.flatMap((item) =>
            item.crops.map((cropItem) => cropItem.label || cropItem.value || 'Unknown')
          ),
          // Modify this based on your data structure
          colors: colors.slice(0, progressData.length), // Update with desired colors
          markers: {
            size: 6,
            colors: colors.slice(0, progressData.length), // Update with desired colors
            strokeColors: ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF'], // Set the stroke color of the markers
            strokeWidth: 2, // Set the stroke width of the markers
            strokeOpacity: 1, // Set the stroke opacity of the markers
            fillOpacity: 1, // Set the fill opacity of the markers
            shape: 'circle', // Set the shape of the markers to 'circle'
          },
          dataLabels: {
            enabled: false, // Disable the default data labels
          },
        },
        series: filteredData.flatMap((item) => item.crops.map((cropItem) => cropItem.Area * 100)),
      }),
      [] // dependencies, empty array to only calculate once
    );
    const currentDate = props.selectedDate ? moment(props.selectedDate) : moment();
    const nextWeekStart = currentDate.clone().add(1, 'week').format('MMM D');
    const nextWeekEnd = currentDate.clone().add(2, 'weeks').format('D');
    const prevWeekStart = currentDate.clone().subtract(1, 'week').format('MMM D');
    const prevWeekEnd = currentDate.clone().subtract(2, 'weeks').format('D');
    const prevWeekEnd2 = currentDate.clone().subtract(3, 'weeks').format('MMM D');
    const week = currentDate.clone().subtract(0, 'weeks').format('MMM D');

    const ClientsData = [
      {
        clientName: 'Client 1',
      },
      {
        clientName: 'Client 2',
      },
    ];
    const getRandomValue = (min, max) => {
      return Math.random() * (max - min) + min;
    };
    const BreakDownComponentProduce = ({ clientName ,data,i}) => {
      const [open, setOpen] = useState(i===0);
      return (
        <Box marginBottom={'20px'}>
          <Grid container display={'flex'} alignItems={'center'}>
            <Grid sm={2} md={2} display={'flex'} justifyContent="start">
       
              <Avatar sx={{ width: '35px', height: '35px', fontSize: '13px', color: 'black' }} src={data.image}/>
            </Grid>
            <Grid sm={4} md={4} display={'flex'} justifyContent="start" sx={{fontSize: '13px'}}>
              {clientName}
            </Grid>
            <Grid
              sm={6}
              md={6}
              display={'flex'}
              fontSize={'12px'}
              color="#02A78B"
              style={{ cursor: 'pointer' }}
              justifyContent="end"
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  setOpen(!open);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setOpen(!open);
                  }
                }}
              >
                {open ? 'Hide details' : 'Show details'}
              </div>
            </Grid>
          </Grid>
          {open && (
            <Grid container alignItems={'center'}>
              <Grid item xm={12} sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                {progressData.map((data, index) => (
                  <LinearProgress
                    key={index}
                    variant="determinate"
                    color="secondary"
                    value={100}
                    sx={{
                      flex: `${data.value} 1 auto`,
                      height: '15px',
                      marginRight: '1px',
                      borderTopLeftRadius: index === 0 ? '5px' : '0px',
                      borderBottomLeftRadius: index === 0 ? '5px' : '0px',
                      borderTopRightRadius: progressData.length - 1 === index ? '5px' : '0px',
                      borderBottomRightRadius: progressData.length - 1 === index ? '5px' : '0px',

                      backgroundColor: '#e3faf6',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: colors2[index % colors2.length],
                      },
                    }}
                  />
                ))}
              </Grid>

              <div style={{ width: '100%' }} fontSize="12px">
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', right: 0 }}>
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: '15px',
                          
                          display:'flex',
                          textAlign: 'start',
                          width: '50%',
                        }}
                      >
                          <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: colors2[0],
                        marginRight: '5px',
                        borderRadius: '50%',
                      }}
                    /> 
                       Sold
                      </Typography>
                      
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: props.viewDetails ? '10px' : '0px',
                          textAlign: 'end',
                          width: '50%',
                          paddingRight: 1,
                        }}
                      >
                       {getRandomValue(100,200).toFixed(0)}Kg
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', right: 0 }}>
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: '15px',
                          display: 'flex',
                        
                          textAlign: 'start',
                          width: '50%',
                        }}
                      >
                          <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: colors2[1],
                        marginRight: '5px',
                        borderRadius: '50%',
                      }}
                    /> 
                      Expected Sales
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          textAlign: 'end',
                          width: '50%',
                          fontSize: '9pt',
                          lineHeight: props.viewDetails ? '10px' : '0px',
                          paddingRight: 1,
                        }}
                      >
                 {getRandomValue(100,200).toFixed(0)}Kg
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', right: 0 }}>
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: '15px',
                          
                          display: 'flex',
                          textAlign: 'start',
                          width: '50%',
                        }}
                      >
                            <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: colors2[2],
                        marginRight: '5px',
                        borderRadius: '50%',
                      }}
                    />    Available For Sale
                       
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: props.viewDetails ? '10px' : '0px',
                          textAlign: 'end',
                          width: '50%',
                          paddingRight: 1,
                        }}
                      >
                 {getRandomValue(100,200).toFixed(0)}Kg
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', right: 0 }}>
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: '15px',
                          
                          display: 'flex',
                          textAlign: 'start',
                          width: '50%',
                        }}
                      >
                            <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: colors2[3],
                        marginRight: '5px',
                        borderRadius: '50%',
                      }}
                    /> 
                        Surplus
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          fontSize: '9pt',
                          lineHeight: props.viewDetails ? '10px' : '0px',
                          textAlign: 'end',
                          width: '50%',
                          paddingRight: 1,
                        }}
                      >
                 {getRandomValue(100,200).toFixed(0)}Kg
                      </Typography>
                    </Box>
              </div>

              <div> </div>
            </Grid>
          )}
        </Box>
      );
    };
    const BreakDownComponent = ({ clientName,i }) => {
      const [open, setOpen] = useState(i===0);
      return (
        <Box marginBottom={'20px'}>
          <Grid container display={'flex'} alignItems={'center'}>
            <Grid sm={2} md={2} display={'flex'} justifyContent="start">
              <Avatar sx={{ width: '30px', height: '30px', fontSize: '13px', color: 'black' }}>C</Avatar>
            </Grid>
            <Grid sm={4} md={4} display={'flex'} justifyContent="start">
              {clientName}
            </Grid>
            <Grid
              sm={6}
              md={6}
              display={'flex'}
              fontSize={'12px'}
              color="#02A78B"
              style={{ cursor: 'pointer' }}
              justifyContent="end"
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  setOpen(!open);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setOpen(!open);
                  }
                }}
              >
                {open ? 'Hide details' : 'Show details'}
              </div>
            </Grid>
          </Grid>
          {open && (
            <Grid container alignItems={'center'}>
              <Grid item xm={12} sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                {progressData.map((data, index) => (
                  <LinearProgress
                    key={index}
                    variant="determinate"
                    color="secondary"
                    value={100}
                    sx={{
                      flex: `${data.value} 1 auto`,
                      height: '15px',
                      marginRight: '1px',
                      borderTopLeftRadius: index === 0 ? '5px' : '0px',
                      borderBottomLeftRadius: index === 0 ? '5px' : '0px',
                      borderTopRightRadius: progressData.length - 1 === index ? '5px' : '0px',
                      borderBottomRightRadius: progressData.length - 1 === index ? '5px' : '0px',

                      backgroundColor: '#e3faf6',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: colors[index % colors.length],
                      },
                    }}
                  />
                ))}
              </Grid>

              <div style={{ width: '100%' }} fontSize="12px">
                {progressData.map((data, index) => (
                  <div
                    key={data.label}
                    style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', right: 0 }}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: colors[index],
                        marginRight: '5px',
                        borderRadius: '50%',
                      }}
                    />
                    <Grid container style={{ width: '100%', fontSize: '12px' }}>
                      <Grid sm={6} md={6}>
                        {data.label}
                      </Grid>
                      <Grid sm={3} md={3} display={'flex'} justifyContent="end">
                        {data.value * 0.8} €
                      </Grid>
                      <Grid display={'flex'} justifyContent="end" sm={3} md={3}>
                        {data.value} Kg
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>

              <div> </div>
            </Grid>
          )}
        </Box>
      );
    };

    return (
      <Box>
        {activeTab === 0 && (
          <>
            <Typography fontSize={'15px'} marginTop={-2} marginBottom={2}>
              Total Revenues
            </Typography>
            <div>
              <Chart options={state.options} series={state.series} type="donut" width="90%" />
            </div>
            <Box sx={{ marginBottom: 3, width: '100%' }}>
              {state.options.labels.map((label, index) => (
                <div key={label} style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px' }}>
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: state.options.colors[index],
                      marginRight: '5px',
                      borderRadius: '50%',
                    }}
                  />
                  <Grid
                    container
                    style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '12px' }}
                  >
                    <Grid sm={6} md={6}>
                      {label}
                    </Grid>
                    <Grid display={'flex'} justifyContent="end" md={3}>
                      <LargeTooltip title={`${state.series[index] * 0.8} €`} />
                    </Grid>
                    <Grid sm={3} md={3} display={'flex'} justifyContent="end">
                      <LargeTooltip title={`${state.series[index]} Kg`} />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Box>

            <Divider />
            <Typography fontSize={'15px'} marginTop={3} marginBottom={1}>
              Breakdown
            </Typography>

            {ClientsData.map((data,i) => {
              return <BreakDownComponent  i={i} clientName={data.clientName} />;
            })}
          </>
        )}
        {activeTab === 1 && (
          <>
            <Typography fontSize={'15px'} marginTop={-2} marginBottom={2}>
              Total Losses
            </Typography>
            <div>
              <Chart options={state.options} series={state.series} type="donut" width="90%" />
            </div>
            <Box sx={{ marginBottom: 3, width: '100%' }}>
              {state.options.labels.map((label, index) => (
                <div key={label} style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px' }}>
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: state.options.colors[index],
                      marginRight: '5px',
                      borderRadius: '50%',
                    }}
                  />
                  <Grid
                    container
                    style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '12px' }}
                  >
                    <Grid md={6}>{label}</Grid>
                    <Grid md={3} display={'flex'} justifyContent="end">
                      <LargeTooltip title={`${state.series[index]*1.2} €`} />
                    </Grid>
                    <Grid md={3} display={'flex'} justifyContent="end">
                      <LargeTooltip title={`${state.series[index]} Kg`} />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Box>

            <Divider />
            <Typography fontSize={'15px'} marginTop={3} marginBottom={1}>
              Breakdown
            </Typography>
            {progressData.map((data,i) => {
              return <BreakDownComponentProduce  data={data} i={i} clientName={data.label} />;
            })}
          </>
        )}
      </Box>
    );
  });

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open2}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
          <br/>
      {/* <div className={classes.drawerHeader} />

      <Divider /> */}
      <Box sx={{ width: '100%', marginTop: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box>
          <Typography fontSize={'18px'} marginBottom={3}>
            Insights
          </Typography>
          <StyledTabs
            value={activeTab}
            onChange={handleChange}
            TabIndicatorProps={{ style: { display: 'none' } }} // Hide the bottom border
          >
            <StyledTab2 label="Sales" />
            <StyledTab1 label="Produce" />
          </StyledTabs>
          <Box mt={2}>
            <TimeInsightsContent activeTab={activeTab} />
          </Box>
        </Box>
      </Box>

      {/* {"{info/notes}"} */}
    </Drawer>
  );
}
const MemoizedRightCalendarDrawer = React.memo(RightCalendarDrawer);
export default MemoizedRightCalendarDrawer;

const drawerWidth = 300;

const useStyles = makeStyles(() =>
  createStyles({
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: '#ffff',

      overflowY: 'scroll',
    },
    drawerPaper: {
      marginTop: '90px',
      paddingBottom: '120px',
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 64,
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    calendarSmall: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(1),
      minHeight: 265,
      minWidth: 240,
      background: theme.palette.background.paper,
    },
  })
);
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: 'none',
  '& .MuiTabs-scroller': {
    '& .MuiTab-root': {
      height: '10px', // Adjust the height value as needed
    },
  },
}));

const StyledTab1 = styled(Tab)(({ theme }) => ({
  width: '50%',
  cursor: 'pointer',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(16),
  border: `1px solid ${theme.palette.divider}`,

  borderBottomRightRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  '&.Mui-selected': {
  
    color: '#05b597',
    backgroundColor: '#e3faf6',
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '&.MuiTab-root': {
    height: 60, // Set the desired height here
    minHeight: 'unset',
    paddingTop: 15,
    paddingBottom: 15,
  },
}));
const StyledTab2 = styled(Tab)(({ theme }) => ({
  width: '50%',
  cursor: 'pointer',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,

  fontSize: theme.typography.pxToRem(16),
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,

  '&.Mui-selected': {
  
    color: '#05b597',
    backgroundColor: '#e3faf6',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  '&.MuiTab-root': {
    height: 60, // Set the desired height here
    minHeight: 'unset',
    paddingTop: 15,
    paddingBottom: 15,
  },
}));
