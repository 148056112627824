import { ToastContainer, toast } from 'react-toastify';
import firebase from '../firebase';
import 'firebase/firestore';
import 'firebase/auth';

const db = firebase.firestore();

export const registerUser = async (userData) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(userData.email, userData.password)
        .then(async (userCredential) => {
          await addUserData({...userData,uid:userCredential.user.uid}).then(async(user) => {
         
            const userData = await getUserByID(userCredential.user.uid)
      
            resolve(userData);
          });
     
        
        
        });
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  });
};

export const loginUser = (email, password) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async(userCredential) => {
          // alert(JSON.stringify(userCredential.user.uid))
          const userData = await getUserByID(userCredential.user.uid)
          // alert(JSON.stringify(userData))
        
          resolve(userData);
        });
    } catch (error) {
      console.error('Error logging in user:', error);
      reject(error);
    }
  });
};

// Function to log out the current user
export const logoutUser = async () => {
  try {
    await firebase.auth().signOut();
    console.log('User logged out');
  } catch (error) {
    console.error('Error logging out user:', error);
    throw error;
  }
};

// Function to add User data
export const addUserData = async (userData) => {
  console.log(userData);
  try {
    const userRef = await db.collection('Users').doc(userData.uid).set(userData);
    console.log('User data added with ID:', userRef.uid);
    return userRef;
  } catch (error) {
    console.error('Error adding User data:', error);
    throw error;
  }
};

// Function to update User data
export const updateUserData = async (userId, updatedData) => {
  try {
    await db.collection('Users').doc(userId).update(updatedData,{merge:true});
    toast.success('User updated successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    console.log('User data updated successfully');
  } catch (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    console.error('Error updating User data:', error);
    throw error;
  }
};

// Function to delete User data
export const deleteUserData = async (userId) => {
  try {
    await db.collection('Users').doc(userId).delete();
    console.log('User data deleted successfully');
  } catch (error) {
    console.error('Error deleting User data:', error);
    throw error;
  }
};

// Function to get all Users
export const getAllUsers = async () => {
  try {
    const snapshot = await db.collection('Users').get();
    const Users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return Users;
  } catch (error) {
    console.error('Error getting Users:', error);
    throw error;
  }
};
export const getUserByID = async (id) => {
  try {
    const snapshot = await db.collection('Users').doc(id).get();
    console.log(snapshot.data()); // Access the document data using .data() method
    const data ={
      uid:id,
      ...snapshot.data()
    }
    return data; // Return the document data
  } catch (error) {
    console.error('Error getting User:', error); // Updated error message
    throw error;
  }
};

