import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Collapse,
  TextField,
  CircularProgress,
  LinearProgress,
  Tooltip,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SpaIcon from '@mui/icons-material/Spa';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import eye from '../assets/images/eye.png';
// import MultiColorRangeInput from './RangeWithColors';
import MultiColorRangeInput from './RangeWithColors';
import logo from '../assets/images/rz.png';

const AppContext = React.createContext(null);

export default function ListCard(props) {
  const [liked, setLiked] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(props.data);
  useEffect(() => {
    const string = '';
    if (props.data.label.includes('Tomato') || props.data.label.includes('Pepper')) {
      props.data.early = {
        percentage: 60,
        startWeek: 0,
        endWeek: 12,
        comment:
          'Significant risk of reduced vigorousness due to cold exposure. Potential increased cost of fertilization.',
      };
      props.data.optimal = {
        percentage: 30,
        startWeek: 13,
        endWeek: 17,
        comment: 'Favorable conditions avoiding frost bites and reduced irrigation costs.',
      };
      props.data.late = {
        percentage: 10,
        startWeek: 18,
        endWeek: 20,
        comment: 'Increased heat stress and irrigation costs.',
      };
    } else {
      props.data.early = {
        percentage: 50,
        startWeek: 0,
        endWeek: 10,
        comment:
          'Significant risk of reduced vigorousness due to cold exposure. Potential increased cost of fertilization.',
      };
      props.data.optimal = {
        percentage: 20,
        startWeek: 11,
        endWeek: 14,
        comment:
          'Favorable conditions avoiding frost bites and reduced irrigation costs. Potential benefits from a second plantation.',
      };
      props.data.late = {
        percentage: 30,
        startWeek: 15,
        endWeek: 20,
        comment: 'Increased heat stress and irrigation costs. ',
      };
    }
  }, []);

  const add = (bool, data) => {
    setLiked(!bool);
  };
  useEffect(() => {
    props.change(liked, props.data);
  }, [liked]);

  useEffect(() => {
    console.log(props.data);
  }, []);

  const onChange = (val) => {
    props.data.Area = parseInt(val, 10);
  };

  const onChangeRange = (value) => {
    props.data.weekStart2 = parseInt(value, 10);
    setData({ ...props.data, weekStart2: parseInt(value, 10) });
    console.log(props.data);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = () => {
    const confirmed = window.confirm('Are you sure you want to reorder?');
    if (confirmed) {
      // alert(JSON.stringify(props.data))
      // setOrdersList(prev => [{...props.data,status:'Pending'}, ...prev]);
      // Delete the item
    } else {
      // Do nothing
    }
  };

  function getRandomValue() {
    const minValue = 45;
    const maxValue = 95;
    return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  }
  function getRandomValueRecommended() {
    const minValue = 67;
    const maxValue = 95;
    return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  }

  function getStatusColor(value) {
    if (value >= 0 && value <= 50) {
      return 'error'; // 'error' is equivalent to 'danger' in some Material-UI versions
    }
    if (value > 50 && value <= 75) {
      return 'warning';
    }
    return 'primary';
  }

  const randomValueFirst = props.index < 3 ? getRandomValueRecommended() : getRandomValue();
  const colorVariantFirst = getStatusColor(randomValueFirst);
  const randomValueSecond = props.index < 3 ? getRandomValueRecommended() : getRandomValue();
  const colorVariantSecond = getStatusColor(randomValueSecond);
  const randomValueThird = props.index < 3 ? getRandomValueRecommended() : getRandomValue();
  const colorVariantThird = getStatusColor(randomValueThird);

  return (
    <Box sx={{ marginBottom: 5 }}>
      {props.index < 3 && (
        <div style={{ display: 'flex', justifyContent: 'end', marginRight: 90 }}>
          <Card
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // border:"1px solid #02A78B",
              fontSize: '18px',
              height: '35px',
              marginLeft: '40px',
              borderRadius: '5px',
              color: 'grey',
              marginBottom: '-10px',
              textAlign: 'center',
              zIndex: 1,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Recommended
          </Card>
        </div>
      )}
      <Card style={{ marginBottom: 15 }}>
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={6}
              md={2}
              style={{ display: 'flex' }}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign={'start'}
            >
              <img
                alt=""
                src={props.data.Pictures}
                style={{ width: '200px', height: '180px', border: 'none', borderRadius: 10 }}
              />
            </Grid>
            <Grid item xs={6} md={5} style={{ display: 'flex' }} justifyContent={'center'} textAlign={'start'}>
              <div style={{ width: '55%' }}>
                <p
                  style={{
                    fontWeight: '400',
                    lineHeight: '25px',
                    textAlign: 'start',
                  }}
                >
                  <span style={{ fontSize: '22pt', fontWeight: 'bold' }}>{props.data.label}</span>
                  <br />
                  <span style={{ fontSize: '14pt', color: 'grey' }}>{props.data.Variety}</span>
                  <br />
                  <span style={{ fontSize: '14pt' }}>0.12$ / seed</span>
                </p>
                <Button variant="contained" onClick={() => add(liked, props.data)} sx={buttonStyle}>
                  {liked ? 'Remove Crop' : 'Add Crop'}
                </Button>
              </div>
            </Grid>
            <Box sx={{ width: '35%', display: 'flex', flexDirection: 'column', rowGap: '0px' }}>
              <Grid
                item
                textAlign={'start'}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {' '}
                <Tooltip title="Agricultural">
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                      position="relative"
                      display="inline-flex"
                      sx={{ backgroundColor: '#E1F4F4', borderRadius: '50%' }}
                    >
                      <CircularProgress value={randomValueFirst} color={colorVariantFirst} variant="determinate" />

                      <Box
                        top={3}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" component="div" color="textSecondary">
                          <AgricultureIcon color={colorVariantFirst} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Tooltip>
                <Tooltip title="Environmental">
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                      position="relative"
                      display="inline-flex"
                      sx={{ backgroundColor: '#E1F4F4', borderRadius: '50%' }}
                    >
                      <CircularProgress value={randomValueSecond} color={colorVariantSecond} variant="determinate" />

                      <Box
                        top={3}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" component="div" color={colorVariantSecond}>
                          <SpaIcon color={colorVariantSecond} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Tooltip>
                <Tooltip title="Economical">
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                      position="relative"
                      display="inline-flex"
                      sx={{ backgroundColor: '#E1F4F4', borderRadius: '50%' }}
                    >
                      <CircularProgress value={randomValueThird} color={colorVariantThird} variant="determinate" />

                      <Box
                        top={3}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" component="div" color={colorVariantThird}>
                          <EuroSymbolIcon color={colorVariantThird} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  mt: 5,
                  maxWidth: '200px',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  rowGap: '10px',
                }}
              >
                <span style={{ color: 'grey', fontSize: '10pt' }}>Total Score</span>
                <LinearProgress
                  sx={{ height: '10px', borderRadius: '10px', width: '100%' }}
                  variant="determinate"
                  value={74}
                />

                {/* {liked && (
                    <Grid container>
                      <TextField
                        sx={{ width: 70 }}
                        placeholder={'0'}
                        defaultValue={'0'}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      />
                      <Box
                        sx={{
                          fontStyle: 'italic',
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 2,
                          fontSize: '20pt',
                        }}
                      >
                        Ha
                      </Box>
                    </Grid>
                  )} */}
              </Box>
              <div style={{ ...boxStyle2, display: 'flex', flexDirection: 'column' }}>
                <Button sx={buttonStyle2} onClick={handleExpandClick}>
                  View Details <RemoveRedEyeOutlinedIcon />
                </Button>
              </div>
            </Box>
          </Grid>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ backgroundColor: '#fffff' }}>
            {props.data.Comments}
            <br />
            {props.data.Variety}
            <br />
            {props.data.CropType}
            <br />
            {props.data.AverageWeight ? `Average weight ${props.data.AverageWeight}` : null}
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
}

const overlay = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // set the background color to a semi-transparent black
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'end',

  width: '90%',
};
const boxStyle2 = {
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'end',
  width: '100%',
  marginLeft: '40px',
};
const productImageStyle = {
  maxWidth: 140,
};
const buttonStyle = {
  color: 'white',
  backgroundColor: '#02A78B',
  height: 40,
  width: 140,
  '&:hover': {
    color: '#02A78B',
    backgroundColor: '#fff',
    border: '1px solid #02A78B',
  },
};
const buttonStyle2 = {
  color: 'grey',
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '140px',
};

const progressContainerStyle = {
  position: 'relative',
  display: 'inline-block',
};
