// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/.pnpm/postcss-loader@6.2.1_postcss@8.4.31_webpack@5.89.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/.pnpm/react-calendar@4.6.1_react-dom@18.2.0_react@18.2.0/node_modules/react-calendar/dist/Calendar.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-calendar {
  width: 100% !important;
  border: none !important;
  height: 690px;
}

.react-calendar__navigation button {
  /* Add custom styles */
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.react-calendar__tile {
  /* Add custom styles */
  border: 1px solid #cccccc;
  background-color: white;
  background: white;
  height: 100px;
  padding: 10px;
}

.react-calendar__tile--has-events {
  background-color: red !important;
  color: white !important;
}

.react-calendar__month-view__has-events {
  display: none !important; /* Hide weekday labels in the header */
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/calendar-style.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,gCAAgC;EAChC,uBAAuB;AACzB;;AAEA;EACE,wBAAwB,EAAE,sCAAsC;AAClE","sourcesContent":["@import 'react-calendar/dist/Calendar.css';\n\n.react-calendar {\n  width: 100% !important;\n  border: none !important;\n  height: 690px;\n}\n\n.react-calendar__navigation button {\n  /* Add custom styles */\n  border: none;\n  border-radius: 5px;\n  padding: 5px 10px;\n  cursor: pointer;\n}\n\n.react-calendar__tile {\n  /* Add custom styles */\n  border: 1px solid #cccccc;\n  background-color: white;\n  background: white;\n  height: 100px;\n  padding: 10px;\n}\n\n.react-calendar__tile--has-events {\n  background-color: red !important;\n  color: white !important;\n}\n\n.react-calendar__month-view__has-events {\n  display: none !important; /* Hide weekday labels in the header */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
