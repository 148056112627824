import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@mui/styles';
import clsx from 'clsx';
import { format, differenceInMinutes } from 'date-fns';
import { useDrag } from 'react-dnd';
import { Card, createTheme, Grid, styled, Typography } from '@mui/material';

import { getEmptyImage } from 'react-dnd-html5-backend';
import { CalendarContext } from '../common/CalendarContext';

import InventoryProductCalendar from '../../../InventoryProductCalendar';
import createEditEvent from './createEditEvent';

const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    marker: {
      overflow: 'hidden',
      position: 'absolute',
      border: '2px solid #e0e0e0',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      // backgroundColor: "rgba(66, 165, 245, 0.8)",
      // padding: '1px 3px',
      borderRadius: '10px',
      cursor: 'pointer',
      zIndex: 50,
      //   '&:hover': {
      //     minWidth: 250,
      //     minHeight: 250,
      //     left: '-50%',
      //     // back
      //     zIndex: 53,
      //     border: '2px solid #02A78B',
      //     // backgroundColor: 'rgba(66, 165, 245, 1)',
      //   },
      //   minHeight: 24,
    },
    markerText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      color: 'black',
    },
    markerTextWast: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#787274',
      height: '33%',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      padding: '5px',
    },
    markerTextAvailable: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#f2bc41',
      height: '33%',
      padding: '5px',
    },
    markerTextSold: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#97cd9b',
      height: '33%',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      padding: '5px',
    },

    beginEnd: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: 10,
    },
    extraInfo: {
      fontSize: 7,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      // marginTop: -30,
      // width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      // minWidth: 120,
    },
    formControlFlex: {
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    optionsBar: {
      marginTop: theme.spacing(-1),
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  })
);

const firstRow = {
  width: '33.33%',
  height: 10,
  backgroundColor: 'grey',
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
};
const firstRow2 = {
  width: '33.33%',
};
const lastRow = {
  width: '33.33%',
  height: 10,
  backgroundColor: '#4FA856',
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
};
const middleRow = { width: '33.33%', height: 10, backgroundColor: '#F2BB42' };
const typographyStyle = { textAlign: 'end', paddingTop: 1.5, marginRight: -2.5, fontSize: 12, fontWeight: '100' };
const typographyCenterStyle = {
  textAlign: 'center',
  paddingTop: 1.5,
  marginRight: -2.5,
  fontSize: 12,
  fontWeight: '100',
};
const typographyStartStyle = {
  textAlign: 'start',
  paddingTop: 1.5,
  marginRight: -2.5,
  fontSize: 12,
  fontWeight: '100',
};
const lastTypographyStyle = { textAlign: 'end', paddingTop: 1.5, fontSize: 12, fontWeight: '100' };

const style = {
  // border: '2px solid #D0D0D0',
  minHeight: 140,
  borderRadius: 20,
  paddingLeft: 15,
  paddingRight: 15,
  maxWidth: 900,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
};
const itemStyle = {};
const subItemStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const imageStyle = {
  maxWidth: '100px',
};
const row = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: 110,
  marginTop: 1,
};

function getStyles(left, top, isDragging, partOfStyle) {
  const transform = `translate3d(${left}px, ${top}px, 0)`;

  return {
    position: 'absolute',
    transform: isDragging ? transform : 'initial',
    WebkitTransform: isDragging ? transform : 'initial',
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    ...partOfStyle,
  };
}

function EventMark(props) {
  const [filteredData,setFilteredData]=useState([])
  const classes = useStyles();
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const {
    defaultEventDuration,
    // modal = false,
    // eventDialogMaxWidth = "md",
    // fullscreen = false,
    // allowFullScreen = false,
    // withCloseIcon = true,
    // title,
    // content,
    // actions,
    // openDialog,
    // handleCloseDialog,
    // eventBeginDate,
    // eventBeginTime,
    // eventEndDate,
    // eventEndTime,
    // minutes,
    // locale,
  } = stateCalendar;

  const { calendarEvent, len, sq } = props;
  // console.log(calendarEvent)

  const beginDate = new Date(calendarEvent.begin);
  const endDate = new Date(calendarEvent.end);

  const beginDateFormatted = format(beginDate, format(beginDate, 'mm') === '00' ? 'HH' : 'HH:mm');
  const endDateFormatted = format(endDate, format(endDate, 'mm') === '00' ? 'HH' : 'HH:mm');

  const currentDay = beginDate;
  const initTime = new Date(format(currentDay, 'YYYY/MM/DD 0:0:0'));
  const position = differenceInMinutes(currentDay, initTime) - 5;

  const duration = differenceInMinutes(endDate, beginDate) - 3;

  const viewEvent = (props) => {
    const { calendarEvent } = props;

    const eeBeginDate = new Date(calendarEvent.begin);
    const eeEndDate = new Date(calendarEvent.end);

    const eeBeginTime = format(eeBeginDate, 'H:mm');
    const eeEndTime = format(eeEndDate, 'H:mm');
    const eeDuration = differenceInMinutes(eeEndDate, eeBeginDate);

    setStateCalendar({
      ...stateCalendar,
      openViewDialog: true,
      calendarEvent,
    });
  };

  // const [{ isDragging }, drag, preview] = useDrag({
  //     item: { type: "box" },
  //     collect: monitor => ({
  //         isDragging: monitor.isDragging(),
  //     }),
  // })

  // useEffect(() => {
  //     preview(getEmptyImage(), { captureDraggingState: false })
  // }, [preview])
 
  const left = (100 / len) * sq + 1;

  const partOfStyle = {
    marginTop: props.sq===0?(140 * props.sq):(140 * props.sq),
    height: 130,
    width: `100%`,

    // marginLeft: `calc(100% / ${len} * ${sq})`,
  };

  const onDragStart = (eventEl, calendarEvent) => {
    const width = eventEl.currentTarget.parentElement.parentElement.offsetWidth;
    const height = eventEl.currentTarget.clientHeight + 5;

    setStateCalendar({
      ...stateCalendar,
      startDragging: true,
      draggingEventId: calendarEvent.id,
      calendarEvent,
      ghostProperties: { width, height },
    });
  };

  return (
    <Card // eslint-disable-line
    onClick={(eventEl) =>
      createEditEvent({
        eventEl,
        defaultEventDuration,
        stateCalendar,
        setStateCalendar,
        props
      })
    } 
      id={calendarEvent.id}
      className={classes.marker}
      // ref={drag}
      // onDragStart={(eventEl) => onDragStart(eventEl, calendarEvent)}
      style={getStyles(left, position / 57 - 2, false, partOfStyle)}
      //   onClick={(eventEl) =>
      //     viewEvent({
      //       eventEl,
      //       calendarEvent,
      //       defaultEventDuration,
      //       stateCalendar,
      //       setStateCalendar,
      //     })
      //   }
    >
       
     <InventoryProductCalendar viewDetails  {...props}/>
   
      {/* <div style={{borderRadius:'10px',width:'auto',height:'auto'}}>

</div> */}
      {/* <div className={classes.beginEnd}>
                <span>{beginDateFormatted}</span>
                <span> - </span>
                <span>{endDateFormatted}</span>
            </div> */}
      {/* <div className={clsx(classes.extraInfo, classes.markerText)}>{`[${calendarEvent.id}]`}</div> */}
 
    </Card>
  );
}

export default EventMark;
