import React, { useContext, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@mui/styles";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import { cyan, lightBlue } from "@mui/material/colors";
import { DndProvider } from "react-dnd";
import { createTheme, styled } from "@mui/material";
import { HTML5Backend } from "react-dnd-html5-backend";
import CalendarHeader from "./CalendarHeader";
import CalendarBoard from "./CalendarBoard";
import CalendarBoardNew from "./CalendarBoardNew";

import CalendarBoardDragLayer from "./CalendarBoardDragLayer";
import { CalendarContext } from "../common/CalendarContext";


const theme = createTheme();
const useStyles = makeStyles(() =>
    createStyles({
        hide: {
            display: "none",
        },
        show: {
            display: "initial",
        },
        root: {
       
            backgroundColor: theme.palette.background.paper,
        },
        body: {
            overflowY: "scroll",
            height: "calc(100%)",
            alignItems: "stretch",
          
            paddingBottom:0,
            paddingTop:0,
            "&:before": {
                backgroundImage: "linear-gradient(to right,white,rgba(255,255,255,0))",
                content: "",
                height: 2,
                position: "absolute",
                width: 80,
                zIndex: 51,
            },
        },
        timeColumnContainer: {
            color: theme.palette.text.secondary,
            backgroundColor: "transparent",
            height: "auto",
            overflowY: "hidden",
            flex: "none",
            display: "flex",
            alignItems: "flex-start",
            minWidth: 40,
            maxWidth: 40,
            marginTop: -8,
        },
        timeColumn: {
            position: "relative",
            webkitBoxSizing: "border-box",
            marginLeft: "auto",
        },
        timeColumnElement: {
            position: "relative",
            height: 60,
            paddingRight: 8,
            textAlign: "right",
            color: "#70757a",
            fontSize: 12,
        },
        boardContainer: {
            // borderRight: '1px solid #dadce0',
            overflowX: "auto",
           
            display: "flex",
            alignItems: "flex-start",
            // backgroundColor: 'rgba(245, 245, 220, 0.30)',
            // height: '100%',
        },
        board: {
            minWidth: "100%",
            height: "100%",
            flex: "none",
            verticalAlign: "top",
            overflow: "hidden",
            position: "relative",
        },
        columnDivisor: {
            height: "100%",
            paddingLeft: 8,
            borderRight: "1px solid #dadce0",
        },
        dayContainer: {
            // backgroundColor: lightBlue[50],
            borderRight: "1px solid #dadce0",
            position: "relative",
            paddingRight: 12,
            flex: "1 1 auto",
            height: "100%",
        },
        eventsContainer: {
            backgroundColor: "transparent",
            position: "relative",
            height: "100%",
            width: "100%",
        },
        dayContainerWeekend: {
            backgroundColor: "#F5F5F5",
        },
        currentTimeDot: {
            background: '#03856f',
            borderRadius: "50%",
            content: "''",
            position: "absolute",
            height: 12,
            width: 12,
            zIndex: 52,
        },
        currentTimeLine: {
            position: "absolute",
            zIndex: 51,
            borderColor: '#03856f',
            borderTop: "2px solid",
            left: 0,
            right: -1,
        },
    }),
)

function CropsViewDashboard(props) {
    const classes = useStyles()

    const { selectedWeekIndex, selectedWeek } = props

    const { stateCalendar } = useContext(CalendarContext)
    const { selectedDate, locale, layout, defaultEventDuration } = stateCalendar
   
    return useMemo(() => {
        return (
            
          <CalendarBoardNew {...props} selectedWeekIndex={selectedWeekIndex} selectedWeek={selectedWeek} />
        )
        // ....
        // ....
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes, selectedDate, locale, layout, defaultEventDuration, selectedWeek, selectedWeekIndex])
}

export default CropsViewDashboard
