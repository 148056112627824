import { Grid, Typography, Card, Input, Button, ButtonGroup, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { crops } from '../utils/DataStructures';
import Peppers from '../assets/peppers.png';

export default function InventoryProduct(props) {
  return (
    <>
      <Grid
        container
        style={props.style}
      >
        <Grid style={{ ...subItemStyle, ...imageStyle }} item xs={2} sm={2} md={2} lg={2}>
          <img src={props.crop ? props.crop.Pictures : Peppers} alt="crop" />
        </Grid>
        <Grid style={itemStyle} item xs={3} sm={3} md={3} lg={3}>
          <Typography variant="span" sx={{ fontSize: '10pt', fontWeight: 'bold', lineHeight: '15px',paddingRight:1  }}>
            <Typography variant="span" sx={{ fontSize: '10pt', fontWeight: 'bold', lineHeight: '15px',paddingRight:1  }}>
              {props.crop?.label ? props.crop?.label : 'Unnamed'}
            </Typography>
            <Typography
              style={{ fontWeight: 'normal', fontSize: '10pt', lineHeight: '15px', marginTop: '5px',paddingRight:1 }}
              variant="h5"
            >
              {props.crop.Variety ? props.crop.Variety : null}
              {/* Crop name goes here */}
            </Typography>
          </Typography>
        </Grid>
        <Grid style={itemStyle} item xs={3} sm={3} md={3} lg={3}>
          <Typography
            variant="span"
            sx={{
              fontSize: '9pt',
              lineHeight: '15px',
              color: '#409d40',
              fontWeight: 'bold',
              textAlign: 'center',
              marginLeft: '-10px',paddingRight:1 
              
            }}
          >
            Available Quantity
          </Typography>
          <Typography
            variant="span"
            sx={{ fontSize: '11pt', fontWeight: 'bold', lineHeight: props.viewDetails?'10px':'0px', textAlign: 'center',paddingRight:1  }}
          >
        {2*110/2+100} kg
            <span
              style={{
                color: '#0ca488',
                fontSize: '10pt',
                lineHeight: props.viewDetails?'10px':'0px',
                marginBottom: '5px',
                textAlign: 'center',
              }}
            >
              ▲
            </span>
          </Typography>
        </Grid>
        <Grid style={itemStyle} item xs={3} sm={3} md={3} lg={3}>
          <Typography
            variant="span"
            sx={{ fontSize: '9pt', lineHeight: '15px', textAlign: 'center', color: '#aeaeac', fontWeight: 'bold' }}
          >
            Expected {props.viewDetails && <br />}Price
          </Typography>
          <Typography
            variant="span"
            sx={{ fontSize: '11pt', fontWeight: 'bold', lineHeight: props.viewDetails?'10px':'0px', textAlign: 'center' }}
          >
            $1.29/kg
            <span style={{ color: '#0ca488', fontSize: '10pt', lineHeight: props.viewDetails?'10px':'0px', marginBottom: '5px' }}>▲</span>
          </Typography>
        </Grid>
        <div
          style={{ display: 'flex', justifyContent: 'end', height: '100%', width: '100%' }}
          className="d-flex justify-content-end align-items-end h-100"
        >
          {props.viewDetails && (
            <Typography sx={{ fontSize: '10pt', marginTop: '-15px', marginRight: '10px', color: '#409d40' }}>
              View Details
            </Typography>
          )}
        </div>
      </Grid>
    </>
  );
}

const itemStyle = {
  height: 140,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '20px',
};
const subItemStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const imageStyle = {
  maxWidth: 110,
  marginRight: 10,
};
