/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Box, Button, Card, Container, Grid, OutlinedInput, Tooltip, Typography } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DropDown from '../components/drop-down';
import CountrySelect from '../components/contires-select';
import CitySelect from '../components/locations-inputs';
import { countries } from './data';
import { addFarm, updateFarm } from '../store/farms';
import { addFarmData, updateFarmData } from '../firebase/fucntions';

export default function CreateLandPage({ children, open }) {
  const defaultData = {
    description: 'Tajonar, Spain',
    formattedSuggestion: {
      secondaryText: 'Spain',
      mainText: 'Tajonar',
    },
    lat: 42.768087,
    lng: -1.622556,
  };

  
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [city, setCity] = useState(defaultData);
  const [cityValue, setCityValue] = useState('Tajonar');
  const [attitude, setAttitude] = useState('');
  const [waterAvailability, setWaterAvailability] = useState(['Select']);
  const [soilType, setSoilType] = useState(['Select']);
  const [plotType, setPlotType] = useState(['Select']);
  const [machineAvailability, setMachineAvailability] = useState(['Select']);
  const [area, setArea] = useState('');
  const [country, setCountry] = useState({ code: 'ES', label: 'Spain', phone: '34' });

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [address, setAddress] = useState('');
  const [parcelID, setParcelID] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [size, setSize] = useState('');
  const [pHLevel, setPHLevel] = useState('');
  const [organicMatterContent, setOrganicMatterContent] = useState('');
  const [nutrientLevels, setNutrientLevels] = useState('');
  const [temperature, setTemperature] = useState('');
  const [precipitation, setPrecipitation] = useState('');
  const [humidity, setHumidity] = useState('');
  const [windSpeed, setWindSpeed] = useState('');
  const [landUseType, setLandUseType] = useState('');
  const [previousCrops, setPreviousCrops] = useState('');
  const [elevation, setElevation] = useState('');
  const [slope, setSlope] = useState('');
  const [waterSourceType, setWaterSourceType] = useState('');
  const [distanceToWaterSource, setDistanceToWaterSource] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [dataSource, setDataSource] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filterOption, setFilterOption] = useState('');
 const [id,setID] = useState('')

  const location = useLocation();
  const bootstrapURLKeys = {
    key: 'AIzaSyDA7pLUbuht65MXkGUQE588Uu4XTirB37U',
  };
  const defaultCenter = {
    lat: 42.768087,
    lng: -1.622556,
  };

  useEffect(() => {
    const farmProp = location.state?.farm;
    if (farmProp) {
    
      setID(location.state.farmID)
      console.log('----------------------------------------------------------------');
      console.log(farmProp);
      setCity(farmProp?.city|| '');
      setCityValue(farmProp?.cityValue|| '');
      setAttitude(farmProp?.attitude|| '');
      setArea(farmProp?.Area|| 1);
      setCountry(farmProp?.country|| '');
  
      // Set state for the remaining fields
      setLongitude(farmProp?.longitude || '');
      setAddress(farmProp?.address || '');
      setParcelID(farmProp?.parcelID || '');
      setRegion(farmProp?.region || '');
      setProvince(farmProp?.province || '');
      setMunicipality(farmProp?.municipality || '');
      setSize(farmProp?.size || '');
      setPHLevel(farmProp?.pHLevel || '');
      setOrganicMatterContent(farmProp?.organicMatterContent || '');
      setNutrientLevels(farmProp?.nutrientLevels || '');
      setTemperature(farmProp?.temperature || '');
      setPrecipitation(farmProp?.precipitation || '');
      setHumidity(farmProp?.humidity || '');
      setWindSpeed(farmProp?.windSpeed || '');
      setLandUseType(farmProp?.landUseType || '');
      setPreviousCrops(farmProp?.previousCrops || '');
      setElevation(farmProp?.elevation || '');
      setSlope(farmProp?.slope || '');
      setWaterSourceType(farmProp?.waterSourceType || '');
      setDistanceToWaterSource(farmProp?.distanceToWaterSource || '');
      setStartDate(farmProp?.startDate || '');
      setEndDate(farmProp?.endDate || '');
      setStartTime(farmProp?.startTime || '');
      setEndTime(farmProp?.endTime || '');
      setOutputFormat(farmProp?.outputFormat || '');
      setDataSource(farmProp?.dataSource || '');
      setSearchText(farmProp?.searchText || '');
      setFilterOption(farmProp?.filterOption || '');
    }
  }, []);
  
  

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onSave = async () => {
    delete city.id;
    const crops = location.state?.farm.crops;
    const finalPlot = {
      city,
      cityValue,
      attitude,
      area,
      country,
      id: id,
      label: capitalize(cityValue),
      value: capitalize(city.description),
      Area: area,
      Coordinates: `${city.lat},${city.lng}`,
      weekStart: 22,
      isselected: false,
      latitude,
longitude,
address,
parcelID,
region,
province,
municipality,
size,
pHLevel,
organicMatterContent,
nutrientLevels,
temperature,
precipitation,
humidity,
windSpeed,
landUseType,
previousCrops,
elevation,
slope,
waterSourceType,
distanceToWaterSource,
startDate,
endDate,
startTime,
endTime,
outputFormat,
dataSource,
searchText,
filterOption,
    };
    console.log(user.uid, id)
    console.log(finalPlot)
    if (location.state) {
      await updateFarmData(user.uid, id, finalPlot).then(() => {
        console.log(finalPlot);
        finalPlot.crops = crops;
        dispatch(updateFarm(finalPlot));
        navigate(`/dashboard/farm`);
      });
    } else {
      delete finalPlot.id;
      await addFarmData(user.uid, finalPlot).then(() => {
        finalPlot.crops = [];
        dispatch(addFarm(finalPlot));
        navigate(`/dashboard/farm`);
      });
    }
  };
  const onCancel = () => {
    navigate(`/dashboard/farm`);
  };

  const InputWithTitle = ({ title, onChange, type, value, country,values }) => {
    const  longText=
      'Agriculture is the practice of cultivating crops, raising animals, and other related activities to produce food, fiber, and other products for human consumption and use. It is a vital part of human civilization, providing sustenance and livelihoods to billions of people around the world.';

    const Input = () => {
      switch (type) {
        case 'dropDown':
          return <DropDown values={values} onChange={(e) => onChange([e])} value={value} />;

        case 'country':
          return <CountrySelect value={value} onChange={onChange} />;
        case 'city':
          return <CitySelect value={value} country={country} onChanged={onChange} />;
        default:
          return (
            <OutlinedInput
              type="number"
              onChange={(e) => onChange(e.target.value)}
              value={value}
              sx={noOutline}
              placeholder="Please enter value"
            />
          );
      }
    };
    return (
      <>
        <Box sx={{ marginTop: 1, marginBottom: 4 }}>
          <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
            <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
              {title}
            </Typography>
            <Tooltip title={longText}>
              <InfoOutlinedIcon sx={{ width: 15 }} />
            </Tooltip>
          </Grid>
          <Input />
        </Box>
      </>
    );
  };

  const onChangeCountryChange = async (value) => {
    await geocodeByAddress(value.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const final = { description: value.label, lat, lng };
        setCity(final);
        setCityValue('');
      });

    setCountry(value);
    // setCityValue('')
  };

  const onSelectValue = async (value) => {
    const results = await geocodeByAddress(value.formattedSuggestion.mainText);
    setCityValue(value.formattedSuggestion.mainText);
    // setCountry(findCountryCode(value.formattedSuggestion.secondaryText));
    await getLatLng(results[0]).then((e) => {
      setCity({ ...value, lat: e.lat, lng: e.lng });
    });
  };

  const onChange = (value) => {
    console.log(value);
  };

  function findCountryCode(label) {
    const country = countries.find((c) => c.label === label);
    return country;
  }

  const waterAvailabilityChoices = [
    'High',
    'Moderate',
    'Low',
    'Scarce',
    'Very Scare',
    'Seasonal',
    'Intermittent',
    'Abundant (Rainfall)',
    'Groundwater Depletion',
    'Aquifer Recharge',
  ];

  const machineAvailabilityChoices = [
    'High', // Machines are readily available and in good working condition.
    'Moderate', // Machines are available, but some may require maintenance or repairs.
    'Low', // Limited availability of machines, leading to delays in operations.
    'Scarce', // Machines are scarce, causing significant disruptions in agricultural activities.
    'Intermittent', // Machines are available only at certain times, impacting productivity.
    'Seasonal', // Machines are available during specific seasons or periods only.
    'Limited Capacity', // Machines are available, but their capacity is limited, affecting efficiency.
    'Downtime', // Machines are frequently facing downtime, reducing their availability.
    'Obsolete', // Machines are outdated or obsolete, making them less reliable.
    'No Machines', // There are no machines available for agricultural tasks.
  ];

  const soilTypeChoices = [
    'Sandy', // Well-draining soil with low nutrient-holding capacity.
    'Clayey', // Heavy soil with high water retention but poor drainage.
    'Loamy', // Ideal soil type, a balanced mixture of sand, silt, and clay.
    'Silty', // Smooth and fine-textured soil with moderate drainage.
    'Peaty', // Soil rich in organic matter, often found in wetlands.
    'Chalky', // Alkaline soil with high pH, composed of calcium carbonate.
    'Sandy Loam', // A mixture of sandy and loamy soil, good drainage with moderate fertility.
    'Clay Loam', // A mixture of clay and loamy soil, better drainage than clayey soil.
    'Saline', // Soil with high salt content, unsuitable for most crops.
    'Peat Loam', // A mixture of peaty and loamy soil, rich in organic matter.
  ];

  const plotTypeChoices = [
    'Field', // A large open area of land used for cultivation.
    'Greenhouse', // A controlled environment structure for growing crops.
    'Polytunnel', // A type of greenhouse made with polyethylene covering.
    'Orchard', // An area planted with fruit trees for cultivation.
    'Vineyard', // An area planted with grapevines for wine production.
    'Vertical Farm', // A method of growing crops in vertically stacked layers.
    'Raised Bed', // A small, elevated garden bed for intensive cultivation.
    'Hydroponics', // A method of growing plants without soil, using nutrient-rich water.
    'Aquaponics', // A system that combines aquaculture (fish farming) with hydroponics.
    'Agroforestry', // A land use system that combines agriculture with tree cultivation.
  ];

  return (
    <div style={container}>
      <Grid container>
        <Grid xs={6} sm={6} item  md={6}>
          <Typography variant="h6">Location</Typography>
          <Grid container>
            <Grid item sm={6} md={6}>
              <InputWithTitle value={country} onChange={onChangeCountryChange} type={'country'} title={'Country'} />
            </Grid>
            <Grid item sm={6} md={6}>
                 <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                 <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                 City
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <CitySelect
                  value={cityValue}
                  onSelectValue={onSelectValue}
                  selectedValue={city}
                  country={country?.code}
                  onChange={setCityValue}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item  md={6}>
          <Typography variant="h6">Capabilities</Typography>
          <Grid container>
            <Grid item sm={6} md={6}>
              <InputWithTitle
                onChange={setWaterAvailability}
                value={waterAvailability}
                type={'dropDown'}
                title={'Water Availability'}
                values={waterAvailabilityChoices}
              />
            </Grid>
            <Grid item sm={6} md={6}>
              <InputWithTitle
                onChange={setMachineAvailability}
                value={machineAvailability}
                type={'dropDown'}
                title={'Machine Availability'}
                values={machineAvailabilityChoices}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={6} sm={6} item  md={6}>
          <Grid container>
            <Grid xs={6} sm={6} item  md={6}>
                 <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    {'Attitude(m)'}
                  </Typography>
                  <Tooltip title={'Attitude(m)'}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                    <OutlinedInput
                  type="number"
                  onChange={(e) => setAttitude(e.target.value)}
                  value={attitude}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid xs={6} sm={6} item  md={6}>
                 <Box sx={{ marginTop: 1, marginBottom: 1 }}>
              <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    {'Plot Area(ha)'}
                  </Typography>
                  <Tooltip title={'Attitude(m)'}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                    <OutlinedInput
                
                  type="number"
                  onChange={(e) => setArea(e.target.value)}
                  value={area}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item  md={6}>
          <Grid container>
            {/* <Grid xs={6} sm={6} item  md={6}>
              <InputWithTitle
                values={soilTypeChoices}
                onChange={setSoilType}
                value={soilType}
                type={'dropDown'}
                title={'Soil Type'}
              />
            </Grid> */}
            <Grid xs={6} sm={6} item  md={6}>
              <InputWithTitle
                values={plotTypeChoices}
                onChange={setPlotType}
                value={plotType}
                type={'dropDown'}
                title={'Plot Type'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
     
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Geographic Coordinates:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Latitude (Decimal degrees)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setLatitude(e.target.value)}
                  value={latitude}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Longitude (Decimal degrees)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setLongitude(e.target.value)}
                  value={longitude}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Address or Parcel Identification:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Address 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Parcel ID 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setParcelID(e.target.value)}
                  value={parcelID}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    
      {/* Administrative Boundaries */}
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Administrative Boundaries:</Typography>
          <Grid container>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Region 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setRegion(e.target.value)}
                  value={region}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Province 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setProvince(e.target.value)}
                  value={province}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Municipality 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setMunicipality(e.target.value)}
                  value={municipality}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Land Size:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Size (Hectares or Square meters)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setSize(e.target.value)}
                  value={size}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

     

      {/* Soil Type */}
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Soil Type:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Soil Type 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={soilTypeChoices}
                  onChange={(selected) => setSoilType(selected)}
                  value={[soilType]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Soil Characteristics:</Typography>
          <Grid container>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    pH Level
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setPHLevel(e.target.value)}
                  value={pHLevel}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Organic Matter Content (in %)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setOrganicMatterContent(e.target.value)}
                  value={organicMatterContent}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Nutrient Levels 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setNutrientLevels(e.target.value)}
                  value={nutrientLevels}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      {/* Weather Characteristics */}
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Weather Characteristics:</Typography>
          <Grid container>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Temperature 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={['Celsius', 'Fahrenheit']}
                  onChange={(selected) => setTemperature(selected)}
                  value={[temperature]}
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Precipitation 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={['mm', 'inches']}
                  onChange={(selected) => setPrecipitation(selected)}
                  value={[precipitation]}
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Humidity 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={['Percentage']}
                  onChange={(selected) => setHumidity(selected)}
                  value={[humidity]}
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Wind Speed 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={['km/h', 'mph']}
                  onChange={(selected) => setWindSpeed(selected)}
                  value={[windSpeed]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Land Use Type:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Land Use Type 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={landUseTypeChoices}
                  onChange={(selected) => setLandUseType(selected)}
                  value={[landUseType]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

     

      {/* Crop History */}
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Crop History:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Previous Crops
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setPreviousCrops(e.target.value)}
                  value={previousCrops}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Environmental Factors:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Elevation (Meters or Feet)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setElevation(e.target.value)}
                  value={elevation}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Slope (Percentage)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setSlope(e.target.value)}
                  value={slope}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

   

      {/* Proximity to Water Sources */}
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Proximity to Water Sources:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Water Source Type 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={waterSourceChoices}
                  onChange={(selected) => setWaterSourceType(selected)}
                  value={[waterSourceType]}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Distance to Water Source (Meters or Feet)
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="number"
                  onChange={(e) => setDistanceToWaterSource(e.target.value)}
                  value={distanceToWaterSource}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Crop Growth Period:</Typography>
          <Grid container>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Start Date
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    End Date
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Start Time
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="time"
                  onChange={(e) => setStartTime(e.target.value)}
                  value={startTime}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    End Time
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="time"
                  onChange={(e) => setEndTime(e.target.value)}
                  value={endTime}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    
      {/* Data Output Format */}
      <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Data Output Format:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Output Format 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={outputFormatChoices}
                  onChange={(selected) => setOutputFormat(selected)}
                  value={[outputFormat]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Data Source:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Data Source 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={dataSourceChoices}
                  onChange={(selected) => setDataSource(selected)}
                  value={[dataSource]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Search and Filter */}
      {/* <Grid container>
        <Grid xs={6} sm={6} item md={6}>
          <Typography variant="h6">Search and Filter:</Typography>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Search Text 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  sx={noOutline}
                  placeholder="Please enter value"
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                <Grid container sx={{ margin: 0, m: 0, p: 0 }}>
                  <Typography variant="span" sx={{ fontWeight: '300', marginRight: 1 }}>
                    Filter Option 
                  </Typography>
                  <Tooltip title={''}>
                    <InfoOutlinedIcon sx={{ width: 15 }} />
                  </Tooltip>
                </Grid>
                <DropDown
                  values={filterOptionChoices}
                  onChange={(selected) => setFilterOption(selected)}
                  value={[filterOption]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Card sx={{ marginTop: 1, marginBottom: 3 }} style={{ width: '100%', height: '400px' }}>
        <GoogleMapReact
          bootstrapURLKeys={bootstrapURLKeys}
          defaultCenter={defaultCenter}
          center={city}
          zoom={city.lat ? 14 : 8}
          defaultZoom={city.lat ? 2 : 8}
        >
          {city.lat && <Pin lat={city.lat} lng={city.lng} text="My Marker" />}
        </GoogleMapReact>
      </Card>

      <Grid container>
        <Grid md={6} item>
          <Typography variant="span" sx={{ fontWeight: '300' }}>
            Coordinates: {city.lat} {city.lng}
          </Typography>
        </Grid>
        <Grid md={6} sx={{ display: 'flex' }} justifyContent={'end'} item>
          <Typography variant="span" sx={{ fontWeight: '300' }}>
            {city.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={buttonsGridStyle} alignItems={'end'} justifyContent={'end'}>
        <Button variant="contained" onClick={onCancel} sx={buttonStyle2}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave} sx={buttonStyle}>
          {location.state?.farm ? 'Update Plot' : 'Save Plot'}
        </Button>
      </Grid>
    </div>
  );
}
const buttonsGridStyle = { marginTop: 1, width: '100%', display: 'flex', height: '100%' };
const buttonStyle = {
  margin: 1,
  color: 'white',
  backgroundColor: '#02A78B',
  borderRadius: '5px',
  height: 40,
  minWidth: 100,
  '&:hover': {
    margin: 1,
    color: 'white',
    backgroundColor: '#02A78B',
    borderRadius: '5px',
    height: 40,
    minWidth: 100,
  },
};
const buttonStyle2 = {
  margin: 1,
  color: '#02A78B',
  backgroundColor: 'white',
  borderRadius: '5px',
  height: 40,
  width: 100,
  border: '1px solid #02A78B',
  '&:hover': {
    margin: 1,
    color: '#02A78B',
    backgroundColor: 'white',
    borderRadius: '5px',
    height: 40,
    width: 100,
    border: '1px solid #02A78B',
  },
};
const Pin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="32px" height="32px">
    <path d="M12 0C7.59 0 4 3.59 4 8c0 5.25 8 16 8 16s8-10.75 8-16c0-4.41-3.59-8-8-8zm0 11c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
  </svg>
);

const noOutline = {
  width: 180,
  height: 40,
  marginTop: 1,
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #484850',
    borderRadius: '5px',
  },
};
const container = {
  padding: 30,
};
const waterSourceChoices = [
  'River',                // A natural flowing watercourse, typically a large stream.
  'Lake',                 // A large body of water surrounded by land.
  'Well',                 // A hole dug or drilled into the ground to access groundwater.
  'Pond',                 // A small, still body of water.
  'Spring',               // A natural source of water formed when groundwater reaches the surface.
  'Reservoir',            // A man-made artificial lake used for water storage.
  'Canal',                // An artificial waterway used for irrigation or transportation of water.
  'Rainwater Harvesting', // Collecting and storing rainwater for various purposes.
  'Desalination',         // The process of removing salt and other minerals from seawater to obtain freshwater.
  'Other',                // Other water source types not covered by the above categories.
];
const landUseTypeChoices = [
  'Agriculture',          // Land used for farming or growing crops.
  'Forestry',             // Land primarily used for growing trees and forestry purposes.
  'Urban',                // Land used for urban development, including residential, commercial, and industrial areas.
  'Industrial',           // Land used for industrial purposes and factories.
  'Residential',          // Land primarily used for housing and residential buildings.
  'Recreational',         // Land used for recreational activities such as parks and sports fields.
  'Conservation',         // Land preserved for conservation and protection of natural habitats.
  'Wetland',              // Land with standing water or saturated soil, supporting wetland ecosystems.
  'Mining',               // Land used for mining activities, extracting minerals and resources.
  'Other',                // Other land use types not covered by the above categories.
];
const outputFormatChoices = [
  'PDF',   // Portable Document Format, suitable for printing and sharing documents.
  'Excel', // Microsoft Excel format, ideal for data manipulation and analysis.
  'CSV',   // Comma-Separated Values format, commonly used for exporting tabular data.
  'JSON',  // JavaScript Object Notation format, used for structured data interchange.
  'XML',   // Extensible Markup Language format, for structured data representation.
  'Other', // Other output formats not covered by the above categories.
];
const dataSourceChoices = [
  'Local weather station', // Data collected from a weather station in the local area.
  'Satellite data',        // Data obtained from satellite imagery and remote sensing.
  'Agricom Database',      // Data sourced from the Agricom database.
  'Other',                 // Other data sources not covered by the above categories.
];
const filterOptionChoices = [
  'Soil Type',          // Filter data based on different soil types.
  'Land Use Type',      // Filter data based on different land use types.
  'Crop Type',          // Filter data based on different crop types.
  'Weather Data',       // Filter data based on various weather characteristics.
  'Proximity to Water', // Filter data based on the distance to water sources.
  'Date and Time Range', // Filter data based on a specific date and time range.
];