import { getUserByID } from '../firebase/fucntions/auth';
import firebase from '../firebase/firebase';

const SET_USER = 'SET_USER';
const CLEAR_USER = 'CLEAR_USER';
const SET_LOADING = 'SET_LOADING';
const SET_ERROR = 'SET_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';
const STOP_LOADING = 'STOP_LOADING';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null,
        loading: false,
        error: null,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});
export const stopLoading = () => ({
  type: STOP_LOADING,
});

// Thunk action creator for refreshing user data
export const refreshUserData = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());


      firebase.auth().onAuthStateChanged(async(user) => {

        if (user) {
     
          const userData = await getUserByID(user.uid)
          dispatch(setUser(userData));
        } else {
          // User is not authenticated, clear the user data from the state
          dispatch(setUser(null));
        }

        dispatch(stopLoading());
      });
    } catch (error) {
      dispatch(stopLoading());
      dispatch(setError(error.message));
    }
  };
};

export default userReducer;
