import { Grid, Typography, Card } from '@mui/material';
import React from 'react';

export default function AddPlot(props) {
  const style = {
    backgroundColor: props.color,
    height: 140,
    borderRadius: 20,
    cursor: 'pointer',
    maxWidth: 900,
  };

  return (
    <Grid onClick={props.onClick} style={{...style,...props.style}} container justifyContent="center" alignItems="center">
      <Grid item spacing={10}>
        <Typography variant="h6">+ Add {props.name}</Typography>
      </Grid>
    </Grid>
  );
}
