/* eslint-disable */
import {
  Grid,
  Typography,
  Card,
  Input,
  Button,
  ButtonGroup,
  IconButton,
  Avatar,
  Collapse,
  CardContent,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { crops } from '../utils/DataStructures';
import Peppers from '../assets/peppers.png';
import dollar from '../assets/dollar-outline.png';
import BoxImg from '../assets/box.png';
import Eye from '../assets/mdi_eye-outline.png';

export default function InventoryClientsCalendar(props) {
  const [viewDetails, setViewDetails] = useState(false);
  // Function to generate a random value between min and max
  const getRandomSymbol = () => {
    return Math.random() < 0.9 ? '▲' : '▼';
  };

  const symbol = getRandomSymbol();
  const textStyle = {
    color: symbol === '▲' ? '#0ca488' : 'red',
    fontSize: '9pt',
    lineHeight: props.viewDetails ? '10px' : '0px',
    marginTop: '5px',
    textAlign: 'center',
    marginLeft: '5px',
  };
  const getRandomSymbol2 = () => {
    return Math.random() < 0.5 ? '▲' : '▼';
  };

  const symbol2 = getRandomSymbol2();
  const textStyle2 = {
    color: symbol2 === '▲' ? '#0ca488' : 'red',
    fontSize: '9pt',
    lineHeight: props.viewDetails ? '10px' : '0px',

    marginBottom: '5px',
    textAlign: 'center',
    marginLeft: '5px',
  };

  const getRandomValue = () => {
    const randomNum = Math.random();
    if (randomNum < 0.33) {
      return 'High';
    } else if (randomNum < 0.66) {
      return 'Medium';
    } else {
      return 'Low';
    }
  };

  const getColor = (value) => {
    switch (value) {
      case 'High':
        return '#D9F2EE';
      case 'Medium':
        return '#FBF9DF';
      case 'Low':
        return '#F4E1E1';
      default:
        return 'white'; // Default color if not 'High', 'Medium', or 'Low'
    }
  };

  const getTextColor = (value) => {
    switch (value) {
      case 'High':
        return '#02A78B';
      case 'Medium':
        return '#928401';
      case 'Low':
        return '#DD5555';
      default:
        return 'white'; // Default color if not 'High', 'Medium', or 'Low'
    }
  };

  const chipStyle = {
    color: getTextColor(''),
    backgroundColor: getColor(''), // Initial color
    borderColor: '#02A78B',
    borderWidth: 1,
    borderRadius: '33px',
    backgroundColor: '#00000022',
    cursor: 'pointer',
    overflowX: 'hidden', // Hide the horizontal scrollbar
    marginLeft: 1,
    fontSize: '11px',
    color: '#00000078',
    paddingRight: '15px',
    paddingLeft: '15px',
    maxWidth: '125px', // Set a minWidth instead of width
    height: 30,
  };

  return (
    <Box>
      <Grid container>
        <Grid style={{ ...subItemStyle }} item xs={2} sm={2} md={2} lg={2}>
          <Grid container height={'100%'}>
            <Grid
              paddingBottom={2}
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              height={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box style={{ height: '100%', width: '100%' }}>
                <Box
                  style={{ height: '50%', backgroundColor: 'white', width: '100%', position: 'relative' }}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Typography
                    variant="span"
                    sx={{ fontSize: '11pt', fontWeight: 'bold', paddingRight: 1, textAlign: 'center' }}
                  >
                    {/* Tomato */}
                    {props.client.label ? props.client.label.split(',')[0].trim() : 'Unnamed'}
                  </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Avatar
                    sx={{ width: '70px', height: '70px', fontSize: '13px', color: 'black', backgroundColor: '#f1f2f3' }}
                  >
                    C
                  </Avatar>
                  {/* <img style={imageStyle} src={props.crop ? props.crop.Pictures : Peppers} alt="crop" /> */}
                </Box>
              </Box>
            </Grid>
            <div
              style={{
                borderLeft: ' 1px solid #A3A4A5',
                height: viewDetails ? 500 : 100,
                marginTop: !viewDetails ? '10px' : '0px',
                marginBottom: !viewDetails ? '10px' : '0px',
                padding:  5 ,
              }}
            />
            <Box style={{ ...itemStyle, paddingTop: '15px', marginLeft: '10px' }}>
              <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                <img src={BoxImg} alt="crop" />
              </div>{' '}
              <br />
              {/* <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                <img src={dollar} alt="crop" />
              </div> */}
            </Box>
          </Grid>
        </Grid>
        <Grid style={{ ...itemStyle, paddingTop: '18px', paddingRight: 15 }} item xs={12} sm={10} md={10} lg={10}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            {Array.from({ length: 7 }, (_, index) => {
              const randomValue = getRandomValue();
              const backgroundColor = getColor(randomValue);
              const color = getTextColor(randomValue);
              const getRandomSymbol = () => {
                return Math.random() < 0.85 ? '▲' : '▼';
              };

              const symbol = getRandomSymbol();
              const textStyle = {
                color: symbol === '▲' ? '#0ca488' : 'red',
                fontSize: '9pt',
                lineHeight: props.viewDetails ? '10px' : '0px',
                marginTop: '5px',
                textAlign: 'center',
                marginLeft: '5px',
              };
              return (
                <Grid
                  item
                  sm={3}
                  md={3}
                  lg={3}
                  key={index}
                  sx={{
                    ...chipStyle,
                    backgroundColor,
                    color,
                  }}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid sm={6} md={6} lg={6}>
                      <Typography fontSize={{ md: '12px', lg: '14px' }} textAlign={'start'}>
                        {randomValue}
                      </Typography>
                    </Grid>
                    <Grid sm={6} md={6} lg={6}>
                      <Typography textAlign={'end'} fontWeight={'bold'}>
                        <span style={textStyle}>{symbol}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <div>
            <span
              style={{ color: '#6D6D6D', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}
            >
              Expected{' '}
            </span>
            <span
              style={{ color: '#02A78B', fontSize: 16, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}
            >
              high demand
            </span>
            <span
              style={{ color: '#6D6D6D', fontSize: 16, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}
            >
              {' '}
            </span>
            <span
              style={{ color: '#6D6D6D', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}
            >
              from this client
            </span>
            <span
              style={{ color: '#6D6D6D', fontSize: 16, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}
            >
              {' '}
            </span>
            <span
              style={{ color: '#6D6D6D', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}
            >
              for
            </span>
            <span
              style={{
                color: '#6D6D6D',
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: '400',
                textDecoration: 'underline',
                wordWrap: 'break-word',
              }}
            >
              {' '}
            </span>
            <span
              style={{
                color: '#0DAB90',
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: '600',
                textDecoration: 'underline',
                wordWrap: 'break-word',
              }}
            >
              Tomato Operino.{' '}
            </span>
          </div>
        </Grid>
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'end',
            fontSize: '12px',
            justifyContent: 'end',
            position: 'absolute',
            padding: 3,
            paddingRight: 15,
            paddingBottom: 0,
            color: '#8C8C8C',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={() => {
              setViewDetails((prev) => !prev);
            }}
          >
            View Details{' '}
            <div style={{ height: 30, display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
              <img src={Eye} alt="crop" />
            </div>
          </Box>
        </Box>
        <Collapse in={viewDetails} style={{ width: '100%' }} timeout="auto" unmountOnExit>
          <CardContent height={'100%'} style={{ backgroundColor: '#fff', width: '100%', padding: 15, paddingLeft: 0 , paddingTop: 0 }}>
            <Grid container style={{ width: '100%', height: '100%' }}>
              <Grid item xs={2} sm={2} md={2} lg={2} height={'100%'}>
                <Grid container height={'100%'}>
                  <Grid
                    paddingBottom={2}
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    height={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Box>
                      {props.filteredData.map((farm, index2) => {
                        return (
                          <>
                            {farm.crops.map((crop, index) => {
                              return (
                               
                                <Grid style={{ display: 'flex', marginBottom: '10px' }}>
                                     <Grid
                  item
                  
                  key={index}
                  sx={{
                    ...chipStyle,
                    backgroundColor:'white',
               
                  }}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid sm={6} md={6} lg={6}>
                      <Typography style={{     color:'black',
                    fontSize:'15px',
                    fontWeight:'bold'}} textAlign={'start'}>
                      {crop.label ?crop.label.split(',')[0].trim() : 'Unnamed'}
                      </Typography>
                    </Grid>
                   
                  </Grid>
                </Grid>
                                  </Grid>
                                )
                          
                            })}
                          </>
                        );
                      })}
                    </Box>
                  </Grid>
                  <div
                    style={{
                      borderLeft: '1px solid #A3A4A5',
                 
                      padding:  5 ,
                    }}
                  >
                    {props.filteredData.map((farm, index2) => {
                      return (
                        <>
                          {farm.crops.map((crop, index) => {
                            return (
                              (index % 2 === 0 || index % 3 === 0) && (
                                <>
                                  <br />
                                  <br />
                                </>
                              )
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                  <Box style={{ ...itemStyle, paddingTop: '15px', marginLeft: '10px' }}>
                    {/* <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                      <img src={BoxImg} alt="crop" />
                    </div>{' '} */}
                    <br />
                    {/* <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                <img src={dollar} alt="crop" />
              </div> */}
                  </Box>
                </Grid>
                {/* {props.filteredData.map((farm, index2) => {
                  return (
                    <>
                      {farm.crops.map((crop, index) => {
                        return   (
                        <div style={{ height: 30, display: 'flex', justifyContent: 'end' }}>
                        <img src={crop.Pictures} style={{height: 50}} alt="crop" />
                      </div>
                      )
                      
              
                      })}
                    </>
                  );
                })} */}
              </Grid>

              <Grid item xs={12} sm={10} md={10} lg={10}>
                {props.filteredData.map((farm, index2) => {
                  return (
                    <>
                      {farm.crops.map((crop, index) => {
                        return (
                          <Grid style={{ display: 'flex', marginBottom: '10px' }}>
                            {Array.from({ length: 7 }, (_, index) => {
                              const randomValue = getRandomValue();
                              const backgroundColor = getColor(randomValue);
                              const color = getTextColor(randomValue);
                              const getRandomSymbol = () => {
                                return Math.random() < 0.85 ? '▲' : '▼';
                              };

                              const symbol = getRandomSymbol();
                              const textStyle = {
                                color: symbol === '▲' ? '#0ca488' : 'red',
                                fontSize: '9pt',
                                lineHeight: props.viewDetails ? '10px' : '0px',
                                marginTop: '5px',
                                textAlign: 'center',
                                marginLeft: '5px',
                              };
                              return (
                                <Grid
                                  item
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  key={index}
                                  sx={{
                                    ...chipStyle,
                                    backgroundColor,
                                    color,
                                  }}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  <Grid container display={'flex'} alignItems={'center'}>
                                    <Grid sm={6} md={6} lg={6}>
                                      <Typography fontSize={{ md: '12px', lg: '14px' }} textAlign={'start'}>
                                        {randomValue}
                                      </Typography>
                                    </Grid>
                                    <Grid sm={6} md={6} lg={6}>
                                      <Typography textAlign={'end'} fontWeight={'bold'}>
                                        <span style={textStyle}>{symbol}</span>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        );
                      })}
                    </>
                  );
                })}
              </Grid>
            </Grid>

            <br />
          </CardContent>
        </Collapse>
      </Grid>
    </Box>
  );
}

const itemStyle = {
  height: 140,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  rowGap: '20px',
};
const subItemStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '120px',
  height: 'auto',
  padding: 5,
  paddingBottom: 35,
};

const imageStyle = {
  maxHeight: 80,
  marginTop: 0,
};
