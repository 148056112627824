import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DashboardLayout from './layouts/dashboard';
import BlogPage from './pages/BlogPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import FarmPage from './pages/FarmPage';
import FarmDetailPage from './pages/FarmDetailPage';
import Step2 from './pages/Step2';
import CreateLandPage from './pages/CreateLandPage';
import { refreshUserData } from './store/user';
import { refreshFarmsData } from './store/farms';
import Register from './pages/Register';
import Profile from './pages/Profile';

export default function Router() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.user);
  const farms = useSelector((state) => state.farms);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    setLoading(true)
  },[])
  useEffect(() => {
    setLoading(true)
    setIsAuthenticated(!!user);
  }, [user]);

  useEffect(() => {
    if (!user) {
      setLoading(true)
      dispatch(refreshUserData());
    } else {
      setLoading(true)
      dispatch(refreshFarmsData(user?.uid))
    };
  }, [user, dispatch]);
  useEffect(() => {
    setLoading(true)
    setLoading(farms?.loading || userState?.loading);
  }, [farms, userState]);

  const loggedInRoutes = (
    <Route path="/" element={<DashboardLayout />}>
    <Route index element={<Navigate to="dashboard/app" />} />
    <Route path="dashboard/app" element={<DashboardAppPage />} />
    <Route path="dashboard/farm" element={<FarmPage />} />
    <Route path="dashboard/products" element={<ProductsPage />} />
    <Route path="dashboard/blog" element={<BlogPage />} />
    <Route path="dashboard/step2" element={<Step2 />} />
    <Route path="dashboard/create" element={<CreateLandPage />} />
    <Route path="profile" element={<Profile />} />
    <Route path="dashboard/farm_detail" element={<FarmDetailPage />} />
  </Route>
  );

  const publicRoutes = (
    <Route>
      <Route index element={<LoginPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<Register />} />
    </Route>
  );

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Routes>
      {isAuthenticated ? loggedInRoutes :  publicRoutes}
      {!loading &&   <Route  path="*" element={<Navigate to="/" />} />}
    </Routes>
  );
}
