import { Card, CardActionArea } from '@mui/material';
import Rainy from '../assets/images/rainy.png';
import Event from './event';

const Week = (props) => {
  return (
    <div>
      <span
        style={{
          marginLeft: '30px',
          color: '#01322A',
          fontWeight: '600',
        }}
      >
        Week {props.myKey}
      </span>
      <br />
      {/* <span style={{ marginLeft: '30px', color: '#9EB1AE' }}>12</span> */}

      <Card
        className="d-flex justify-content-center align-items-center"
        style={{
          // border: '1px solid #C5C5C5',
          marginLeft: '30px',
          width: '170px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // height: '50px',
          borderRadius: '5px',
        }}
      >
        <img src={Rainy} style={{ marginRight: '5px', height: '90%' }} alt="hh" />
        <div style={{ fontSize: '14px' }}>
          Sunny, Rainy
          <br />9 ~ 12
        </div>
      </Card>

      <Card
        style={{
          // border: '1px solid #C5C5C5',
          backgroundColor: '#FFFFFF',
          marginLeft: '30px',
          width: '170px',
          marginTop: '10px',
          height: '600px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        {props.weekEvent?.events.map((singleEvent) => {
          return <Event name={singleEvent.name} value={singleEvent.value} />;
        })}
      </Card>
    </div>
  );
};

export default Week;
