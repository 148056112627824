/* eslint-disable */
/* @ts-nocheck */

import { Grid, Typography, Card, Input, Button, ButtonGroup, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { farms, crops } from '../../utils/DataStructures';

import Peppers from '../../assets/peppers.png';
import NumberInput from '../number-input-outlined';
import { updateCropData } from '../../firebase/fucntions';
import { refreshFarmsData, refreshFarmsDataWithoutLoading } from '../../store/farms';

export default function PlotsCrops(props) {
  const dispatch = useDispatch()
  const currentElement = React.useRef(null);
  const elementInput = React.useRef(null);
  const inputWarning = React.useRef(null);
  const updateButton = React.useRef(null);
  const [area, setArea] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((data) => data.user.user);
  console.log('props.farm');
  console.log(props.crop);
  useEffect(()=>{
    setArea(props.crop.Area);
  },[props.crop.Area,props.plotSelectedFilters,props.cropSelectedFilters])
  const handleDataChange = async (e) => {
    console.log(e.target.value);
    if (parseInt(e.target.value, 10) > parseInt(props.farm.Area, 10)) {
      console.log('You do not have enough space');
      elementInput.current.style.backgroundColor = 'rgba(255, 133, 133, 0.8)';
      inputWarning.current.style.display = 'flex';
      console.log(updateButton);
      updateButton.current.setAttribute('disabled', '');
      
      setArea(props.crop.Area);
      // currentElement.current.style.display = 'none';
      if(e.target.value!==props.crop.Area)
      props.setSelectedCrop(prev => {
        const existingCropIndex = prev.findIndex(crop => crop.id === props.crop.id);
      
        if (existingCropIndex !== -1) {
          // ID already exists in state, remove old data and add new data
          const updatedCrop = { ...prev[existingCropIndex], Area: area };
          return [...prev.slice(0, existingCropIndex), updatedCrop, ...prev.slice(existingCropIndex + 1)];
        }
      
        // ID doesn't exist in state, add new data
        return [...prev, { ...props.crop, Area: area }];
      });
      
    } else {
      setArea(e.target.value);
      elementInput.current.style.backgroundColor = 'white';
      inputWarning.current.style.display = 'none';
      if(e.target.value!==props.crop.Area)
      props.setSelectedCrop(prev => {
        const existingCropIndex = prev.findIndex(crop => crop.id === props.crop.id);
      
        if (existingCropIndex !== -1) {
          // ID already exists in state, remove old data and add new data
          const updatedCrop = { ...prev[existingCropIndex], Area: area };
          return [...prev.slice(0, existingCropIndex), updatedCrop, ...prev.slice(existingCropIndex + 1)];
        }
      
        // ID doesn't exist in state, add new data
        return [...prev, { ...props.crop, Area: area }];
      });
      // currentElement.current.style.display = 'flex';
      // props.setSelectedCrop(prev=>[...prev,user.uid, props.farm.id, props.crop.id, { ...props.crop, Area: area }])
    }
  };

  const showButtons = async () => {
    currentElement.current.style.display = 'flex';
  };

  const hideButtons = async () => {
    currentElement.current.style.display = 'none';
  };
  const update = async () => {

    setLoading(true);
    await updateCropData(user.uid, props.farm.id, props.crop.id, { ...props.crop, Area: area }).then(() => {
      dispatch(refreshFarmsDataWithoutLoading(user?.uid))
      setLoading(false);
      
      hideButtons();
    });
  };

  const RowWithColors = () => {
    return (
      <Box>
        <Grid container sx={box}>
          <div style={firstRow2}>
            <Typography variant="subtitle1" sx={typographyStyle}>
              Planting
            </Typography>
          </div>
          <div style={firstRow2}>
            <Typography variant="subtitle1" sx={typographyCenterStyle}>
              Growth
            </Typography>
          </div>
          <div style={firstRow2}>
            <Typography variant="subtitle1" sx={typographyStartStyle}>
              Harvest
            </Typography>
          </div>
        </Grid>
        <Box sx={box}>
          <Box sx={firstRow}>
            <Typography variant="subtitle1" sx={typographyStyle}>
              Week {props.crop?.weekStart2}
            </Typography>
          </Box>
          <Box sx={middleRow}>
            <Typography variant="subtitle1" sx={typographyStyle}>
              Week {props.crop?.weekStart2 + 19}
            </Typography>
          </Box>

          <Box sx={lastRow}>
            <Typography variant="subtitle1" sx={lastTypographyStyle}>
              Week 46
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
    <Grid sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        
      <Card style={style} container className="shadow">
        <Grid style={{ ...subItemStyle, ...imageStyle }} item xs={1} sm={1} md={2}>
          <img src={props.crop ? props.crop.Pictures : Peppers} alt="crop" />
        </Grid>
        <Grid style={{ ...itemStyle, paddingLeft: '30px' }} item xs={3} sm={3} md={3} lg={5}>
          <div>
            <Typography style={{ fontWeight: 'normal' }} variant="h5">
              {props.crop?.label}
            </Typography>
            <Typography variant="span">{props.crop?.Variety}</Typography>
            <Box sx={{ ...row }}>
              <Input
                type="number"
               value={area}
                style={{ width: '50%', textAlign: 'center' }}
                // sx={{ textAlign: 'center' }}
                onFocus={() => showButtons()}
                ref={elementInput}
                onChange={(e) => handleDataChange(e)}
                // sx={input{textAlign:'center'}}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <span style={{ fontWeight: 'bold', color: 'gray' }}>ha</span>
            </Box>
            <Typography variant="span" sx={{ color: 'red', display: 'none' }} ref={inputWarning}>
              Maximum area is {props.farm?.Area}. Please adjust accordingly
            </Typography>
          </div>
        </Grid>
        <Grid style={{ ...itemStyle }} item xs={6} sm={7} md={7} lg={7}>
          <div style={{ width: '100%' }}>
            <RowWithColors />
          </div>
        </Grid>
      </Card>
      <ButtonGroup
        ref={currentElement}
        sx={{
          display: 'none',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
          maxWidth: '900px',
        }}
      >
        {loading && <CircularProgress />}
        <Button onClick={update}>Update</Button>
        <Button onClick={() => hideButtons()}>Cancel</Button>
      </ButtonGroup>
    </Grid>
    </>
   
  );
}

const firstRow = {
  width: '33.33%',
  height: 10,
  backgroundColor: 'grey',
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
};
const firstRow2 = {
  width: '33.33%',
};
const lastRow = {
  width: '33.33%',
  height: 10,
  backgroundColor: '#4FA856',
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
};
const middleRow = { width: '33.33%', height: 10, backgroundColor: '#F2BB42' };
const typographyStyle = { textAlign: 'end', paddingTop: 1.5, marginRight: -2.5, fontSize: '12pt', fontWeight: '100' };
const typographyCenterStyle = {
  textAlign: 'center',
  paddingTop: 1.5,
  marginRight: -2.5,
  fontSize: 12,
  fontWeight: '100',
};
const typographyStartStyle = {
  textAlign: 'start',
  paddingTop: 1.5,
  marginRight: -2.5,
  fontSize: 12,
  fontWeight: '100',
};
const lastTypographyStyle = { textAlign: 'end', paddingTop: 1.5, fontSize: '12pt', fontWeight: '100' };

const style = {
  // border: '2px solid #D0D0D0',
  minHeight: 140,
  borderRadius: 20,
  paddingLeft: 15,
  paddingRight: 15,
  maxWidth: 900,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
};
const itemStyle = {
  height: 160,
  display: 'flex',
  alignItems: 'center',
};
const subItemStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const imageStyle = {
  minWidth: 80,
  maxWidth: 110,
  marginLeft: 10,
};
const row = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: 110,
  marginTop: 1,
};
const italicStyle = { fontWeight: '100', color: '#D0D0D0', fontStyle: 'italic' };

const box = { display: 'flex', width: '100%' };
