import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { toast } from 'react-toastify';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Grid, Paper, styled, Typography, Card, CardContent, Container, Button, Box, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilterContainer from './FilterContainer';


import Peppers from '../assets/peppers.png';


const OrderCard = ( props ) => {
  const data = props.data
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = () => {
    const confirmed = window.confirm('Are you sure you want to reorder?');
    if (confirmed) {
    
      props?.setOrdersList( [{ ...data, status: 'Pending' }, ...props.ordersList]);
      toast.success('Reorder completed successfully', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      // Delete the item
    } else {
      // Do nothing
    }
  };
  return (
    <>
      <Card style={{ marginBottom: 15,width:'90%' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={6} md={3} alignItems={'center'} textAlign={'start'}>
              <img src={data.Pictures} style={productImageStyle} alt={'product'} />
            </Grid>
            <Grid item xs={6} md={5}>
              <Typography variant="h4" sx={{ fontWeight: '300' }}>
                {data.value}
              </Typography>
              <Typography variant="span" sx={{ fontWeight: '300' }}>
                Client: {data.clientName}
              </Typography>
              <br />
              <Typography variant="span" sx={{ fontWeight: '300' }}>
                {' '}
                Date: {data.deliveryDate?.toDateString()}
              </Typography>
              <br />
              <Typography variant="span" sx={{ fontWeight: '300' }}>
                Location: {data.deliveryLocation}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} container textAlign={'start'}>
              <Grid container>
                  <Grid item xs={12} md={6} container textAlign={'start'}>
                  <Typography variant="span" sx={{ fontWeight: '300'}}>
                Status: {data.status}
              </Typography>
             
                  </Grid>
                  <Grid item xs={12} md={6} container textAlign={'start'}>
                  <Box sx={boxStyle}>
                <Button variant="contained" onClick={handleClick} sx={buttonStyle}>
                  <AutorenewIcon /> Reorder
                </Button>
              </Box>
                    </Grid>
                
             
              </Grid>
             
              <Box sx={boxStyle2}>
                <Button sx={buttonStyle2} onClick={handleExpandClick}>
                 { expanded?<>{"Hide Details"}<ExpandLess /></>:<>{"View Details"}<ExpandMoreIcon /></>} 
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ backgroundColor: '#fff' }}>
            <Typography paragraph variant="h6">
              Details:
            </Typography>
            Price: {data.price ? data.price : ' -'}$<br />
            Crop Type: {data.CropType ? data.CropType : ' -'}
            <br />
            Variety: {data.Variety ? data.Variety : ' -'}
            <br />
            Average Weight: {data.AverageWeight ? data.AverageWeight : ' -'}
            <br />
            Vigour: {data.Vigour ? data.Vigour : ' -'}
            <br />
            Comments: {data.Comments ? data.Comments : ' -'}
            <br />
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default OrderCard;

const overlay = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // set the background color to a semi-transparent black
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'end',


};
const boxStyle2 = {
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'end',

};
const productImageStyle = {
  maxWidth: 140,
};
const buttonStyle = {
  color: 'white',
  backgroundColor: '#02A78B',
  borderRadius: '20px',
  height: 40,
  width: 100,
  '&:hover': {
    color: '#02A78B',
    backgroundColor: '#fff',
    border: '1px solid #02A78B',
  },
};
const buttonStyle2 = {
  color: '#02A78B',
  height: 40,
};
