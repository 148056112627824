import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { loginUser } from '../../../firebase/fucntions/index';
import Iconify from '../../../components/iconify';
import { setUser } from '../../../store/user';
import ThemeProvider from '../../../theme';

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    try {
      // Perform login operation using your login function
      loginUser(email, password).then((user) => {
        const userData = {
          isLoggedIn: true,
          email: user.email,
          uid: user.uid,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          role: user.role,
        };
        dispatch(setUser(userData));
        navigate('/');
      });

      // Dispatch the setUser action to update the user state
    } catch (error) {
      console.error('Error logging in:', error);
      // Handle error or display error message to the user
    }
  };

  const buttonStyle = {
    color: 'white',
    backgroundColor: '#02A78B',
    '&:hover': {
      backgroundColor: '#02A78B',
    },
  };

  const checkBoxStyle = {
    color: '#02A78B',
    '&$checked': {
      color: '#02A78B',
    },
  };

  return (
    <ThemeProvider>
      <>
        <Stack spacing={3}>
          <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <Checkbox
            sx={checkBoxStyle}
            checked={false}
            onChange={(e) => console.log(e.target.checked)}
            name="remember"
          /> */}
          {/* <Link variant="subtitle2" underline="hover" sx={{ color: '#02A78B' }}>
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton sx={buttonStyle} fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
          Login
        </LoadingButton>
      </>
    </ThemeProvider>
  );
}
