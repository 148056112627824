import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CssBaseline, styled } from '@mui/material';
import { addLocaleData, IntlProvider } from 'react-intl';
import { addMonths, addWeeks, addDays, subMonths, subWeeks, subDays } from 'date-fns';
import * as dateFnsPT from 'date-fns/locale/pt';
import en from 'react-intl/locale-data/en';
import pt from 'react-intl/locale-data/pt';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { flattenMessages } from '../common/utils';
import dictionary from '../common/dictionary';
import { CalendarContext } from '../common/CalendarContext';
import CalendarToolbar from './CalendarToolbar';
import CalendarDrawer from './CalendarDrawer';
import CalendarMain from './CalendarMain';
import CalendarEventDialog from './CalendarEventDialog';
import CalendarEventViewDialog from './CalendarEventViewDialog';
import RightCalendarDrawer from './RightSideCalendar';
import ToolbarItems from './ToolbarItems';

const theme = createTheme();
const useStyles = styled(() => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
}));

const layout = 'week';

let _locale = (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';
_locale = !!Object.keys(dictionary).includes(_locale) ? _locale : 'en-US';

const locale = _locale === 'pt-BR' ? dateFnsPT : null;

const openDialog = false;
const openViewDialog = false;
const selectedDate = new Date();
const defaultEventDuration = 60; // in minutes

function Calendar(props) {
  // const { history, match } = props
  const classes = useStyles();
  // const theme = useTheme()

  addLocaleData([...en, ...pt]);
  const i18nLocale = _locale;
  const localeDictionary = flattenMessages(dictionary[i18nLocale]);

  const changeLanguage = (newLang) => {
    const i18nLocale = newLang.value;
    const newDateFnLocale = i18nLocale === 'pt-BR' ? dateFnsPT : null;

    console.group('changeLanguage');
    console.log('locale: ', newDateFnLocale);
    console.groupEnd();
    setStateCalendar({ ...stateCalendar, locale: newDateFnLocale, i18nLocale });
  };

  // const handleCloseDialog = () => {
  //     console.group('on handleCloseDialog')
  //     console.log({ stateCalendar })
  //     console.groupEnd()
  //     // const {open} = props
  //     setStateCalendar({ ...stateCalendar, openDialog: false })
  // }

  // const handleCloseViewDialog = () => {
  //     console.group('on handleCloseViewDialog')
  //     console.log({ stateCalendar })
  //     console.groupEnd()

  //     // const {open} = props
  //     setStateCalendar({ ...stateCalendar, openViewDialog: false })
  // }

  const [stateCalendar, setStateCalendar] = useState({
    selectedDate,
    locale,
    i18nLocale,
    layout,
    openDialog,
    openViewDialog,
    eventBeginDate: null,
    eventBeginTime: { value: null, label: null },
    eventEndDate: null,
    eventEndTime: { value: null, label: null },
    defaultEventDuration,
    modal: false,
    eventDialogMaxWidth: 'md',
    fullscreen: false,
    allowFullScreen: false,
    withCloseIcon: true,
    title: '',
    content: '',
    actions: '',
    calendarEvent: {},
    draggingEventId: -1,
    startDragging: false,
    ghostProperties: { width: 0, height: 0, date: new Date() },
    // handleCloseDialog,
    // handleCloseViewDialog,
  });

  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerClose2 = () => {
    setOpen2(false);
  };
  const [runAnimation, setRunAnimation] = useState(true);

  // const applyLink = (newDate: Date) => {
  //     history.push(`/d/${layout}/${format(newDate, "YYYY/MM/DD")}`)
  // }

  const goToToday = () => {
    setRunAnimation(false);
    const newDate = new Date();
    setStateCalendar({ ...stateCalendar, selectedDate: newDate });
    // applyLink(newDate)
  };

  const handleLayoutChange = (args) => {
    const { value } = args;
    setStateCalendar({ ...stateCalendar, layout: value });
    // history.push(`/d/${value}/${format(selectedDate, "YYYY/MM/DD")}`)
  };

  const next = () => {
    setRunAnimation(false);
    let newDate;

    switch (stateCalendar.layout) {
      case 'week':
        newDate = addWeeks(stateCalendar.selectedDate, 1);
        break;

      case 'day':
        newDate = addDays(stateCalendar.selectedDate, 1);
        break;

      default:
        // month
        newDate = addMonths(stateCalendar.selectedDate, 1);
        break;
    }
    setStateCalendar({ ...stateCalendar, selectedDate: newDate });
    // applyLink(newDate)
  };

  const previous = () => {
    setRunAnimation(false);
    let newDate;

    switch (stateCalendar.layout) {
      case 'week':
        newDate = subWeeks(stateCalendar.selectedDate, 1);
        break;

      case 'day':
        newDate = subDays(stateCalendar.selectedDate, 1);
        break;

      default:
        // month
        newDate = subMonths(stateCalendar.selectedDate, 1);
        break;
    }
    setStateCalendar({ ...stateCalendar, selectedDate: newDate });
    // applyLink(newDate)
  };

  // useEffect(() => {
  //     // selectedDate !== null && applyLink(selectedDate)
  //     setTimeout(() => {
  //         setRunAnimation(true)
  //     }, 1)
  // }, [selectedDate])

  return (
    <CalendarContext.Provider value={{ stateCalendar, setStateCalendar }}>
      <IntlProvider locale={i18nLocale} messages={localeDictionary}>
        <div className={classes.root}>
          <CssBaseline />

<ToolbarItems
     {...props}
     goToToday={goToToday}
     next={next}
     previous={previous}
     open={open}
     handleDrawerOpen={handleDrawerOpen}
     handleDrawerClose={handleDrawerClose}
     handleLayoutChange={handleLayoutChange}
     changeLanguage={changeLanguage}
/>
          {/* <CalendarToolbar
            {...props}
            goToToday={goToToday}
            next={next}
            previous={previous}
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            handleLayoutChange={handleLayoutChange}
            changeLanguage={changeLanguage}
          /> */}

          <CalendarDrawer
            {...props}
            selectedDate={selectedDate}
            next={next}
            previous={previous}
            open={open}
            open2={open2}
            handleDrawerClose={handleDrawerClose}
            handleDrawerClose2={handleDrawerClose2}
            layout={'month'}
            locale={locale}
          />

          <CalendarMain
            {...props}
            // selectedDate={selectedDate}
            open={open}
            open2={open2}
            // layout={layout}
            runAnimation={runAnimation}
          />
  
          <RightCalendarDrawer
            {...props}
            selectedDate={selectedDate}
            next={next}
            previous={previous}
            open2={open2}
            handleDrawerClose2={handleDrawerClose2}
            layout={'month'}
            locale={locale}
          />
          <CalendarEventDialog />
          <CalendarEventViewDialog />
        </div>
      </IntlProvider>
    </CalendarContext.Provider>
  );
}

export default Calendar;
