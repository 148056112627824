import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 220,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular,
  };
}
const noOutline = {
  height: 33,

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #484850',
    borderRadius: '5px',
  },
};

export default function DropDown({ onChange, value, values,className }) {
  const names =
    values?.length > 0
      ? values
      : [
          'Oliver Hansen',
          'Van Henry',
          'April Tucker',
          'Ralph Hubbard',
          'Omar Alexander',
          'Carlos Abbott',
          'Miriam Wagner',
          'Bradley Wilkerson',
          'Virginia Andrews',
          'Kelly Snyder',
        ];

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    onChange(value);
  };

  return (
    <div>
      <FormControl sx={{ width: 180 }} className={className}>
        <Select
          sx={noOutline}
          displayEmpty
          value={value}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span>Select</span>;
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <span>Select</span>
          </MenuItem>
          {names.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, value, theme)}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
