/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, styled } from '@mui/material';
import { createStyles, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DropDown from '../../../drop-down';
import CalendarSmall from '../engine_components/CalendarSmall';
import FilterContainer from '../../../FilterContainer';
import AppContext from '../Context';

const drawerWidth = 250;
const theme = createTheme();

const useStyles = makeStyles(() =>
  createStyles({
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      marginTop: -2,
      backgroundColor: '#fff',
      overflowY:'scroll'
    },
    drawerPaper: {
      top: '90px',
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 64,
      ...theme.mixins.toolbar,
      marginLeft: theme.spacing(2),

    },
    calendarSmall: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(1),
      minHeight: 265,
      minWidth: 240,
      background: theme.palette.background.paper,
    },
    select: {
      width: 200,
    },
  })
);

function CalendarDrawer(props) {
  const { open, handleDrawerClose } = props;




  const [dates, setDates] = React.useState([]);

  const {
    listOfFilter, setListOfFilter,
    setListOfCropFilter,
    plotSelectedFilters,
    setPlotSelectedFilters,
    setCropSelectedFilters,
    setStatusFilters,
    listOfCropFilter,
    setRatingSelectedFilters,
    setFilteredData,
    cropSelectedFilters
    } = useContext(AppContext)

  

  const classes = useStyles();
  const theme = useTheme();
  const {
    pageView,
    handleViewChangeSelect,
    farmData,
  } = props;

  useEffect(() => {
    setFilteredData(filterCrops(onFilterPlotChange()));

  }, [cropSelectedFilters,plotSelectedFilters]);

  const onFilterPlotChange = () => {
    if (plotSelectedFilters.length > 0) {
      const filteredFarms = farmData.farms.filter((farm) => {
        return plotSelectedFilters.some((filter) => filter.label === farm.label);
      });
      return filteredFarms;
    }
    return farmData.farms;
  };
  const filterCrops = (filteredData) => {
    if (cropSelectedFilters.length > 0) {
      const filteredFarms = filteredData
        .filter((farm) => {
          const filteredCrops = farm.crops.filter((crop) => {
            return cropSelectedFilters.some((filter) => filter.label === crop.label);
          });
          return filteredCrops.length > 0;
        })
        .map((farm) => {
          return {
            ...farm,
            crops: farm.crops.filter((crop) => {
              return cropSelectedFilters.some((filter) => filter.label === crop.label);
            }),
          };
        });
      return filteredFarms;
    }
    return filteredData;
  };

  useEffect(() => {
    const allCrops = farmData.farms.flatMap((farm) =>
    farm?.crops ? farm?.crops?.map((crop) => ({ ...crop, label: crop.label })) : []
  );
  console.log({ allCrops });
  setListOfCropFilter(allCrops);
  }, [farmData]);

  useEffect(() => {
    const uniqueFilterValues = new Set();
    farmData.farms.forEach((data) => {
      uniqueFilterValues.add(data.label);
    });
    const filters = Array.from(uniqueFilterValues).map((value) => ({
      label: value,
      value,
    }));
    setListOfFilter(filters);
  }, []);
 
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <br/>
      <div className={classes.drawerHeader}>
      <FormControl >
          <DropDown    className={classes.select} values={['Crops', 'Clients', 'Orders','Timeline']} onChange={handleViewChangeSelect} value={pageView} />
        </FormControl>
        {/* <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton> */}
       
      </div>
    
      {/* <Divider /> */}
      <div className={classes.calendarSmall}>
        <CalendarSmall />
      </div>
      <Divider />
      <div style={{ padding: 10 }}>
        <FilterContainer
        
          status
          removeDate
          removeRatings
          plotSelectedFilters={plotSelectedFilters}
          setPlotSelectedFilters={setPlotSelectedFilters}
          listOfFilter={listOfFilter}
          setCropSelectedFilters={setCropSelectedFilters}
          setStatusFilters={setStatusFilters}
          listOfCropFilter={listOfCropFilter}
          setRatingSelectedFilters={setRatingSelectedFilters}
 
        />
      </div>

      {/* {"{info/notes}"} */}
    </Drawer>
  );
}

export default React.memo(CalendarDrawer);