import styled from '@emotion/styled';
import { Grid, Box, Typography, CircularProgress } from '@mui/material';

const Chart = (props) => {

  const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color:  props.status === 'Sold'
    ? '#4ca856'
    : props.status === 'Available'
    ? '#c8ac00'
    : props.status === 'Revenue'
    ? '#f0bb43'
    : '#7c767c', // Replace with your custom color
  }));
  return (
    <Box
     
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'rotate(-135deg)',
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            position: 'absolute',
            zIndex: 1,
            transform: 'rotate(135deg)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '10pt',
              marginBottom: '0',
              color:
                props.status === 'Sold'
                  ? '#4da752'
                  : props.status === 'Available'
                  ? '#cdab00'
                  : props.status === 'Revenue'
                  ? '#f2bb42'
                  : '#737575',
            }}
          >
            Actual
          </span>
          <h3
            style={{
              margin: 0,
              color:
                props.status === 'Sold'
                  ? '#4ca856'
                  : props.status === 'Available'
                  ? '#c8ac00'
                  : props.status === 'Revenue'
                  ? '#f0bb43'
                  : '#7c767c',
            }}
          >
            25,758$
          </h3>
          <span
            style={{
              fontSize: '10pt',
              marginBottom: '0',
              color:
                props.status === 'Sold'
                  ? '#4ca856'
                  : props.status === 'Available'
                  ? '#c8ac00'
                  : props.status === 'Revenue'
                  ? '#f0bb43'
                  : '#7c767c',
            }}
          >
            Expected
          </span>

          <h5
            style={{
              margin: 0,
              color:
                props.status === 'Sold'
                  ? '#4ca856'
                  : props.status === 'Available'
                  ? '#c8ac00'
                  : props.status === 'Revenue'
                  ? '#f0bb43'
                  : '#7c767c',
            }}
          >
            50,750$
          </h5>
        </Typography>

        <CustomCircularProgress
          variant="determinate"
          size={150}
          thickness={4}
          value={50}
          color={
            props.status === 'Sold'
              ? 'success'
              : props.status === 'Available'
              ? 'warning'
              : props.status === 'Revenue'
              ? 'secondary'
              : 'grey'
          }
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            ...(50 === 100 && {
              color: '#00e676',
            }),
          }}
        />

        <CircularProgress
          variant="determinate"
          size={150}
          thickness={4}
          value={75}
          sx={{
            color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
          }}
        />
      </Box>
      <h3
        style={{
          color:
            props.status === 'Sold'
              ? '#4ca856'
              : props.status === 'Available'
              ? '#c8ac00'
              : props.status === 'Revenue'
              ? '#f0bb43'
              : '#7c767c',
           
        }}
      >
        {props.status}
      </h3>
    </Box>
  );
};

export default Chart;
