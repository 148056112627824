import { getAllFarmsByUserId } from '../firebase/fucntions/index';
import data from '../utils/mockData';

const mergeCropsWithWeeks = (data2) => {
  const plots = data2;
  for (let i = 0; i < plots.length; ) {
    const plot = plots[i];
    for (let j = 0; j < plot.crops.length; ) {
      const crop = plot.crops[j];
      let weeks = data[containsString(crop.name)]
        ? Object.values(data[containsString(crop.name)])
        : Object.values(data.Sweeterno);
      weeks = weeks.filter((week) => {
        return Object.keys(week).length > 1;
      });
      const newWeeks = [];
      plot.weekEnd = plot.weekStart + weeks.length;
      weeks.forEach((week) => {
        const tempWeek = { ...week };
        tempWeek.week = parseInt(tempWeek.week.replace(',', '.'), 10) + (plot.weekStart - 1);

        newWeeks.push(tempWeek);
      });
      crop.weeks = newWeeks;
      j += 1;
    }
    i += 1;
  }
  return plots;
};

function containsString(inputString) {
  return data.Var.find((string) => inputString?.includes(string));
}

const initialState = {
  farms: mergeCropsWithWeeks([]),
  loading: false,
  error: null,
};

const ADD_FARM = 'ADD_FARM';
const UPDATE_FARM = 'UPDATE_FARM';
const DELETE_FARM = 'DELETE_FARM';
const GET_FARMS = 'GET_FARMS';
const ADD_CROP = 'ADD_CROP';
const SET_LOADING = 'SET_LOADING';
const SET_FARMS = 'SET_FARMS';
const SET_ERROR = 'SET_ERROR';
const STOP_LOADING = 'STOP_LOADING';

export const farmReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case SET_FARMS:
      return {
        ...state,
        farms: action.payload,
        loading: false,
        error: null,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_FARM:
      return {
        ...state,
        farms: [...state.farms, action.payload],
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FARM:
      return {
        ...state,
        farms: state.farms.map((farm) => (farm.id === action.payload.id ? action.payload : farm)),
      };
    case DELETE_FARM:
      return {
        ...state,
        farms: state.farms.filter((farm) => farm.id !== action.payload),
      };
    case GET_FARMS:
      return {
        ...state,
        farms: action.payload, // Update the farms array with the fetched data
      };
    case ADD_CROP:
      return {
        ...state,
        farms: state.farms.map((farm) => {
          if (farm.id === action.payload.farmId) {
            const newCrop = action.payload;
            newCrop.weeks = [];
            action.payload.value = action.payload.name;
            action.payload.label = action.payload.name;
            console.log(newCrop);
            return {
              ...farm,
              crops: [...farm.crops, newCrop],
            };
          }

          return farm;
        }),
      };
    default:
      return state;
  }
};

export function addCrop(Area, Pictures, name, weekStart2, farmId,id) {
  return {
    type: ADD_CROP,
    payload: {
      Area,
      Pictures,
      name,
      weekStart2,
      farmId,
      id,
    },
  };
}

export function addFarm(payload) {
  return { type: ADD_FARM, payload };
}

export function deleteFarm(payload) {
  addCrop(payload)
  return { type: DELETE_FARM, payload };
}

export function updateFarm(payload) {
  return { type: UPDATE_FARM, payload };
}

export function getFarms(userID) {
  return async (dispatch) => {
    try {
      const farmsData = await getAllFarmsByUserId(userID); // Fetch farms data from Firebase using the provided userID
      const mergedData = mergeCropsWithWeeks(farmsData); // Merge the fetched data with weeks
      dispatch({ type: GET_FARMS, payload: mergedData }); // Dispatch the merged data to the reducer
    } catch (error) {
      // Handle error if the Firebase request fails
      console.error('Failed to fetch farms data:', error);
    }
  };
}
export const setFarms = (farms) => ({
  type: SET_FARMS,
  payload: farms,
});

export const setLoading = () => ({
  type: SET_LOADING,
});
export const stopLoading = () => ({
  type: STOP_LOADING,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

// Thunk action creator for refreshing user data
export const refreshFarmsData = (userID) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());

      // Call the Firebase API to fetch the updated user data
      const farms = await getAllFarmsByUserId(userID);
 
      // Dispatch the setUser action with the updated user data
      dispatch(setFarms(farms));
      dispatch(stopLoading());
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
};
export const refreshFarmsDataWithoutLoading = (userID) => {
  return async (dispatch) => {
    try {

      // Call the Firebase API to fetch the updated user data
      const farms = await getAllFarmsByUserId(userID);
      // Dispatch the setUser action with the updated user data
      dispatch(setFarms(farms));
      dispatch(stopLoading());
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
};
