import { useDispatch, useSelector, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Paper, styled, Typography, Box, LinearProgress, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checked from '../assets/images/Checked.png';
import Star from '../assets/images/Star.png';
import FilterContainer from '../components/FilterContainer';
import SliderDate from '../components/slider-date-filter';
import AddPlot from '../components/add-plot';
import PlotsCrops from '../components/plots-crops';
import MultiSelect from '../components/chip-multi-select';
import { getAllFarmsByUserId } from '../firebase/fucntions';

function FarmDetailPage() {
  const navigate = useNavigate();
  const [listOfFilter, setListOfFilter] = React.useState([]);
  const [listOfCropFilter, setListOfCropFilter] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const farmData = useSelector((state) => state.farms);
  const [plotSelectedFilters, setPlotSelectedFilters] = React.useState([]);
  const [cropSelectedFilters, setCropSelectedFilters] = React.useState([]);
  const [ratingSelectedFilters, setRatingSelectedFilters] = React.useState([]);
  const [minWeek, setMinWeek] = React.useState(0);
  const [maxWeek, setMaxWeek] = React.useState(52);

  useEffect(() => {
   
    setListOfFilter(location.state.myFarm.crops);
    setFilteredData(location.state.myFarm.crops);
  }, []);

  useEffect(() => {
    setFilteredData(filterCrops());
    const allCrops = onFilterPlotChange().flatMap((farm) => farm.crops.map((crop) => ({ ...crop, label: crop.label })));
    setListOfCropFilter(allCrops);
  }, [ratingSelectedFilters, plotSelectedFilters, cropSelectedFilters, minWeek, maxWeek]);

  const onFilterPlotChange = () => {
    if (plotSelectedFilters.length > 0) {
      const filteredFarms = farmData.farms.filter((farm) => {
        return plotSelectedFilters.some((filter) => filter.label === farm.label);
      });
      return filteredFarms;
    }
    return farmData.farms;
  };
  function filterFarmsByWeek(farms) {
    return farms.filter((farm) => farm.weekStart >= minWeek && (farm.weekEnd ? farm.weekEnd <= maxWeek : true));
  }

  const filterCrops = () => {
    if (cropSelectedFilters.length > 0) {
      const filteredFarms = location.state.myFarm.crops.filter((crop) => {
        // Check if the crop label is present in cropSelectedFilters
        return cropSelectedFilters.some((filter) => filter.value === crop.label);
      });
   
      return filteredFarms;
    }

    return location.state.myFarm.crops;
  };
  const onFilterRatingChange = (filteredData) => {
    if (ratingSelectedFilters.length > 0) {
      const filteredFarms = filteredData.filter((farm) => {
        return ratingSelectedFilters.some((filter) => filter.label === farm.rating);
      });
      return filteredFarms;
    }
    return filteredData;
  };
  function getWeek(date) {
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const diff = date - yearStart + (yearStart.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    const week = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return week;
  }
  const onDateChange = (date) => {
    const minWeek = getWeek(new Date(date[0]));
    const maxWeek = getWeek(new Date(date[1]));
    setMinWeek(minWeek);
    setMaxWeek(maxWeek);
    console.log(minWeek, maxWeek);
  };

  const [selectedCrop, setSelectedCrop] = React.useState([]);
  const myRef = React.useRef(null);

  const location = useLocation();
  console.log(location.state.myFarm);
  const onChange = ()=>{

  }
  return (
    <Grid container spacing={7} sx={{ ...gridStyles, ...tallGrid }}>
      <Grid item xs={11} md={2}>
        <Contents>
          <Typography sx={typographyStyle} variant="span">
            Crops
          </Typography>
          <MultiSelect onFilterChange={setCropSelectedFilters} listOfFilterProp={listOfCropFilter} />
        </Contents>

        <Contents>
          <Typography variant="span">Date Range</Typography>
          <SliderDate onChange={onDateChange} />
        </Contents>
      </Grid>
      <Grid item xs={11} md={7} sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
          <Grid>
            <span
              style={{ fontSize: '22pt', fontWeight: 'bold' }}
            >{`${location.state.myFarm.value} (${location.state.myFarm.Area} ha)`}</span>
            {location.state.myFarm.Coordinates}
            <Button
              onClick={() => {
                navigate(`/dashboard/create`, { state: { farm: location.state.myFarm, farmID:location.state.farmID } });
              }}
            >
              Edit Plot
            </Button>
          </Grid>

          {filteredData?.map((crop) => {
            return (
              <div ref={myRef}>
                <PlotsCrops
                  crop={crop}
                  setSelectedCrop={setSelectedCrop}
                  plotSelectedFilters={plotSelectedFilters}
                  cropSelectedFilters={cropSelectedFilters}
                  farm={location.state.myFarm}
                />
              </div>
            );
          })}

          {/* <AddPlot
                onClick={() => {
                  navigate(`/dashboard/step2`, { state: { uid: farm.id, farmName: farm.value } });
                }}
                name="Crop"
                color={'#E0F0ED'}
              /> */}
        </div>

        <br />
        <br />
      </Grid>
      <Grid item xs={11} justifyContent={'center'} md={3} style={{ color: '#75918D', fontSize: '13pt' }}>
        {/* <Grid container>
          <Grid item xs={6} md={6}>
            {' '}
            <Grid container>
              <CheckBoxIcon src={Checked} alt="dskfjh" style={{ marginRight: '10px' }} /> Changed Crops:{' '}
              {selectedCrop.length} crops
            </Grid>
          </Grid>
        </Grid> */}
        {/* {selectedCrop.map((data, index) => (
          <Grid container sx={{ display: 'flex' }} alignItems={'center'}>
            <Grid md={1} item>
              <img alt="" src={Star} style={{ marginBottom: '2px' }} />
            </Grid>
            <Grid md={5} item style={{ lineHeight: '15px', fontSize: '10pt' }}>
              <p
                className="d-flex align-items-center"
                style={{
                  fontSize: '17pt',
                  fontWeight: '600',
                  marginTop: '15px',
                  lineHeight: '25px',
                  marginBottom: '3px',
                  color: 'black',
                  width: '230px',
                  // lineHeight: '18px',
                }}
              >
                {data.label}
              </p>
              {data.Variety}
            </Grid>
            <Grid item md={5}>
              <Grid container >
                <Grid md={10} item>
                  <Grid container>
                    {location.state.farmName}
                    <img alt="" src={Warning} style={{width:20,marginLeft:5}}/> 
                  </Grid>
                
                </Grid>
               
              </Grid>
              <Grid container>
                     
                      <Box
                        sx={{
                          fontStyle: 'italic',
                          display: 'flex',
                          alignItems: 'center',
                      
                          fontSize: '10pt',
                        }}
                      >
                        id: {data.id}
                      </Box>
                    </Grid>
            </Grid>
          
             
          </Grid>
        ))} */}
        {/* <Button
          variant="contained"
          disabled={selectedCrop.length ===0}
          onClick={() => {
   
          }}
          sx={buttonStyle}
        >
          Apply Changes
        </Button> */}
      </Grid>
    </Grid>
  );
}
export default FarmDetailPage;

const ratingFilters = [
  {
    label: '1',
  },
  {
    label: '2',
  },
  {
    label: '3',
  },
  {
    label: '4',
  },
  {
    label: '5',
  },
];
const gridStyles = {
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 1,
  paddingBottom: 1,
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const Contents = styled(Paper)(({ theme }) => ({
  marginBottom: 20,
}));

const typographyStyle = {
  fontWeight: '350',
  marginBottom: 5,
};
const tallGrid = {
  height: '100%',
};
const buttonStyle = {
  color: 'white',
  backgroundColor: '#02A78B',
  height: 40,
  marginTop: 5,
  width: 140,
  '&:hover': {
    color: '#02A78B',
    backgroundColor: '#fff',
    border: '1px solid #02A78B',
  },
};