import { Card } from '@mui/material';

import Blue from '../assets/images/Blue.png';

const Event = (props) => {
  return (
    <Card
      style={{
        backgroundColor:
          props.name === 'Water'
            ? '#E5F5FF'
            : props.name === 'Labor'
            ? 'rgb(251, 249, 235)'
            : props.name === 'Seeds1'
            ? 'rgb(253, 245, 245)'
            : 'rgb(252, 247, 245)',
        width: '170px',
        border:
          props.name === 'Water'
            ? '1px solid #62C7FF'
            : props.name === 'Labor'
            ? '1px solid rgb(251, 210, 200)'
            : props.name === 'Seeds1'
            ? '1px solid rgb(253, 245, 245)'
            : '1px solid rgb(180, 180, 245)',
        height: '25%',
      }}
    >
      <div className="d-flex justify-content-start ">
        <img src={Blue} alt="This is an alt" />
        <span style={{ color: '#62C7FF' }}>{props.name}</span>
      </div>

      <span
        className="d-flex justify-content-end "
        style={{
          color: '#62C7FF',
          fontSize: '14px',
          fontWeight: '600',
          marginBottom: '5px',
          marginRight: '5px',
        }}
      >
        - {props.value} {props.name === 'Water' ? '(m3)' : props.name === 'Labor' ? '(Hours)' : '(Tonnes)'}
      </span>
    </Card>
  );
};

export default Event;
