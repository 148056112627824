import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { injectIntl } from 'react-intl';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import TodayIcon from '@mui/icons-material/Today';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from 'react-select';
import { FormControl, styled } from '@mui/material';
// import Select from "react-select";
import format from 'date-fns/format';
import getWeekDays from '../common/getWeekDays';

import { i18nPreviousLabel, i18nNextLabel } from '../common/i18nLabels';
import getSelectedWeekIndex from '../common/getSelectedWeekIndex';
import { CalendarContext } from '../common/CalendarContext';
import DropDown from '../../../drop-down';

const drawerWidth = 240;
const drawerWidth2 = 300;
const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    root: {

      flexGrow: 1,
      position: 'fixed',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      borderBottom: '1px solid #E0E0E0',
      // marginTop:-24
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      //   paddingLeft: theme.spacing(1),
      fontWeight: 400,
      fontSize: theme.spacing(3),
      textTransform: 'capitalize',
    },
    button: {
      paddingRight: theme.spacing(1),
    },
    appBar: {
      width: `calc(100% - ${drawerWidth2+10}px)`,
      right: drawerWidth2 ,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth+drawerWidth2+10}px)`,
      marginLeft: drawerWidth - 6,
      right: drawerWidth2 ,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    tooltip: {
      marginTop: 2,
    },
    select: {
      width: theme.spacing(15),
    },
  })
);

const languageOptions = [
  {
    value: 'en-US',
    label: 'English',
  },
  {
    value: 'pt-BR',
    label: 'Português',
  },
];

function CalendarToolbar(props) {
  const classes = useStyles();
  const { open, handleDrawerOpen, handleDrawerClose, changeLanguage, goToToday, next, previous, intl, match,pageView ,handleViewChangeSelect} = props;

  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, i18nLocale, layout } = stateCalendar;

  return useMemo(() => {
    const setLayout = (props) => {
      const { option } = props;
      setStateCalendar({ ...stateCalendar, layout: option });
    };

    const weeks = getWeekDays(selectedDate, 7);
    const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0);
    const selectedWeek = weeks[selectedWeekIndex];

    const firstDayOfWeekMonth = format(selectedWeek[0], 'MMM', { locale });
    const lastDayOfWeekMonth = format(selectedWeek[6], 'MMM', { locale });
    const firstDayOfWeekYear = format(selectedWeek[0], 'YYYY', { locale });
    const lastDayOfWeekYear = format(selectedWeek[6], 'YYYY', { locale });

    const showMonthsAndYears =
      layout === 'week' && firstDayOfWeekMonth !== lastDayOfWeekMonth && firstDayOfWeekYear !== lastDayOfWeekYear
        ? `${firstDayOfWeekMonth} ${firstDayOfWeekYear} - ${lastDayOfWeekMonth} ${lastDayOfWeekYear}`
        : false;
    const showMonthsAndYear =
      !showMonthsAndYears && layout === 'week' && firstDayOfWeekMonth !== lastDayOfWeekMonth
        ? `${firstDayOfWeekMonth} - ${lastDayOfWeekMonth} ${firstDayOfWeekYear}`
        : false;
    const showMonthAndYear = !showMonthsAndYear ? format(selectedDate, 'MMMM YYYY', { locale }) : false;

    return (
      <div
        className={clsx(classes.root, classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="default"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Tooltip title={`${format(new Date(), 'DDDD, D MMMM', { locale })}`} classes={{ tooltip: classes.tooltip }}>
            <IconButton
              color="inherit"
              aria-label="Today"
              onClick={goToToday}
              edge="start"
              className={classes.menuButton}
            >
              <TodayIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: i18nPreviousLabel(layout) })} classes={{ tooltip: classes.tooltip }}>
            <IconButton onClick={previous}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: i18nNextLabel(layout) })} classes={{ tooltip: classes.tooltip }}>
            <IconButton onClick={next}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>

          <Typography className={classes.title}>
            {showMonthsAndYears || showMonthsAndYear || showMonthAndYear}
          </Typography>

          <Tooltip title={intl.formatMessage({ id: 'navigation.day' })} classes={{ tooltip: classes.tooltip }}>
            <IconButton
              color="inherit"
              aria-label="Day View"
              onClick={(e) => setLayout({ e, option: 'day' })}
              edge="start"
              className={classes.menuButton}
            >
              <CalendarViewDayIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: 'navigation.week' })} classes={{ tooltip: classes.tooltip }}>
            <IconButton
              color="inherit"
              aria-label="Week View"
              onClick={(e) => setLayout({ e, option: 'week' })}
              edge="start"
              className={classes.menuButton}
            >
              <ViewWeekIcon />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title={intl.formatMessage({ id: 'navigation.month' })} classes={{ tooltip: classes.tooltip }}>
            <IconButton
              color="inherit"
              aria-label="Month View"
              onClick={(e) => setLayout({ e, option: 'month' })}
              edge="start"
              className={classes.menuButton}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip> */}

       

        {/* <FormControl >
          <DropDown    className={classes.select} values={['Table', 'Timeline', 'Sales']} onChange={handleViewChangeSelect} value={pageView} />
        </FormControl> */}

        </Toolbar>
      </div>
    );
    // ....
    // for stateCalendar and setStateCalendar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, selectedDate, layout, intl, locale, i18nLocale, goToToday, next, previous, changeLanguage]);
}

export default injectIntl(CalendarToolbar);
