import React from 'react';
import { InputAdornment, Input, InputLabel, Container } from '@mui/material';
import './style.css';

const NumberInput = ({ value, unit, onChange }) => {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    onChange(inputValue);
  };
  const containerStyle = {
    border: 2,
    borderRadius: 1,
    borderColor: '#D0D0D0',
    maxWidth: 80,
    marginLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth:0
  };
  const inputStyle = {
    color: '#D0D0D0',
    fontWeight: 'bold',
    fontSize: '25pt',
    padding: 0,
    px: 0,

    minWidth:0
  };

  return (
    <Container style={{ padding: 0 }} sx={containerStyle}>
      <Input sx={inputStyle} disableUnderline type="number" value={value} onChange={handleInputChange} />
    </Container>
  );
};

export default NumberInput;
