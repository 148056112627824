// component
import SvgColor from '../../../components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Crops',
  //   path: '/dashboard/app',
  //   // icon: icon('farm'),
  // },
  // {
  //   title: 'Client',
  //   path: '/dashboard/clients',
  //   // icon: icon('farm'),
  // },
  // {
  //   title: 'Orderss',
  //   path: '/dashboard/orders',
  //   // icon: icon('farm'),
  // },

  // {
  //   title: 'farm',
  //   path: '/dashboard/farm',
  //   icon: icon('dashboard'),
  // },
  // {
  //   title: 'marketplace',
  //   path: '/dashboard/products',
  //   icon: icon('market'),
  // },
];

export default navConfig;
