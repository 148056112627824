import  {useState,useContext} from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { createStyles, createTheme, styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { makeStyles } from '@mui/styles';

import { CalendarContext } from '../common/CalendarContext';

dayjs.extend(isBetweenPlugin);

const theme = createTheme({
  palette: {
    primary: {
      main: '#02A78B', // Replace with your desired primary color
    },
  },
})
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ dayIsBetween, isFirstDay, isLastDay }) => ({
  position: 'relative',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  ...(dayIsBetween && {
    backgroundColor: '#02A78B',
       zIndex:1000,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      zIndex:1000,
      backgroundColor: '#02A78B',
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    zIndex:1000,
    backgroundColor: '#02A78B',
  }),
}));


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height:'200px',
      background: theme.palette.background.paper,
    },
    yearButton: {
      display: 'none', // Hide the year button
    },
  })
);

function Day(props) {
  const { day, selectedDay, ...other } = props;

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }
  const start = selectedDay.clone().subtract(3, 'day');
  const end = selectedDay.clone().add(0, 'day');

  const isCurrentDay = day.isSame(selectedDay, 'day');
  const isCurrentDay2 = dayjs().isSame(day, 'day');
  const dayIsBetween = day.isBetween(start, end, null, '[]');
  const isFirstDay = day.isSame(start, 'day');
  const isLastDay = day.isSame(end, 'day');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      theme={theme} // Add this line to pass the theme to the component
      sx={
        
        dayIsBetween || isCurrentDay 
          ? {
              backgroundColor: '#02A78B',
              zIndex:1000,
              color: theme.palette.common.white,
              '&:hover, &:focus': {
                backgroundColor: '#02A78B',
                zIndex:1000,
              },
              borderRadius: '40px',
              width: '40px',
              height: '30px',
            }
          : isCurrentDay2
          ? {
              backgroundColor: theme.palette.grey[300],
              borderRadius: '40px',
              width: '40px',
              height: '30px',
            }
          : {
              borderRadius: '40px',
              width: '40px',
              height: '30px',
            }
      }
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}

function CalendarSmall(props) {
  const classes = useStyles()
  const { intl, isDatepicker = false, datepickerOnChange = () => {}, datepickerValue } = props
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext)
  const { selectedDate, locale } = stateCalendar

  const [internalDate, setInternalDate] = useState(isDatepicker ? datepickerValue : selectedDate)
  const [selectedInternalDate, setSelectedInternalDate] = useState(isDatepicker )

  const [value, setValue] = useState(dayjs());
  const selectDate = (newDate) => {
    const previousDate = dayjs(new Date(newDate)).add(1, 'day');
    setValue(previousDate)
    if (!isDatepicker) {
      setStateCalendar({ ...stateCalendar, selectedDate: newDate });
      setSelectedInternalDate(previousDate);
    } else {
      datepickerOnChange(previousDate);
    }
  };
  return (
    <section className={classes.root}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          sx={{ width: '240px',
          '& .Mui-selected': {
            backgroundColor: '#02A78B', // Custom background color for selected dates
            color: '#fff', // Custom text color for selected dates
            zIndex:1000
          }, }}
          value={value}
          onChange={(newValue) => selectDate(newValue)}
          views={['day']} // Only show the day view
          slots={{ day: Day }}
          slotProps={{
            day: {
              selectedDay: value,
            //  onClick: () => selectDate(value), // Call selectDate with the selected date value
            },
          }}
          components={{
            YearSelectionButton: () => null, // Render an empty component for the year selection button
          }}
        />
      </LocalizationProvider>
    </section>
  );
}

export default CalendarSmall;
