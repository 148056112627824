import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import React, { useEffect } from 'react';
import FilterContainer from '../components/FilterContainer';
import OrderCard from '../components/OrderCard';


import data from '../utils/mockData';

function ProductsPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [listOfFilter, setListOfFilter] = React.useState([]);
  const [listOfCropFilter, setListOfCropFilter] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const farmData = useSelector((state) => state.farms);
  const [plotSelectedFilters, setPlotSelectedFilters] = React.useState([]);
  const [cropSelectedFilters, setCropSelectedFilters] = React.useState([]);
  const [ratingSelectedFilters, setRatingSelectedFilters] = React.useState([]);
  const [minWeek, setMinWeek] = React.useState(0);
  const [maxWeek, setMaxWeek] = React.useState(52);
  const [minDate, setMinDate] = React.useState(0);
  const [maxDate, setMaxDate] = React.useState(52);
  const [ordersList, setOrdersList] = React.useState([]);
  const [dates,setDates]=React.useState([]);
  useEffect(() => {
    setOrdersList(orders);
    getMinAndMaxDates(orders)
  }, [orders]);


  useEffect(() => {
    const uniqueFilterValues = new Set();
    ordersList.forEach((data) => {
      uniqueFilterValues.add(data.status);
    });
    const filters = Array.from(uniqueFilterValues).map((value) => ({
      label: value,
      value,
    }));
    setListOfFilter(filters);
  }, [ordersList]);

  useEffect(() => {
    setFilteredData(filterFarmsByWeek(filterCrops(onFilterPlotChange())));
    const allCrops = onFilterPlotChange().filter((data) => data.label);

    setListOfCropFilter(allCrops);
  }, [ratingSelectedFilters, plotSelectedFilters, cropSelectedFilters, minWeek, maxWeek,minDate,maxDate, ordersList]);

  
  function getMinAndMaxDates(orders) {
    let minDate = orders[0].deliveryDate;
    let maxDate = orders[0].deliveryDate;
  
    for (let i = 1; i < orders.length;) {
      const currentDate = orders[i].deliveryDate;
  
      if (currentDate < minDate) {
        minDate = currentDate;
      }
  
      if (currentDate > maxDate) {
        maxDate = currentDate;
      }
      i+=1
    }
    setDates([new Date(minDate).getTime(), new Date(maxDate).getTime()])
    setMinWeek(minDate)
    setMaxWeek(maxDate)
   setMinDate(minDate)
   setMaxDate(maxDate)
  }
  
  
  const onFilterPlotChange = () => {
    if (plotSelectedFilters.length > 0) {
      const filteredFarms = ordersList.filter((farm) => {
        return plotSelectedFilters.some((filter) => filter.label === farm.status);
      });
      return filteredFarms;
    }
    return ordersList;
  };
  function filterFarmsByWeek(farms) {
    return farms.filter((farm) => farm.deliveryDate >= minWeek && farm.deliveryDate <= maxWeek);
  }

  const filterCrops = (filteredData) => {
    if (cropSelectedFilters.length > 0) {
      const filteredFarms = filteredData.filter((farm) => {
        return cropSelectedFilters.some((filter) => filter.label === farm.value);
      });
      return filteredFarms;
    }
    return filteredData;
  };
  function getWeek(date) {
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const diff = date - yearStart + (yearStart.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    const week = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return week;
  }
  const onDateChange = (date) => {
    const minWeek = (new Date(date[0]));
    const maxWeek = (new Date(date[1]));
    setMinWeek(minWeek)
    setMaxWeek(maxWeek)
  };

  return (
    <Grid container spacing={7}  sx={{ ...gridStyles, ...tallGrid }}>
            <Grid item xs={11} md={2}>
      <FilterContainer
        market
        setPlotSelectedFilters={setPlotSelectedFilters}
        listOfFilter={listOfFilter}
        setCropSelectedFilters={setCropSelectedFilters}
        listOfCropFilter={listOfCropFilter}
        setRatingSelectedFilters={setRatingSelectedFilters}
        onDateChange={onDateChange}
        minDate={minDate}
        maxDate={maxDate}
        dates={dates}
        pageView="Farm"
      />
      </Grid>
      <Grid item xs={11} md={10}>
   
          {filteredData.map((data) => (
            <>
              {/* TODO switch OrderCard to FlexItem */}
              <OrderCard setOrdersList={setOrdersList} ordersList={ordersList} data={data} />
            </>
          ))}
      
      </Grid>
    </Grid>
  );
}
export default ProductsPage;

const gridStyles = {
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 1,
  paddingBottom: 1,
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const tallGrid = {
  height: '100%',
};

const randomValueFunc = (randomIndex) => {
  const randomValue = data.Varieties[randomIndex];
  return randomValue;
};

const orders = [
  {
    ...randomValueFunc(7),
    price: 3.99,
    clientName: 'John Doe',
    deliveryDate: new Date('Sat Nov 26 2022'),
    deliveryLocation: 'No delivery location',
    productName: 'Pepper Meyer',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Accepted',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    ...randomValueFunc(2),
    price: 9.99,
    clientName: 'Jane Smith',
    deliveryDate: new Date('Mon Dec 05 2022'),
    deliveryLocation: '123 Main St.',
    productName: 'Tomato Cherry',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Delivered',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    ...randomValueFunc(8),
    price: 12.99,
    clientName: 'Bob Johnson',
    deliveryDate: new Date('Tue Dec 13 2022'),
    deliveryLocation: '456 Elm St.',
    productName: 'Cucumber English',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Cancelled',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    ...randomValueFunc(4),
    price: 5.99,
    clientName: 'Mary Adams',
    deliveryDate: new Date('Wed Dec 21 2022'),
    deliveryLocation: '789 Oak St.',
    productName: 'Zucchini Yellow',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Rejected',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    ...randomValueFunc(5),
    price: 17.99,
    clientName: 'David Lee',
    deliveryDate: new Date('Thu Dec 29 2022'),
    deliveryLocation: '1011 Maple St.',
    productName: 'Squash Butternut',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Delivered',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    ...randomValueFunc(6),
    price: 33.99,
    clientName: 'Sarah Kim',
    deliveryDate: new Date('Fri Jan 06 2023'),
    deliveryLocation: '1213 Pine St.',
    productName: 'Broccoli',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Accepted',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
];

