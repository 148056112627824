import React, { useContext } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  useMediaQuery,
  styled,
  createTheme,
  Container,
  Grid,
  FormControl,
  Select,
  Box,
  MenuItem,
  Chip,
  InputLabel,
  OutlinedInput,
  LinearProgress,
} from '@mui/material';
import Chart from '../../../Chart';
import getWeekDays from '../common/getWeekDays';
import getSelectedWeekIndex from '../common/getSelectedWeekIndex';
import CalendarLayoutMonth from './CalendarLayoutMonth';
import CalendarLayoutDayWeek from './CalendarLayoutDayWeek';
import { CalendarContext } from '../common/CalendarContext';
import FlexBox from '../../../FlexBox';
import MyTabs from './RightSideCalendar';
import CropsViewDashboard from './CropsViewDashboard';
// import Chart from '../components/Chart';

const drawerWidth = 234;
const drawerWidth2 = 285;
const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-center',
      marginBottom:20
    },
    content: {
      flexGrow: 1,
      marginLeft: `-${drawerWidth}px)`,
      marginRight: drawerWidth2,
      // padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#fff',
      height: '100%',
    },
    contentShift: {
      marginLeft: drawerWidth,
      marginRight: drawerWidth2,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

const CalendarMain = (props) => {
  const {
    pageView,
    handleViewChange,
    selectedFarm,
    handleFarmArrayChange,
    farmData,
    selectedCrop,
    handleCropArrayChange,
    listOfCropFilter,
    filteredArray,
    value,
  } = props;
  const classes = useStyles();
  const { stateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, layout } = stateCalendar;

  const { open, runAnimation } = props;

  const weeks = getWeekDays(selectedDate, 4);
  const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0);
  const selectedWeek = weeks[selectedWeekIndex];

  const Calendar = () => {
    return (
      <>
        {layout === 'month' && <CalendarLayoutMonth weeks={weeks} runAnimation={runAnimation} locale={locale} />}

        {(layout === 'week' || layout === 'day') && (
          // <CropsViewDashboard/>
          <CropsViewDashboard {...props} selectedWeekIndex={selectedWeekIndex} selectedWeek={selectedWeek} />
          // <CalendarLayoutDayWeek {...props} selectedWeekIndex={selectedWeekIndex} selectedWeek={selectedWeek} />
        )}
      </>
    );
  };
  return (
    <div
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.drawerHeader} />

      {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography> */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column' ,padding:2}}>
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <FlexBox
                pageView={pageView}
                filteredArray={filteredArray}
                value={value}
                style={{ height: '900px' }}
              >
                   <Calendar />
             
              </FlexBox>
              <MyTabs    layout={"month"}/>
            </Grid>
        
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CalendarMain;
