// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderCards::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}

.orderCards::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust the background color as needed */
}

.orderCards::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust the thumb color as needed */
  border-radius: 5px;
}

.orderCards::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Adjust the thumb color on hover as needed */
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/style.scss"],"names":[],"mappings":"AAAA;EACE,UAAA,EAAA,+BAAA;AACF;;AAEA;EACE,yBAAA,EAAA,0CAAA;AACF;;AAGA;EACE,sBAAA,EAAA,qCAAA;EACA,kBAAA;AAAF;;AAGA;EACE,sBAAA,EAAA,8CAAA;AAAF","sourcesContent":[".orderCards::-webkit-scrollbar {\n  width: 8px; /* Adjust the width as needed */\n}\n\n.orderCards::-webkit-scrollbar-track {\n  background-color: #f1f1f1; /* Adjust the background color as needed */\n  //   border-radius: 5px;\n}\n\n.orderCards::-webkit-scrollbar-thumb {\n  background-color: #888; /* Adjust the thumb color as needed */\n  border-radius: 5px;\n}\n\n.orderCards::-webkit-scrollbar-thumb:hover {\n  background-color: #555; /* Adjust the thumb color on hover as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
