import React from 'react';
import { createPortal } from 'react-dom';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Collapse from '@mui/material/Collapse';
import CalendarSmall from './CalendarSmall';



const theme = createTheme();
const useStyles = makeStyles(() =>  createStyles({
    collapseCalendar: {
        position: "absolute",
        zIndex: 1600,
    },
    paper: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        // marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        maxWidth: 272,
    },
}))

function DatepickerCalendar(props) {
    const classes = useStyles()

    const {
        datepickerValue = new Date(),
        calendarPosition = { top: 0, left: 0 },
        openCalendar,
        handleClickAway,
        handleChangeDateCalendar,
    } = props

    const popupCalendar = (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Collapse
                in={openCalendar}
                className={classes.collapseCalendar}
                style={{ top: calendarPosition.top, left: calendarPosition.left }}
                // style={{ top: 200, left: 400 }}
            >
                <Paper className={classes.paper}>
                    <CalendarSmall
                        isDatepicker
                        datepickerOnChange={handleChangeDateCalendar}
                        datepickerValue={datepickerValue}
                    />
                </Paper>
            </Collapse>
        </ClickAwayListener>
    )

    const appRoot = document.body
    return createPortal(popupCalendar, appRoot)
}

export default DatepickerCalendar
