import { Grid } from '@mui/material';

import Week from './week';
import Chart from './Chart';
import InventoryProduct from './InventoryProduct';
import TableView from './TableView';
import PlotsCrops from './plots-crops';

const FlexItem = (props) => {
  return (
    <Grid>
      {props.pageView === 'Crops' ? (
        <Week myKey={props.value} weekEvent={props.weekEvent} />
      ) : props.pageView === 'Chart' ? (
        <Chart />
      ) : props.pageView === 'Inventory' ? (
        <InventoryProduct crop={props.crop} />
      ) : props.pageView === 'Table' ? (
        <TableView allWeeksEvents={props.allWeeksEvents} />
      ) : props.pageView === 'Crop' ? (
        <PlotsCrops crop={props.crop} farm={props.farm} />
      ) : null}
    </Grid>
  );
};

export default FlexItem;
