import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from 'react-select';
import { Chip, FormControl, Grid, styled } from '@mui/material';
import format from 'date-fns/format';
import getWeekDays from '../common/getWeekDays';

import { i18nPreviousLabel, i18nNextLabel } from '../common/i18nLabels';
import getSelectedWeekIndex from '../common/getSelectedWeekIndex';
import { CalendarContext } from '../common/CalendarContext';
import DropDown from '../../../drop-down';

const drawerWidth = 240;
const drawerWidth2 = 300;
const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 150,
    },
    root: {
      flexGrow: 1,
      position: 'fixed',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      borderBottom: '1px solid #E0E0E0',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontWeight: 400,
      fontSize: theme.spacing(3),
      textTransform: 'capitalize',
    },
    button: {
      paddingRight: theme.spacing(1),
    },
    appBar: {
      width: `calc(100% - ${drawerWidth2 + 10}px)`,
      right: drawerWidth2,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth + drawerWidth2 + 10}px)`,
      marginLeft: drawerWidth - 6,
      right: drawerWidth2,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    tooltip: {
      marginTop: 2,
    },
  })
);

function ToolbarItems(props) {
  const classes = useStyles();
  const {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    changeLanguage,
    goToToday,
    next,
    previous,
    intl,
    match,
    pageView,
    handleViewChangeSelect,
  } = props;
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, i18nLocale, layout } = stateCalendar;
  const [dateType, setDateType] =useState('Weekly')
  const [selectDateIndex,setSelectDateIndex] = useState(0)
  return useMemo(() => {
    const setLayout = (props) => {
      const { option } = props;
      setStateCalendar({ ...stateCalendar, layout: option });
    };

    const weeks = getWeekDays(selectedDate, 7);
    const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0);
    const selectedWeek = weeks[selectedWeekIndex];
   
    const firstDayOfWeekMonth = format(selectedWeek[0], 'MMM', { locale });
    const lastDayOfWeekMonth = format(selectedWeek[6], 'MMM', { locale });
    const firstDayOfWeekYear = format(selectedWeek[0], 'YYYY', { locale });
    const lastDayOfWeekYear = format(selectedWeek[6], 'YYYY', { locale });

    const showMonthsAndYears =
      layout === 'week' && firstDayOfWeekMonth !== lastDayOfWeekMonth && firstDayOfWeekYear !== lastDayOfWeekYear
        ? `${firstDayOfWeekMonth} ${firstDayOfWeekYear} - ${lastDayOfWeekMonth} ${lastDayOfWeekYear}`
        : false;
    const showMonthsAndYear =
      !showMonthsAndYears && layout === 'week' && firstDayOfWeekMonth !== lastDayOfWeekMonth
        ? `${firstDayOfWeekMonth} - ${lastDayOfWeekMonth} ${firstDayOfWeekYear}`
        : false;
    const showMonthAndYear = !showMonthsAndYear ? format(selectedDate, 'MMMM YYYY', { locale }) : false;

    function getNext7Days() {
      const today = new Date();
      const next50Days = [];

      for (let i = 0; i < 7; ) {
        const date = new Date();
        date.setDate(today.getDate() +selectDateIndex+ i*7);
        next50Days.push(date);
        i+=1
      }

      return next50Days;
    }

    const chipStyle = {
      borderColor: '#02A78B',
      borderWidth: 1,
      borderRadius: '33px',
      backgroundColor: '#f1f2f3',
      cursor: 'pointer',
      overflowX: 'hidden', // Hide the horizontal scrollbar
      marginLeft: 1,
      fontSize: '11px',
      color: '#00000078',
      paddingRight: '20px',
      paddingLeft: '20px',
      maxWidth: '125px', // Set a minWidth instead of width
      height: 30,
      flex: '1', // Allow the chips to take up available space
    };


    const setPrevDate = () =>{
      setSelectDateIndex((prev)=>prev-1)
    }
    const setNextDate = () =>{
      setSelectDateIndex((prev)=>prev+1)
    }
    const DateCard = () => {
      const dates = getNext7Days();
      let prevMonth = null;
    
      return (
        <Grid style={{ ...itemStyle, paddingRight: 15 }} item xs={12} sm={10} md={10} lg={10}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          
            {dates.map((date, index) => {
              const currentMonth = date.getMonth();
              const displayMonth =
                currentMonth !== prevMonth
                  ? date.toLocaleDateString('en-US', { month: 'long' })
                  : index === 0 // Display the month name only for the first date
                  ? date.toLocaleDateString('en-US', { month: 'long' })
                  : '';
              prevMonth = currentMonth; // Update prevMonth for the next iteration
    
              return (
                <Grid
                  item
                  sm={3}
                  md={3}
                  lg={3}
                  key={index}
                  sx={{
                    ...chipStyle,
                    marginRight: index === dates.length - 1 ? 1 : 0,
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  marginTop={'20px'}
                >
                  <div style={{ position: 'absolute', top: 10, marginLeft: index-15,fontSize:'15px'  }}>
                    {displayMonth}
                  </div>
                  <Grid container>
                    <Grid sm={6} md={6} lg={6}>
                      <Typography textAlign={'start'}>
                        {date.toLocaleDateString('en-US', { weekday: 'short' })}
                      </Typography>
                    </Grid>
                    <Grid sm={6} md={6} lg={6}>
                      <Typography textAlign={'end'} fontWeight={'bold'}>
                        {date.getDate()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <ArrowForwardIosIcon style={{marginTop:'20px',marginRight:'-30px',color: '#00000078',cursor:'pointer'}} onClick={setNextDate}/>
          </div>
        </Grid>
      );
    };
    

   

    return (
      <div
        className={clsx(classes.root, classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="default"
      >
      <Grid container display={'flex'} alignItems={'center'} minHeight={'70px'} padding={2}>
      {pageView!=='Orders' && <>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <div style={{lineHeight:'25px',marginTop:'-7px',fontSize:'15px'  ,color: '#00000078',}}>
        Group by
        </div>
        <div style={{lineHeight:'25px',marginTop:'5px',display:'flex'}}>
            <DropDown
              className={classes.select}
              values={['Daily','Weekly', 'Monthly']}
              onChange={(e)=>{setDateType(e)}} // Add your onChange handler here
              value={dateType} // Replace 'Cards' with the appropriate state variable
            />
           <div style={{display:'flex',justifyContent:'end',width:'100%',alignItems:'center'}}>
           <ArrowBackIosIcon style={{color: '#00000078',cursor:'pointer',marginRight:'5px',marginLeft:'5px'}}  onClick={setPrevDate}/>
           </div>
            </div>
          </Grid>
          <DateCard />
          </>
        }
        </Grid>
      </div>
    );
  }, [classes, selectedDate, layout, intl, locale, i18nLocale, goToToday, next, previous, changeLanguage,dateType,selectDateIndex]);
}

export default injectIntl(ToolbarItems);
const itemStyle = {
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
};
