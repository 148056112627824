import React from 'react';
import { DndProvider } from 'react-dnd';
import { IntlProvider } from 'react-intl';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';
import Calendar from './components/Calendar';
import ThemeProvider from '../../../theme';
import AppContext from './Context';

function GoogleCalendar(props) {

  const [listOfFilter, setListOfFilter] = React.useState([]);
  const [listOfCropFilter, setListOfCropFilter] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [plotSelectedFilters, setPlotSelectedFilters] = React.useState([]);
  const [cropSelectedFilters, setCropSelectedFilters] = React.useState([]);
  const [ratingSelectedFilters, setRatingSelectedFilters] = React.useState([]);
  const [minWeek, setMinWeek] = React.useState(0);
  const [maxWeek, setMaxWeek] = React.useState(52);
  const [selectedCrop, setSelectedCrop] = React.useState(null);
  const [statusFilter, setStatusFilters] = React.useState(null);
  return (
    <AppContext.Provider
    value={{
      statusFilter, setStatusFilters,
      listOfFilter, setListOfFilter,
      listOfCropFilter, setListOfCropFilter,
      filteredData, setFilteredData,
      plotSelectedFilters, setPlotSelectedFilters,
      cropSelectedFilters, setCropSelectedFilters,
      ratingSelectedFilters, setRatingSelectedFilters,
      minWeek, setMinWeek,
      maxWeek, setMaxWeek,
      selectedCrop, setSelectedCrop
    }}>
<IntlProvider  locale='en'>
    <DndProvider backend={HTML5Backend}>
      <div className="App">
  <ThemeProvider>
  <Calendar {...props} />
  </ThemeProvider>

      </div>
    </DndProvider>
        </IntlProvider>
        </AppContext.Provider>
  );
}

export default GoogleCalendar;
