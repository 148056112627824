import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import format from 'date-fns/format';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { red } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material';
import { createStyles, createTheme } from '@mui/material/styles';
import { CalendarContext } from '../common/CalendarContext';
import createEditEvent from './createEditEvent';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    divTitleButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    closeButton: {
      // color: theme.palette.grey[900],
      // color: theme.palette.secondary.light,
      // color: red[500],
      // "&:hover": {
      // backgroundColor: red[100],
      // },
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      // margin: "auto",
      // marginTop: -30,
      // width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      // minWidth: 120,
    },
    formControlFlex: {
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    optionsBar: {
      marginTop: theme.spacing(-1),
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  })
);

function CalendarEventViewDialog(props) {
  const classes = useStyles();

  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { openViewDialog, locale, calendarEvent } = stateCalendar;

  return useMemo(() => {
    // console.group('CalendarEventViewDialog')
    // console.log(calendarEvent)
    // console.groupEnd()

    const handleCloseViewDialog = () => {
      setStateCalendar({ ...stateCalendar, openViewDialog: false });
    };

    return (
      <Dialog
        // onEntered={() => {
        //     console.log(textFieldTitle)
        //     textFieldTitle.current !== null && textFieldTitle.current!.focus()
        // }}
        // onExited={onExited}
        fullScreen={false}
        fullWidth
        maxWidth="sm"
        open={openViewDialog}
        onClose={handleCloseViewDialog}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Transition}
        keepMounted={false}
      >
        <DialogTitle>
          {calendarEvent.title || ''}
          <div className={classes.divTitleButton}>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseViewDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl className={classes.optionsBar}>
            <Tooltip title="Edit">
              <IconButton
                size="medium"
                aria-label="Edit event"
                // onClick={() => }
                onClick={(eventEl) => {
                  createEditEvent({
                    eventEl,
                    // defaultEventDuration,
                    stateCalendar,
                    setStateCalendar,
                    calendarEvent,
                  });
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="medium"
                edge="end"
                aria-label="Delete event"
                onClick={() => {
                  const markers = JSON.parse(window.localStorage.getItem('markers'));
                  const updatedMarkers = [...markers]; // Create a copy of the markers array
                  const index = updatedMarkers.findIndex((marker) => marker.id === calendarEvent.id); // Find the index of the calendarEvent by its ID
                  if (index !== -1) {
                    updatedMarkers.splice(index, 1); // Remove the calendarEvent from the updatedMarkers array
                    window.localStorage.setItem('markers', JSON.stringify(updatedMarkers)); // Update the markers in localStorage
                  }
                  handleCloseViewDialog();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </FormControl>
          <FormControl className={clsx(classes.formControl, classes.formControlFlex)}>
            <AccessTimeIcon className={classes.icon} />
            <Typography>
              {format(new Date(calendarEvent.begin), 'dddd, D MMMM YYYY ⋅ HH:mm -', {
                locale,
              })}{' '}
              {format(new Date(calendarEvent.end), 'HH:mm', { locale })}
            </Typography>
          </FormControl>
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
    // ....
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEvent, classes, locale, openViewDialog]);
}

export default CalendarEventViewDialog;
