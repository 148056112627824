const weekData = {
  Operino: [
    {
      week: '1',
      Seeds: 11111,
      Seeds1: 210,
      Seed: 2333.31,
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 150,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '2',
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '3',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '4',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '5',
      IrrigationRequirement: 22,
      Water: 220,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '6',
      IrrigationRequirement: 26,
      Water: 260,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '7',
      IrrigationRequirement: 30.7,
      Water: 307,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '8',
      IrrigationRequirement: 34,
      Water: 340,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '9',
      IrrigationRequirement: 36.5,
      Water: 365,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '10',
      IrrigationRequirement: 37.8,
      Water: 378,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '11',
      IrrigationRequirement: 38.6,
      Water: 386,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '12',
      IrrigationRequirement: 39.6,
      Water: 396,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '13',
      IrrigationRequirement: 40,
      Water: 400,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '14',
      IrrigationRequirement: 39.7,
      Water: 397,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '15',
      IrrigationRequirement: 29,
      Water: 290,
      Labor: 120,
      N: 0,
      PO3: 0,
      K2O5: 0,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '16',
    },
    {
      week: '17',
    },
    {
      week: '18',
    },
    {
      week: '19',
    },
    {
      week: '20',
    },
    {
      week: '21',
    },
    {
      week: '22',
    },
    {
      week: '23',
    },
    {
      week: '24',
    },
    {
      week: '25',
    },
    {
      week: '26',
    },
    {
      week: '27',
    },
    {
      week: '28',
    },
    {
      week: '29',
    },
    {
      week: '30',
    },
    {
      week: '31',
    },
    {
      week: '32',
    },
    {
      week: '33',
    },
    {
      week: '34',
    },
    {
      week: '35',
    },
    {
      week: '36',
    },
    {
      week: '37',
    },
    {
      week: '38',
    },
    {
      week: '39',
    },
    {
      week: '40',
    },
    {
      week: '41',
    },
    {
      week: '42',
    },
  ],
  Roterno: [
    {
      week: '1',
      Seeds: 11111,
      Seeds1: 210,
      Seed: 2333.31,
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 150,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '2',
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '3',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '4',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '5',
      IrrigationRequirement: 22,
      Water: 220,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '6',
      IrrigationRequirement: 26,
      Water: 260,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '7',
      IrrigationRequirement: 30.7,
      Water: 307,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '8',
      IrrigationRequirement: 34,
      Water: 340,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '9',
      IrrigationRequirement: 36.5,
      Water: 365,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '10',
      IrrigationRequirement: 37.8,
      Water: 378,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '11',
      IrrigationRequirement: 38.6,
      Water: 386,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '12',
      IrrigationRequirement: 39.6,
      Water: 396,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '13',
      IrrigationRequirement: 40,
      Water: 400,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '14',
      IrrigationRequirement: 39.7,
      Water: 397,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '15',
      IrrigationRequirement: 29,
      Water: 290,
      Labor: 120,
      N: 0,
      PO3: 0,
      K2O5: 0,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '16',
    },
    {
      week: '17',
    },
    {
      week: '18',
    },
    {
      week: '19',
    },
    {
      week: '20',
    },
    {
      week: '21',
    },
    {
      week: '22',
    },
    {
      week: '23',
    },
    {
      week: '24',
    },
    {
      week: '25',
    },
    {
      week: '26',
    },
    {
      week: '27',
    },
    {
      week: '28',
    },
    {
      week: '29',
    },
    {
      week: '30',
    },
    {
      week: '31',
    },
    {
      week: '32',
    },
    {
      week: '33',
    },
    {
      week: '34',
    },
    {
      week: '35',
    },
    {
      week: '36',
    },
    {
      week: '37',
    },
    {
      week: '38',
    },
    {
      week: '39',
    },
    {
      week: '40',
    },
    {
      week: '41',
    },
    {
      week: '42',
    },
  ],
  Sweeterno: [
    {
      week: '1',
      Seeds: 11111,
      Seeds1: 210,
      Seed: 2333.31,
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 150,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '2',
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '3',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '4',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '5',
      IrrigationRequirement: 22,
      Water: 220,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '6',
      IrrigationRequirement: 26,
      Water: 260,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '7',
      IrrigationRequirement: 30.7,
      Water: 307,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '8',
      IrrigationRequirement: 34,
      Water: 340,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '9',
      IrrigationRequirement: 36.5,
      Water: 365,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '10',
      IrrigationRequirement: 37.8,
      Water: 378,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '11',
      IrrigationRequirement: 38.6,
      Water: 386,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '12',
      IrrigationRequirement: 39.6,
      Water: 396,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '13',
      IrrigationRequirement: 40,
      Water: 400,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '14',
      IrrigationRequirement: 39.7,
      Water: 397,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '15',
      IrrigationRequirement: 29,
      Water: 290,
      Labor: 120,
      N: 0,
      PO3: 0,
      K2O5: 0,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '16',
    },
    {
      week: '17',
    },
    {
      week: '18',
    },
    {
      week: '19',
    },
    {
      week: '20',
    },
    {
      week: '21',
    },
    {
      week: '22',
    },
    {
      week: '23',
    },
    {
      week: '24',
    },
    {
      week: '25',
    },
    {
      week: '26',
    },
    {
      week: '27',
    },
    {
      week: '28',
    },
    {
      week: '29',
    },
    {
      week: '30',
    },
    {
      week: '31',
    },
    {
      week: '32',
    },
    {
      week: '33',
    },
    {
      week: '34',
    },
    {
      week: '35',
    },
    {
      week: '36',
    },
    {
      week: '37',
    },
    {
      week: '38',
    },
    {
      week: '39',
    },
    {
      week: '40',
    },
    {
      week: '41',
    },
    {
      week: '42',
    },
  ],
};

export default weekData;
