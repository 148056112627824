import { Grid, Typography, Paper, styled } from '@mui/material';
import React from 'react';
import SliderDate from './slider-date-filter';

import MultiSelect from './chip-multi-select';

// TODO: Switch this page to be sent through a FlexBox

const Contents = styled(Paper)(({ theme }) => ({
  marginBottom: 20,
}));
const typographyStyle = {
  fontWeight: '350',
  marginBottom: 5,
};
const ratingFilters = [
  {
    label: '1',
  },
  {
    label: '2',
  },
  {
    label: '3',
  },
  {
    label: '4',
  },
  {
    label: '5',
  },
];
const status = [
  {
    label: 'Sold',
  },
  {
    label: 'Available',
  },
  {
    label: 'Expected',
  },
  {
    label: 'Loss',
  }
];

const FilterContainer = (props) => {
  return !props.market  ? (
    <>
      <Contents>
        <Typography sx={typographyStyle} variant="span">
          Plots
        </Typography>
        <MultiSelect onFilterChange={props.setPlotSelectedFilters} listOfFilterProp={props.listOfFilter} />
      </Contents>
      <Contents>
        <Typography sx={typographyStyle} variant="span">
          Crops
        </Typography>
        <MultiSelect onFilterChange={props.setCropSelectedFilters} listOfFilterProp={props.listOfCropFilter} />
      </Contents>


      {!props.removeRatings && <Contents>
        <Typography variant="span">Ratings</Typography>
        <MultiSelect onFilterChange={props.setRatingSelectedFilters} listOfFilterProp={ratingFilters} rate />
      </Contents>}
      {props.status && <Contents>
        <Typography variant="span">Status</Typography>
        <MultiSelect onFilterChange={props.setStatusFilters} listOfFilterProp={status}  />
      </Contents>}
      {!props.removeDate && <Contents>
        <Typography variant="span">Date Range</Typography>
        <SliderDate minDate={props.minDate} maxDate={props.maxDate} dates={props?.dates} onChange={props.onDateChange} />
      </Contents>}
    </>
  ) : props.market ? (
    <>
      <Contents>
        <Typography sx={typographyStyle} variant="span">
          Status
        </Typography>
        <MultiSelect onFilterChange={props.setPlotSelectedFilters} listOfFilterProp={props.listOfFilter} />
      </Contents>
      <Contents>
        <Typography sx={typographyStyle} variant="span">
          Crops
        </Typography>
        <MultiSelect onFilterChange={props.setCropSelectedFilters} listOfFilterProp={props.listOfCropFilter} />
      </Contents>
      {/* <Contents>
        <Typography variant="span">Ratings</Typography>
        <MultiSelect onFilterChange={props.setRatingSelectedFilters} listOfFilterProp={ratingFilters} rate />
      </Contents> */}
      <Contents>
        <Typography variant="span">Date Range</Typography>
        <SliderDate minDate={props.minDate} maxDate={props.maxDate} market={props.market} dates={props.dates} onChange={props.onDateChange} />
      </Contents>
    </>
  ) : null;
};

export default React.memo(FilterContainer);
