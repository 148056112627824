import React, { useState, useContext, useEffect } from 'react';
import ChipFilter from '../chip-filter';

function MultiSelect({ listOfFilterProp, onFilterChange, rate }) {
  const uniqueFiltersFunction = () => {
    const unique = [];
    const encounteredLabels = {};
    listOfFilterProp.forEach((filter) => {
      const { label } = filter;
      if (!encounteredLabels[label]) {
        unique.push(filter);
        encounteredLabels[label] = true;
      }
    });
    return unique;
  };
  const [arrayFilter, setArrayFilter] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);
  const [uniqueFilters, setUniqueFilters] = useState(true);
  useEffect(() => {
    const filters = uniqueFiltersFunction();
    setUniqueFilters(filters);
  }, [listOfFilterProp]);
  console.log(arrayFilter);

  useEffect(() => {
    setSelectedAll(arrayFilter.length === 0);
    if (arrayFilter.length === 0) {
      onFilterChange([]);
    }
    onFilterChange(arrayFilter);
  }, [arrayFilter]);

  const onChipFilterClick = (selected, row) => {
    if (selected) setArrayFilter((prev) => [...prev, row]);
    else {
      const values = arrayFilter.filter((value) => value.label !== row.label);
      setArrayFilter(values);
    }
  };

  return (
    <>
      {uniqueFilters.length > 0 ? (
        <div>
          <ChipFilter
            label={'All'}
            all
            selectedPropAll={selectedAll}
            onClick={() => {
              setArrayFilter([]);
            }}
          />
          {uniqueFilters.map((row, i) => {
            console.log(row);
            if (row.label !== undefined) {
              return (
                <ChipFilter
                  rate={rate}
                  row={row}
                  selectedProp={arrayFilter.length === 0}
                  key={i}
                  label={row.label}
                  onClick={onChipFilterClick}
                />
              );
            }
            return null;
          })}
        </div>
      ) : (
        <div>No Filters Available</div>
      )}
    </>
  );
}

export default MultiSelect;
