import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  InputAdornment,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';

const YourTableComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Function to handle search field change
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Function to handle row selection
  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
    }
  };

  // Function to handle actions on selected rows
  const handleActionClick = () => {
    // Perform actions on selected rows
  };

  // Function to handle menu open
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  // Function to handle menu close
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Function to handle menu item click
  const handleMenuItemClick = (action) => {
    // Perform the selected action
  };

  // Function to handle select all rows
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  // Function to handle filter button click
  const handleFilterClick = () => {
    // Perform filtering based on searchValue
  };

  const dummyData = [
    {
      id: 1,
      orderNumber: 'ORD-001',
      client: 'John Doe',
      status: 'Unfulfilled',
      paymentStatus: 'Unpaid',
      orderDate: '2023-06-01',
      totalAmount: 100.0,
    },
    {
      id: 2,
      orderNumber: 'ORD-002',
      client: 'Jane Smith',
      status: 'Open',
      paymentStatus: 'Paid',
      orderDate: '2023-06-02',
      totalAmount: 150.0,
    },
    // Add more dummy data here...
    {
      id: 11,
      orderNumber: 'ORD-011',
      client: 'Mark Johnson',
      status: 'Unfulfilled',
      paymentStatus: 'Unpaid',
      orderDate: '2023-06-11',
      totalAmount: 90.0,
    },
    {
      id: 12,
      orderNumber: 'ORD-012',
      client: 'Sara Davis',
      status: 'Open',
      paymentStatus: 'Unpaid',
      orderDate: '2023-06-12',
      totalAmount: 120.0,
    },
    // Add more dummy data here...
  ];

  const filteredData = dummyData.filter((row) => {
    if (selectedTab === 0) {
      return true; // Show all rows
    }
    if (selectedTab === 1) {
      return row.status === 'Unfulfilled';
    }
    if (selectedTab === 2) {
      return row.paymentStatus === 'Unpaid';
    }
    if (selectedTab === 3) {
      return row.status === 'Open';
    }
    if (selectedTab === 4) {
      return row.status === 'Closed';
    }
    return false;
  });

  return (
    <div>
      {/* Tabs */}
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{}} TabIndicatorProps={{ style: { display: 'none' } }}>
        <Tab
          sx={{
            bgcolor: selectedTab === 0 ? 'primary.main' : undefined,
            borderRadius: '10px',
            '&.MuiTab-root': {
              height: 20, // Set the desired height here
              minHeight: 'unset',
              paddingTop: 2,
              paddingBottom: 2,
            },
          }}
          label={
            <Typography
              sx={{
                color: selectedTab === 0 ? 'white' : undefined,
                bgcolor: selectedTab === 0 ? 'primary.main' : undefined,
              }}
            >
              All
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 1 ? 'primary.main' : undefined, borderRadius: '10px',
          '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          } }}
          label={
            <Typography
              sx={{
                color: selectedTab === 1 ? 'white' : undefined,
                bgcolor: selectedTab === 1 ? 'primary.main' : undefined,
              }}
            >
              Unfulfilled
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 2 ? 'primary.main' : undefined, borderRadius: '10px', '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          },
         }}
          label={
            <Typography
              sx={{
                color: selectedTab === 2 ? 'white' : undefined,
              }}
            >
              Unpaid
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 3 ? 'primary.main' : undefined, borderRadius: '10px',
          '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          }, }}
          label={
            <Typography
              sx={{
                color: selectedTab === 3 ? 'white' : undefined,
              }}
            >
              Open
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 4 ? 'primary.main' : undefined, borderRadius: '10px',
          '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          }, }}
          label={
            <Typography
              sx={{
                color: selectedTab === 4 ? 'white' : undefined,
              }}
            >
              Closed
            </Typography>
          }
        />
      </Tabs>

      {/* Search field and filter button */}
      {/* <div>
     
<TextField
  value={searchValue}
  onChange={handleSearchChange}
  variant="outlined"
  placeholder='Search'
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    style: { height: 40 },
  }}
  style={{ width: '50%', margin: 10, marginLeft: 0 }}
/>
        <Button style={{margin:10,marginLeft:0 }} variant="contained" onClick={handleFilterClick}>
          Filter
        </Button>
      </div> */}

      {/* Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox checked={selectedRows.length === filteredData.length} onChange={handleSelectAll} />
            </TableCell>
            <TableCell>Order Number</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Order Date</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox checked={selectedRows.includes(row)} onChange={(event) => handleRowSelect(event, row)} />
              </TableCell>
              <TableCell>{row.orderNumber}</TableCell>
              <TableCell>{row.client}</TableCell>
              <TableCell >{row.status}</TableCell>
              <TableCell><span style={{backgroundColor:row.paymentStatus ==='Paid'?'#dbf4b7':'#fdc7c7',padding:3,color:'#241c4d',borderRadius:'5px'}}> ◉ {row.paymentStatus}</span></TableCell>
              <TableCell>{row.orderDate}</TableCell>
              <TableCell>{row.totalAmount}</TableCell>
              <TableCell>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={() => handleMenuItemClick('Action 1')}>Delete</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('Action 2')}>Update</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Actions on selected rows */}
      {/* {selectedRows.length > 0 && (
        <div>
          <Button variant="contained" onClick={handleActionClick}>
            Perform Action
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default YourTableComponent;
