import React, { useEffect, useState } from 'react';
import { Slider, Typography } from '@mui/material';

export default function MultiColorRangeInput({
  greyWidth,
  yellowWidth,
  greenWidth,
  redWidth,
  canChange,
  onChange,
  data,
  startWeek
}) {
  const [isThumbHeld, setIsThumbHeld] = useState(false);
  const [range, setRange] = useState(0);

  useEffect(() => {
    if(onChange)
    onChange(range,data);
  }, [range]);

  const thumbSize = 8;
  const returnBackgroundColor = () => {
    if (range <= 0) {
      return '#b1b0b0';
    }
    if (range <= data.early?.endWeek) {
      return '#f2bb42';
    }
    if (range <= data.optimal?.endWeek) {
      return '#04a78b';
    }
    return '#cc1c00';
  };
  
  const trackStyle = {
    zIndex:21,
      '& .MuiSlider-rail': {
        opacity:1,
        backgroundImage: `linear-gradient(to right, 
          #b1b0b0 0%, 
          #b1b0b0 ${greyWidth}%, 
          #f2bb42 ${greyWidth}%, 
          #f2bb42 ${yellowWidth + greyWidth}%, 
          #04a78b ${yellowWidth + greyWidth}%, 
          #04a78b ${greenWidth + yellowWidth + greyWidth}%, 
          #cc1c00 ${greenWidth + yellowWidth + greyWidth}%, 
          #cc1c00 ${yellowWidth + greenWidth + redWidth + greyWidth}%)`,
      },
      '& .MuiSlider-track': {
        backgroundColor: 'red', // Change the track color
      },
      '& .MuiSlider-thumb': {
          display:!canChange&&'none',
        backgroundColor: 'blue', // Change the thumb color
      },
      '& .MuiSlider-valueLabel': {
      
        backgroundColor: returnBackgroundColor(), // Change the background color of the value label
      },
   
  
  };

  const railStyle = {
    backgroundImage: `linear-gradient(to right, 
      #b1b0b0 0%, 
      #b1b0b0 ${greyWidth}%, 
      #f2bb42 ${greyWidth}%, 
      #f2bb42 ${yellowWidth + greyWidth}%, 
      #04a78b ${yellowWidth + greyWidth}%, 
      #04a78b ${greenWidth + yellowWidth + greyWidth}%, 
      #cc1c00 ${greenWidth + yellowWidth + greyWidth}%, 
      #cc1c00 ${yellowWidth + greenWidth + redWidth + greyWidth}%)`,
    opacity: 0.3,
  };

  const returnComment = () => {
    if (range <= 0) {
      return '';
    }
    if (range <= data.early?.endWeek) {
      return data.early?.comment;
    }
    if (range <= data.optimal?.endWeek) {
      return data.optimal?.comment;
    }
    return data.late?.comment;
  };

  const returnText = (range) => {
    if (range <= 0) {
      return `Week ${range+startWeek}\n\n  ${returnComment()}`;
    }
    if (range <= data.early?.endWeek) {
      const comment = returnComment();
      return <div  className='text-center'>Week {range+startWeek} Early <br/> {comment}</div>;
    }
    
    if (range <= data.optimal?.endWeek) {
      return <div  className='text-center'>Week {range+startWeek} Optimal <br/> {returnComment()}</div>;
      // return `Week ${range} Optimal\n\n${returnComment()}`;
    }
    return <div className='text-center'>Week {range+startWeek} Late <br/> {returnComment()}</div>;
    // return `Week ${range} Late\n\n${returnComment()}`;
  };

  const handleMouseDown = () => {
    setIsThumbHeld(true);
  };

  const handleMouseUp = () => {
    setIsThumbHeld(false);
  };

  return (
    <div>
      <Slider
        min={0}
        valueLabelFormat={returnText(range)}
        max={data.late?.endWeek}
        step={1}
        value={range}
        onChange={(e, value) => setRange(value)}
        valueLabelDisplay={isThumbHeld ? 'on' : 'off'}
        disabled={!canChange}
        track={false}
        sx={{
          ...trackStyle,
          height: thumbSize,
          borderRadius: thumbSize,
          margin: '10px 0',
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '-15px',
          marginRight: '-40px',
          fontSize: '10pt',
        }}
      >
        <Typography style={{ textAlign: 'start', width: `${20}%`, fontSize: '7pt', }}>Week {startWeek}</Typography>
        <Typography style={{ textAlign: 'end', width: `${yellowWidth}%` , fontSize: '7pt'}}>
          Week {data?.early?.endWeek+startWeek}
        </Typography>
        <Typography style={{ textAlign: 'end', width: `${greenWidth}%`, fontSize: '7pt'}}>
          Week {data?.optimal?.endWeek+startWeek}
        </Typography>
        <Typography style={{ textAlign: 'end', width: `${redWidth + 10}%`, fontSize: '7pt' }}>
          Week {data?.late?.endWeek+startWeek}
        </Typography>
      </div>
    </div>
  );
}
