import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MenuItem, Typography } from '@mui/material';
import { countries } from '../../pages/data';

export default function CountrySelect({ onChange, value }) {
  const noOutline = {
    height: 40,
    outline: 'none',
    '& .MuiInputBase-root': {
      height: 40,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #484850',
      borderRadius: '5px',
    },
  };
  const noOutline2 = {
    width: 180,
    height: 40,
    marginTop: 1,
    '&.Mui-focused .fieldset': { border: 'none' },
    '& .MuiInputBase-root': {
      height: 40,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #484850',
      borderRadius: '5px',
    },
  };

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };
  const handleInputChange = (value) => {
    // alert(JSON.stringify(value));
  };
  return (
    <Autocomplete
      onFocus={()=>{}}
      id="country-select-demo"
      sx={noOutline}
      value={value}
      onChange={handleChange}
      defaultValue={{ code: 'ES', label: 'Spain', phone: '34' }}
      options={countries}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            size="small"
            sx={noOutline2}
          />
        </>
      )}
    >
      <MenuItem disabled value="">
        <span>Select</span>
      </MenuItem>
    </Autocomplete>
  );
}


