/* eslint-disable */
/* @ts-nocheck */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyD67v1-9mbIIMVLZvcKStbhfbNTNzYDuno',
  authDomain: 'agricom-321707.firebaseapp.com',
  projectId: 'agricom-321707',
  storageBucket: 'agricom-321707.appspot.com',
  messagingSenderId: '1014968407551',
  appId: '1:1014968407551:web:d3be14dd0dbfa7a5d343ca',
  measurementId: 'G-6RTCCBD5G8',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
