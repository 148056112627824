import { ToastContainer, toast } from 'react-toastify';
import firebase from '../firebase';

const db = firebase.firestore();

export const addFarmData = (userId, farmData) => {
  return new Promise((resolve, reject) => {
    db.collection('Users')
      .doc(userId)
      .collection('farms')
      .add(farmData)
      .then((farmRef) => {
        toast.success('Farm added successfully', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        console.log('Farm data added with ID:', farmRef.id);
        resolve(farmRef);
      })
      .catch((error) => {
        console.error('Error adding Farm data:', error);
        reject(error);
      });
  });
};

export const updateFarmData = (userId, farmId, updatedData) => {
  return new Promise((resolve, reject) => {
    db.collection('Users')
      .doc(userId)
      .collection('farms')
      .doc(farmId)
      .set({...updatedData,merge:true})
      .then(() => {
        toast.success('Farm data updated successfully', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resolve();
      })
      .catch((error) => {
        console.error('Error updating Farm data:', error);
        reject(error);
      });
  });
};

export const deleteFarmData = (userId, farmId) => {
  return new Promise((resolve, reject) => {
    db.collection('Users')
      .doc(userId)
      .collection('farms')
      .doc(farmId)
      .delete()
      .then(() => {
        console.log('Farm data deleted successfully');
        toast.success('Farm data deleted successfully', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resolve();
      })
      .catch((error) => {
        console.error('Error deleting Farm data:', error);
        reject(error);
      });
  });
};


export const getAllFarmsByUserId = async (userId) => {
  try {
    const userRef = db.collection('Users').doc(userId);
    const farmsSnapshot = await userRef.collection('farms').get();
    const farms = [];

    await Promise.all(
      farmsSnapshot.docs.map(async (farmDoc) => {
        const farmData = farmDoc.data();
        const cropsSnapshot = await farmDoc.ref.collection('crops').get();
        const crops = cropsSnapshot.docs.map((cropDoc) => {
   
          const cropData = cropDoc.data();
          return { id: cropDoc.id, ...cropData };
        });
        farms.push({
          id: farmDoc.id,
          ...farmData,
          crops,
        });
      })
    );
      console.log(farms)
    return farms;
  } catch (error) {
    console.error('Error getting farms with crops:', error);
    throw error;
  }
};


// export const getAllFarmsByUserId = (userId) => {
//   return new Promise((resolve, reject) => {
//     db.collection('Users')
//       .doc(userId)
//       .collection('farms')
//       .get()
//       .then((snapshot) => {
//         const farms = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//         console.log(farms)
//         resolve(farms);
//       })
//       .catch((error) => {
//         console.error('Error getting Farms:', error);
//         resolve([]);
//       });
//   });
// };
