import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@mui/material';
import Blue from '../assets/images/Blue.png';
import Yellow from '../assets/images/Yellow.png';
import Red from '../assets/images/Red.png';
import Mauve from '../assets/images/Mauve.png';

const TableView = (props) => {
  console.log(props.allWeeksEvents.current);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <img src={Blue} alt="" /> Water
              </TableCell>
              <TableCell>
                <img src={Yellow} alt="" /> Labor
              </TableCell>
              <TableCell>
                <img src={Red} alt="" /> Seeds
              </TableCell>
              <TableCell>
                <img src={Mauve} alt="" /> Fertilizers
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(props.allWeeksEvents.current).map((row, z) => (
              <TableRow key={z} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Week {row}
                </TableCell>
                <TableCell>
                  {props.allWeeksEvents.current[row].events.name === 'Water'
                    ? `${props.allWeeksEvents.current[row].events.value} (m2)`
                    : '---'}
                </TableCell>
                <TableCell>
                  {props.allWeeksEvents.current[row].events.Labor
                    ? `${props.allWeeksEvents.current[row].events.Labor} (hours)`
                    : '---'}
                </TableCell>
                <TableCell>
                  {props.allWeeksEvents.current[row].Seeds1
                    ? `${props.allWeeksEvents.current[row].Seeds1} (tonnes)`
                    : '---'}
                </TableCell>
                <TableCell>
                  {props.allWeeksEvents.current[row].N ? `${props.allWeeksEvents.current[row].N} (tonnes)` : '---'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableView;
