/* eslint-disable */
/* @ts-nocheck */

import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { Box, Card, Grid, useScrollTrigger } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useDispatch, useSelector, connect } from 'react-redux';
import { StackedLineChart } from '@mui/icons-material';
import Chart from 'react-apexcharts';
import Calendar from 'react-calendar';

import AddPlot from './add-plot';
import OrderCard from './OrderCard';
import FlexItem from './FlexItem';
import SideOrder from './SideOrder';
import styles from '../assets/styles/style.scss';
import Blue from '../assets/images/Blue.png';
import Yellow from '../assets/images/Yellow.png';
import Mauve from '../assets/images/Mauve.png';

import { crops, orders, farms } from '../utils/DataStructures';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import '../assets/styles/calendar-style.css';
import AppContext from './google-calendar/src/Context';
import YourTableComponent from './google-calendar/src/components/Table';
import TablePage from './google-calendar/src/components/TablePage';

const FlexBox = (props) => {
  const farmData = useSelector((state) => state.farms);
  const {filteredData}=useContext(AppContext)
  console.log(props.myFarms);

  const navigate = useNavigate();
  const weeklyEvents = {};
  const aggregatedWeeklyEvents = {};

  const aggregateWeeks = React.useRef([]);
  console.log(props.myFarms);

  useEffect(() => {
    farms?.forEach((farm) => {
      farm.crops?.forEach((crop) => {
        console.log(crop);
        const cropData = crops[crop.cropId];
        // TODO change the fetching from crops local db table to

        cropData?.weeks.forEach((weekData) => {
          const week = weekData.week;
          if (!weeklyEvents[week]) {
            weeklyEvents[week] = {};
          }
          Object.entries(weekData).forEach(([key, value]) => {
            if (key !== 'week' && week && key) {
              if (!weeklyEvents[week][key]) {
                weeklyEvents[week][key] = 0;
              }
              weeklyEvents[week][key] += value;
            }
          });
        });
      });
    });

    Object.entries(weeklyEvents).forEach(([week, events]) => {
      aggregatedWeeklyEvents[week] = {
        events: Object.entries(events).map(([name, value]) => ({
          name,
          value,
        })),
      };
    });

    aggregateWeeks.current = aggregatedWeeklyEvents;
  }, []);

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    'grey',
    '#FFA07A',
    '#9370DB',
    '#32CD32',
    '#8A2BE2',
    '#FF4500',
    '#48D1CC',
    '#800080',
    '#20B2AA',
    '#FF1493',
    '#778899',
    '#FFD700',
    '#00CED1',
    '#FF69B4',
    '#708090',
    '#FF6347',
    '#4682B4',
    '#FF8C00',
    '#66CDAA',
    '#8B008B',
    '#00FA9A',
    '#FF00FF',
    '#6A5ACD',
    '#FF7F50',
    '#40E0D0',
    '#C71585',
  ];

  // Generate chart data based on the crops array for each entry in the data
  let i = -1
  const chartData = filteredData.flatMap(item => {
  
    return(
    item.crops.map((cropItem,index) => {
      i++
      return({
      name: cropItem.label || cropItem.value || 'Unknown', // Modify this based on your data structure
      data:Array.from({ length: 7 }, () => getRandomNumber(1, 100)), // Modify this based on your data structure
      color: colors[i], // Use the colors array to set the color for each series
    })})
  )

}
  );
  const state = {
    options: {
      chart: {
        id: 'basic-bar',
        stacked: true
      },
      xaxis: {
        categories: Object.keys(crops['cr_2418'].weeks),
      },
      markers: {
        size: 6,
        colors: chartData.map(item => item.color), // Set the marker colors to match the line colors
      },
    },
    series: chartData,
    fill: {
      type: 'solid',
    },
    stroke: {
      type: 'solid',
      curve: 'smooth',
    },
  };

  
  console.log(aggregateWeeks.current);

  const events = [
    { title: 'Water', date: new Date(2023, 5, 11) },
    { title: 'Fertilizer', date: new Date(2023, 5, 12) },
    { title: 'Labor', date: new Date(2023, 5, 12) },
    { title: 'Labor', date: new Date(2023, 5, 18) },
    { title: 'Water', date: new Date(2023, 5, 18) },
    { title: 'Water', date: new Date(2023, 6, 12) },
    { title: 'Fertilizer', date: new Date(2023, 6, 12) },
    { title: 'Labor', date: new Date(2023, 6, 12) },
    { title: 'Water', date: new Date(2023, 6, 13) },
    { title: 'Labor', date: new Date(2023, 6, 13) },
    { title: 'Labor', date: new Date(2023, 6, 14) },
    { title: 'Labor', date: new Date(2023, 7, 14) },
    { title: 'Water', date: new Date(2023, 7, 14) },
  ];

  // Custom function to render event content for each tile
  const tileContent = ({ date, view }) => {
    const eventsForDate = getEventsForDate(date); // Fetch events for the specific date
    return (
      <div className="event-container">
        {eventsForDate.map((event, index) => (
          <div
            key={index}
            className="event"
            style={{
              backgroundColor:
                event.title === 'Water'
                  ? 'rgba(0, 255, 255, 0.1)'
                  : event.title === 'Fertilizer'
                  ? 'rgba(128, 0, 0, 0.1)'
                  : event.title === 'Labor'
                  ? 'rgba(112, 112, 0, 0.1)'
                  : '',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '5px',
              height: '23px',
              width: '100%',
              color: 'black',
              borderRadius: '5px',
              // border: '1px solid',
              // borderColor:
              //   event.title === 'Water'
              //     ? 'rgb(0, 255, 255)'
              //     : event.title === 'Fertilizer'
              //     ? 'brown'
              //     : event.title === 'Labor'
              //     ? 'green'
              //     : '',
              fontSize: '12pt',
            }}
          >
            <img src={event.title === 'Water' ? Blue : event.title === 'Labor' ? Yellow : Mauve} />
            <span
              style={{
                color:
                  event.title === 'Water'
                    ? 'cornflowerblue'
                    : event.title === 'Fertilizer'
                    ? 'maroon'
                    : event.title === 'Labor'
                    ? '#8B8000'
                    : '',
              }}
            >
              {event.title}
              (640 m2)
            </span>
          </div>
        ))}
      </div>
    );
  };

  // Custom function to get events for a specific date
  const getEventsForDate = (date) => {
    // Filter and return the events that match the specific date
    return events.filter((event) => isSameDay(event.date, date));
  };

  // Helper function to check if two dates are the same day
  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const tileClassName = ({ date }) => {
    const eventsForDate = getEventsForDate(date);
    return eventsForDate.length > 0 ? 'tile__has-events' : '';
  };

  const disableTile = ({ activeStartDate, date, view }) => {
    // Disable tiles for all views
    return true;
  };

  return (
    <Box sx={{ width: '100%',height:'100%'}} className={styles['main-flexbox-container']}>
      {props.pageView === 'Crops' ? (
        <>
        {props.children}
        </>
      ):props.pageView === 'Clients' ? (
        <>
        {props.children}
        </>
      ) : props.pageView === 'SideInventory' ? (
        <Box>
          <Card
            sx={{
              padding: '10px',
              backgroundColor: 'rgba(235, 235, 235, 0.2)',
              marginBottom: '10px',
              maxHeight: '330px',
              overflowY: 'scroll',
            }}
            className="orderCards"
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                color: 'gray',
                maxHeight: '600px',
              }}
            >
              <h3>Orders</h3>
              <Link
                to="/dashboard/products"
                style={{
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  color: 'gray',
                  fontSize: '14pt',
                  backgroundColor: 'white',
                  padding: '5px',
                }}
              >
                View More
              </Link>
            </Grid>
            <Grid>
              {orders.map((singleOrder) => {
                console.log(singleOrder);
                return <SideOrder data={singleOrder} location="dashboard" />;
              })}
            </Grid>
          </Card>
          <Card
            sx={{
              paddingLeft: '10px',
              backgroundColor: 'rgba(235, 235, 235, 0.2)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '10px',
              maxHeight: '370px',
              overflowY: 'scroll',
            }}
            className="orderCards"
          >
            <h3 style={{ color: 'grey' }}>Inventory</h3>
            {props.myFarms[0]?.crops.map((crop) => {
              return <FlexItem pageView="Inventory" crop={crop} />;
            })}
          </Card>
        </Box>
      ) : props.pageView === 'Orders' ? (
       <TablePage/>
      ) : props.pageView === 'Farm' ? (
        <Box item xs={11} md={9} sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
            <span
              style={{ fontSize: '22pt', fontWeight: 'bold' }}
            >{`${props.farm.value} (${props.farm.Area} ha)`}</span>
            {props.farm.Coordinates}
            {props.farm.crops.map((crop) => {
              console.log(crop);
              return <FlexItem pageView="Crop" farm={props.farms} crop={crop} />;
            })}

            <AddPlot
              onClick={() => {
                navigate(`/dashboard/step2`, { state: { name: props.farm.label } });
              }}
              name="Crop"
              color={'#E0F0ED'}
            />
          </div>
        </Box>
      ) : props.pageView === 'Timeline' ? (
        <Grid sx={{ width: '100%',height:'100%',padding:5}}>
          {/* <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ width: '32%' }}>
              <AppWidgetSummary color="secondary" icon={BarChartIcon} title="Total Revenue" total="220" sx={{}} />
            </Box>
            <Box sx={{ width: '32%' }}>
              <AppWidgetSummary color="secondary" icon title="Today Revenue" total="220" sx={{}} />
            </Box>
            <Box sx={{ width: '32%' }}>
              <AppWidgetSummary color="secondary" icon title="Remaining Inventory" total="220" sx={{}} />
            </Box>
          </Grid> */}
          <Grid sx={{ width: '100%',height:'70vh'}}>
            <Chart  width={'100%'} options={state.options} series={state.series} type="area" height={'100%'}  />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default FlexBox;
