import React, { useState, useEffect, useContext, useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { useDrop } from 'react-dnd';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, differenceInMinutes, addMinutes, isBefore, startOfDay } from 'date-fns';
import { CalendarContext } from '../common/CalendarContext';
import LineDivisor from './LineDivisor';
import createEditEvent from './createEditEvent';
import EventMark from './EventMark';
import AppContext from '../Context';

const useStyles = makeStyles(() =>
  createStyles({
    board: {
      minWidth: '100%',
      height: '100%',
      flex: 'none',
      verticalAlign: 'top',
      overflow: 'hidden',
      position: 'relative',
    },
    columnDivisor: {
      height: '100%',
      paddingLeft: 8,
      borderRight: '1px solid #dadce0',
    },
    dayContainer: {
      // backgroundColor: lightBlue[50],
      borderRight: '1px solid #dadce0',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      flex: '1 1 auto',
      height: '100%',
    },
    eventsContainer: {
      backgroundColor: 'transparent',
      position: 'relative',
      height: '100%',
      width: '97%',
      display: 'flex',
      justifyContent: 'center',
    },
    currentTimeDot: {
      background: 'rgb(226, 57, 43)',
      borderRadius: '50%',
      content: "''",
      position: 'absolute',
      height: 12,
      width: 12,
      zIndex: 52,
      marginTop: -1000,
      marginLeft: -6.5,
    },
    currentTimeLine: {
      position: 'absolute',
      zIndex: 51,
      borderColor: 'rgb(226, 57, 43)',
      borderTop: '2px solid',
      left: 0,
      right: -1,
    },
  })
);

function CalendarBoard(props) {
  const classes = useStyles();

  const { selectedWeekIndex, selectedWeek } = props;

  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, layout, defaultEventDuration, draggingEventId } = stateCalendar;
  const farmData = useSelector((state) => state.farms);
  const [currentTimePosition, setCurrentTimePosition] = useState(0);
  const [crops, setCrops] = useState([]);
  const {
    statusFilter,
    setStatusFilters,
    setListOfFilter,
    listOfCropFilter,
    setListOfCropFilter,
    filteredData,
    setFilteredData,
    plotSelectedFilters,
    setPlotSelectedFilters,
    cropSelectedFilters,
    setCropSelectedFilters,
    ratingSelectedFilters,
    setRatingSelectedFilters,
    minWeek,
    setMinWeek,
    maxWeek,
    setMaxWeek,
    selectedCrop,
    setSelectedCrop,
  } = useContext(AppContext);

  // const viewLayout = Array.from(Array(7).keys())
  const viewLayout = Array.from(Array(layout === 'week' ? 3 : layout === 'day' ? 1 : 0).keys());
  const localStorageMarkers = window.localStorage.getItem('markers');
  const getEventData = (day) => {
    const today = startOfDay(new Date());
    const isToday = format(day, 'DDMMYYYY') === format(today, 'DDMMYYYY');
    const isPast = isBefore(day, today);
    let indexCrop = -1;
    return (
      <>
        {filteredData.map((farm, index2) => {
          return (
            <>
              {farm.crops.map((crop, index) => {
                indexCrop += 1;
                return (
                  <EventMark
                    {...props}
                    isToday={isToday}
                    isPast={isPast}
                    key={`event-${crop.id}`}
                    calendarEvent={crop}
                    crop={crop}
                    sq={indexCrop}
                  />
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  // }, [localStorageMarkers])

  const CurrentTimeMark = (props) => {
    const { marginTop = -1000 } = props;
    return (
      <>
        <div className={classes.currentTimeDot} style={{ marginTop: marginTop - 5 }} />
        <div className={classes.currentTimeLine} style={{ marginTop }} />
      </>
    );
  };

  const onDrop = (eventEl) => {
    const eventID = draggingEventId;

    const eventMarkGhost = document.querySelector('[data-ghost]');
    if (!eventMarkGhost) return false;

    const eventBeginDate = new Date(eventMarkGhost.dataset.date);
    if (!eventBeginDate) return false;

    const localStorageMarkers = window.localStorage.getItem('markers');
    const markers = (localStorageMarkers && JSON.parse(localStorageMarkers)) || [];

    const draggedEvent = markers.find((markEvent) => markEvent.id === eventID);

    const duration = differenceInMinutes(new Date(draggedEvent.end), new Date(draggedEvent.begin));

    const marker = {
      ...draggedEvent,
      begin: format(eventBeginDate, 'YYYY/MM/DD HH:MM'),
      end: format(addMinutes(eventBeginDate, duration), 'YYYY/MM/DD HH:MM'),
    };

    window.localStorage.setItem(
      'markers',
      JSON.stringify([...markers.filter((markEvent) => markEvent.id !== eventID), marker])
    );

    setStateCalendar({ ...stateCalendar, draggingEventId: -1 });
    return stateCalendar;
  };

  const [, drop] = useDrop({
    accept: 'box',
    drop(item, monitor) {
      return undefined;
    },
  });

  const viewLayoutEl = useMemo(() => {
    return viewLayout.map((index) => {
      const day = layout === 'week' ? selectedWeek[index] : selectedDate;
      const isToday = format(day, 'DDMMYYYY') === format(new Date(), 'DDMMYYYY');
      const eventsOfDay = getEventData(day);

      return (
        <Grid
          item
          xs
          id={`day${index + 1}`}
          data-group="day-column"
          data-date={day}
          className={classes.dayContainer}
          key={`board-day-column-${layout}-${selectedWeekIndex}-${day}-${index}`}
        >
          {/* {isToday && <CurrentTimeMark marginTop={currentTimePosition} />} */}
          <div className={classes.eventsContainer} data-date={day}>
            {eventsOfDay}
          </div>
        </Grid>
      );
    });
    // ....
    // ....
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    classes,
    // currentTimePosition,
    defaultEventDuration,
    getEventData,
    layout,
    selectedDate,
    selectedWeek,
    selectedWeekIndex,
    viewLayout,
    localStorageMarkers,
  ]);

  return (
    <Grid
      ref={drop}
      onDrop={onDrop}
      container
      spacing={0}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.board}
    >
      <LineDivisor />
      <div className={classes.columnDivisor} />

      {viewLayoutEl}
    </Grid>
  );
}

export default CalendarBoard;
