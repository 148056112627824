const data = {
  Farm: [
    {
      rating: '4',
      label: 'Tajonar',
      value: 'Tajonar, Navarre, Spain',
      Area: 14,
      Coordinates: '42.768087,-1.622556',
      weekStart: 22,
      crops: [
        {
          name: 'Triora RZ F1',
          Area: 6,
          Pictures:
            'https://www.rijkzwaanusa.com/sites/default/files/styles/image_450x450/public/varieties/CA10842_webimage-16044C63-8CB7-44C5-9E88F7A7966122EA.jpg?itok=EqB9js4b',
        },
        {
          name: 'Roterno RZ F1',
          Area: 4,
          Pictures:
            'https://www.rijkzwaanusa.com/sites/default/files/styles/image_450x450/public/varieties/SL11042_webimage-D3F849CC-0ADF-4EBA-86F716927C0D2882.jpg?itok=MBnvIhNP',
        },
        {
          name: 'Sweeterno RZ F1',
          Area: 4,
          Pictures:
            'https://www.rijkzwaanusa.com/sites/default/files/styles/image_450x450/public/varieties/SL11339_webimage-EE3BEA5B-4DDE-4083-97573735689143A0.jpg?itok=7-Mc8n39',
        },
      ],
      Crop1: 'Triora RZ F1',
      Crop1ha: 6,
      Crop2: 'Roterno RZ F1',
      Crop2ha: 4,
      Crop3: 'Sweeterno RZ F1',
      Crop3ha: 4,
      isselected: false,
    },
    {
      rating: '3',
      label: 'Larraga',
      value: 'Larraga, Navarre, Spain',
      Area: 2,
      Coordinates: '42.558088,-1.840623',
      weekStart: 11,
      crops: [
        {
          name: 'Habib RZ F1',
          Area: 2,
          Pictures:
            'https://www.rijkzwaanusa.com/sites/default/files/styles/image_450x450/public/varieties/CA10851-S1_webimage-C05B80DD-A750-4212-84ED9273D93E97AC.jpg?itok=ac7dv1wz',
        },
      ],
      Crop1: 'Habib RZ F1',
      Crop1ha: 2,
      isselected: false,
    },
    {
      rating: '3',
      label: 'Lodosa',
      value: 'Lodosa, Navarre, Spain',
      Area: 8,
      crops: [],
      Coordinates: '42.431786,-2.060504',
      weekStart: 19,
      isselected: false,
    },
  ],
  Varieties: [
    {
      value: "Tomato, Plum",
      label: "Tomato, Plum",
      Variety: "Operino R ZF1",
      AverageWeight: "12-14g",
      Vigour: "Generative",
      CropType: "Tall, Generative",
      Comments:
        "Orange Pear Tomato, Mini.\nFor harvesting loose; longtrusses, good flavour and high Brix.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Tomato-Plum-Operino-R-ZF1.jpeg"
    },
    {
      value: "Tomato, Round",
      label: "Tomato, Round",
      Variety: "Roterno R ZF1",
      Comments:
        "Cluster Tomato, Intermediate Size.\nNice red color-for a relatively large tomato, the color stands out.\nVery consistent size, firmness and overall eating quality.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Tomato-Round-Roterno-R-ZF1.jpeg"
    },
    {
      value: "Tomato, Beefsteak",
      label: "Tomato, Beefsteak",
      Variety: "Taymyr RZ F1",
      AverageWeight: "260-280g",
      Vigour: "Normal",
      CropType: "Long open crop and internodes with pendulous leaves.",
      Comments: "Oblate/round fruits, large and productive.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Tomato-Beefsteak-Taymyr-RZ-F1.jpeg"
    },
    {
      value: "Tomato, Beefsteak ",
      label: "Tomato, Beefsteak ",
      Variety: "Montenegro RZ F1",
      AverageWeight: "200-250g",
      Vigour: "Vegetative",
      CropType: "Strongly vegetative plants",
      Comments: "Harvest in colour breaking stage.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Tomato-Beefsteak--Montenegro-RZ-F1.jpeg"
    },
    {
      value: "Tomato, Intermediate",
      label: "Tomato, Intermediate",
      Variety: "Sweeterno RZ F1",
      AverageWeight: "100-110g",
      Vigour: "Generative variety with strong vigour.",
      CropType: "Relatively short crop.",
      Comments:
        "High brix, very good flavour, nice flat trusses, strong against blossom end rot, resistance to powdery mildew (oidium), suitable for growing with artificial light.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Tomato-Intermediate-Sweeterno-RZ-F1.png"
    },
    {
      value: "Pepper, Sweet Specialty",
      label: "Pepper, Sweet Specialty",
      Variety: "Triora RZ F1",
      Comments:
        "This variety should go together with TrirossoRZ and TriyelloRZ in a traffic light mix.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Pepper-Sweet-Specialty-Triora-RZ-F1.jpeg"
    },
    {
      value: "Pepper, Sweet Lamuyo",
      label: "Pepper, Sweet Lamuyo",
      Variety: "Plinio RZ F1",
      Vigour: "Vigorous medium size plant.",
      CropType: "Long and big lamuyo fruit type.",
      WinterCrop: "Y",
      Comments: "Suitable for green harvest.\nRather early",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Pepper-Sweet-Lamuyo-Plinio-RZ-F1.jpeg"
    },
    {
      value: "Pepper, Medium Hot",
      label: "Pepper, Medium Hot",
      Variety: "Habib RZ F1",
      Vigour: "Open plants with good vigor.",
      WinterCrop: "Y",
      Comments:
        "Suitable for harvesting green and red.\nSuitable for protected winter cultivation.",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Pepper-Medium-Hot-Habib-RZ-F1.jpeg"
    },
    {
      value: "Carrot, Packaging Carrot",
      label: "Carrot, Packaging Carrot",
      Variety: "Ymer RZ F1",
      AverageWeight: "18-20cm",
      Growingdays: 130,
      Comments:
        "Hybrid Berlicum/Nantestype for fresh market.\nSuitable for long-term storability.\nStrong, dark green foliage, Nice cylindrical shape",
      Pictures:
      "https://agricom-assets.s3.eu-central-1.amazonaws.com/images/Carrot-Packaging-Carrot-Ymer-RZ-F1.jpeg"
    },
  ],
  Operino: [
    {
      week: '1',
      Seeds: 11111,
      Seeds2: 210,
      Seed: 2333.31,
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 150,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '2',
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '3',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '4',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '5',
      IrrigationRequirement: 22,
      Water: 220,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '6',
      IrrigationRequirement: 26,
      Water: 260,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '7',
      IrrigationRequirement: 30.7,
      Water: 307,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '8',
      IrrigationRequirement: 34,
      Water: 340,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '9',
      IrrigationRequirement: 36.5,
      Water: 365,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '10',
      IrrigationRequirement: 37.8,
      Water: 378,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '11',
      IrrigationRequirement: 38.6,
      Water: 386,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '12',
      IrrigationRequirement: 39.6,
      Water: 396,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '13',
      IrrigationRequirement: 40,
      Water: 400,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '14',
      IrrigationRequirement: 39.7,
      Water: 397,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '15',
      IrrigationRequirement: 29,
      Water: 290,
      Labor: 120,
      N: 0,
      PO3: 0,
      K2O5: 0,
      ProductionGradeA: 15,
      ProductionGradeB: 3.75,
    },
    {
      week: '16',
    },
    {
      week: '17',
    },
    {
      week: '18',
    },
    {
      week: '19',
    },
    {
      week: '20',
    },
    {
      week: '21',
    },
    {
      week: '22',
    },
    {
      week: '23',
    },
    {
      week: '24',
    },
    {
      week: '25',
    },
    {
      week: '26',
    },
    {
      week: '27',
    },
    {
      week: '28',
    },
    {
      week: '29',
    },
    {
      week: '30',
    },
    {
      week: '31',
    },
    {
      week: '32',
    },
    {
      week: '33',
    },
    {
      week: '34',
    },
    {
      week: '35',
    },
    {
      week: '36',
    },
    {
      week: '37',
    },
    {
      week: '38',
    },
    {
      week: '39',
    },
    {
      week: '40',
    },
    {
      week: '41',
    },
    {
      week: '42',
    },
  ],
  Roterno: [
    {
      week: '1',
      Seeds: 11111,
      Seeds1: 210,
      Seed: 2333.31,
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 150,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '2',
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '3',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '4',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '5',
      IrrigationRequirement: 22,
      Water: 220,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '6',
      IrrigationRequirement: 26,
      Water: 260,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '7',
      IrrigationRequirement: 30.7,
      Water: 307,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '8',
      IrrigationRequirement: 34,
      Water: 340,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '9',
      IrrigationRequirement: 36.5,
      Water: 365,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '10',
      IrrigationRequirement: 37.8,
      Water: 378,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '11',
      IrrigationRequirement: 38.6,
      Water: 386,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '12',
      IrrigationRequirement: 39.6,
      Water: 396,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '13',
      IrrigationRequirement: 40,
      Water: 400,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '14',
      IrrigationRequirement: 39.7,
      Water: 397,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '15',
      IrrigationRequirement: 29,
      Water: 290,
      Labor: 120,
      N: 0,
      PO3: 0,
      K2O5: 0,
      ProductionGradeA: 18.5,
      ProductionGradeB: 4.6,
    },
    {
      week: '16',
    },
    {
      week: '17',
    },
    {
      week: '18',
    },
    {
      week: '19',
    },
    {
      week: '20',
    },
    {
      week: '21',
    },
    {
      week: '22',
    },
    {
      week: '23',
    },
    {
      week: '24',
    },
    {
      week: '25',
    },
    {
      week: '26',
    },
    {
      week: '27',
    },
    {
      week: '28',
    },
    {
      week: '29',
    },
    {
      week: '30',
    },
    {
      week: '31',
    },
    {
      week: '32',
    },
    {
      week: '33',
    },
    {
      week: '34',
    },
    {
      week: '35',
    },
    {
      week: '36',
    },
    {
      week: '37',
    },
    {
      week: '38',
    },
    {
      week: '39',
    },
    {
      week: '40',
    },
    {
      week: '41',
    },
    {
      week: '42',
    },
  ],
  Sweeterno: [
    {
      week: '1',
      Seeds: 11111,
      Seeds1: 210,
      Seed: 2333.31,
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 150,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '2',
      IrrigationRequirement: 21.1,
      Water: 211,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '3',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '4',
      IrrigationRequirement: 24.3,
      Water: 243,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '5',
      IrrigationRequirement: 22,
      Water: 220,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '6',
      IrrigationRequirement: 26,
      Water: 260,
      Labor: 60,
      N: 6,
      PO3: 6,
      K2O5: 6,
    },
    {
      week: '7',
      IrrigationRequirement: 30.7,
      Water: 307,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '8',
      IrrigationRequirement: 34,
      Water: 340,
      Labor: 60,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '9',
      IrrigationRequirement: 36.5,
      Water: 365,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '10',
      IrrigationRequirement: 37.8,
      Water: 378,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '11',
      IrrigationRequirement: 38.6,
      Water: 386,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '12',
      IrrigationRequirement: 39.6,
      Water: 396,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '13',
      IrrigationRequirement: 40,
      Water: 400,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '14',
      IrrigationRequirement: 39.7,
      Water: 397,
      Labor: 120,
      N: 4,
      PO3: 4,
      K2O5: 10,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '15',
      IrrigationRequirement: 29,
      Water: 290,
      Labor: 120,
      N: 0,
      PO3: 0,
      K2O5: 0,
      ProductionGradeA: 13,
      ProductionGradeB: 3,
    },
    {
      week: '16',
    },
    {
      week: '17',
    },
    {
      week: '18',
    },
    {
      week: '19',
    },
    {
      week: '20',
    },
    {
      week: '21',
    },
    {
      week: '22',
    },
    {
      week: '23',
    },
    {
      week: '24',
    },
    {
      week: '25',
    },
    {
      week: '26',
    },
    {
      week: '27',
    },
    {
      week: '28',
    },
    {
      week: '29',
    },
    {
      week: '30',
    },
    {
      week: '31',
    },
    {
      week: '32',
    },
    {
      week: '33',
    },
    {
      week: '34',
    },
    {
      week: '35',
    },
    {
      week: '36',
    },
    {
      week: '37',
    },
    {
      week: '38',
    },
    {
      week: '39',
    },
    {
      week: '40',
    },
    {
      week: '41',
    },
    {
      week: '42',
    },
  ],
  Var: ['Operino', 'Roterno', 'Sweeterno'],
};

export default data;
