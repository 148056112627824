import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../firebase/fucntions';
import { setUser } from '../store/user';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,
  },
  avatar: {
    width: 116,
    height: 116,
    border: `2px solid`,
  },
  uploadButton: {
    marginLeft: 2,
  },
  form: {
    marginBottom: 2,
  },
  textField: {
    marginBottom: 10,
  },
  button: {
    marginLeft: 2,
  },
}));

const ProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [avatarUrl, setAvatarUrl] = useState('/path/to/profile-picture.jpg');
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  // alert(JSON.stringify(user))
  const handleUpdateProfile = (event) => {
    event.preventDefault();
    // Handle profile update logic
  };

  const handleUploadAvatar = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setAvatarUrl(e.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveAvatar = () => {
    setAvatarUrl('/path/to/profile-picture.jpg');
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    // Handle password change logic
  };
  const updateUser = async () => {
    const userData = {
      ...user,
      firstName,
      lastName,
      email,
      phone,
    };
    console.log(userData);
    updateUserData(user.uid, userData);
    dispatch(setUser(userData));
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <Grid item className={classes.avatarContainer}>
          <Button disabled component="label" className={classes.uploadButton}>
            <Avatar alt="Profile Picture" src={avatarUrl} className={classes.avatar} />

            <input type="file" accept="image/*" hidden onChange={handleUploadAvatar} />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1" align="center">
            My Profile
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={handleUpdateProfile} className={classes.form}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={updateUser} type="submit">
              Update Profile
            </Button>
          </form>
        </Grid>
        {/* <Grid item>
          <Typography variant="h6" component="h2" align="center">
            Change Password
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={handleChangePassword} className={classes.form}>
            <TextField
              label="Current Password"
              variant="outlined"
              type="password"
              fullWidth
              className={classes.textField}
            />
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              className={classes.textField}
            />
            <TextField
              label="Confirm New Password"
              variant="outlined"
              type="password"
              fullWidth
              className={classes.textField}
            />
            <Button variant="contained" color="primary" type="submit">
              Change Password
            </Button>
          </form>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default ProfilePage;
