// Users have a 1 to many relationship with farms

export const farms = [
  {
    id: 1,
    label: 'Tajonar',
    value: 'Tajonar, Navarre, Spain',
    Area: 14,
    Coordinates: '42.768087,-1.622556',
    weekStart: 22,
    crops: [
      {
        cropId: 'cr_2418',
        Area: 6,
        weekStart: 22,
      },
      {
        cropId: 'cr_2287',
        Area: 4,
        weekStart: 19,
      },
      {
        cropId: 'cr_2418',
        Area: 4,
        weekStart: 21,
      },
    ],
  },
  {
    id: 1,
    label: 'Larraga',
    value: 'Larraga, Navarre, Spain',
    Area: 2,
    Coordinates: '42.558088,-1.840623',
    weekStart: 22,
    crops: [
      {
        cropId: 'cr_2314',
        Area: 6,
        weekStart: 22,
      },
      {
        cropId: 'cr_2287',
        Area: 4,
        weekStart: 19,
      },
      {
        cropId: 'cr_2418',
        Area: 4,
        weekStart: 21,
      },
    ],
  },
];

export const userFarms = {
  user_132: [1, 2, 3],
};

export const crops = {
  cr_2418: {
    id: 1,
    cropId: 'cr_2418',
    type: 'Tomato',
    subtype: 'Plum',
    label: 'Tomato, Plum',
    Variety: 'Operino R ZF1',
    AverageWeight: '12-14g',
    startWeek: 19,
    Vigour: 'Generative',
    CropType: 'Tall, Generative',
    weeks: [
      {
        week: '1',
        Water: 211,
        Labor: 150,
        Fertilizers: 18,
      },
      {
        week: '2',
        Water: 211,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '3',
        Water: 243,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '4',
        Water: 243,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '5',
        Water: 220,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '6',
        Water: 260,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '7',
        Water: 307,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '8',
        Water: 340,
        Labor: 60,
        Fertilizers: 18,
      },
      {
        week: '9',
        Water: 365,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '10',
        Water: 378,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '11',
        Water: 386,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '12',
        Water: 396,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '13',
        Water: 400,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '14',
        Water: 397,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '15',
        Water: 290,
        Labor: 120,
        Fertilizers: 18,
      },
      {
        week: '16',
      },
      {
        week: '17',
      },
      {
        week: '18',
      },
      {
        week: '19',
      },
      {
        week: '20',
      },
      {
        week: '21',
      },
      {
        week: '22',
      },
      {
        week: '23',
      },
      {
        week: '24',
      },
      {
        week: '25',
      },
      {
        week: '26',
      },
      {
        week: '27',
      },
      {
        week: '28',
      },
      {
        week: '29',
      },
      {
        week: '30',
      },
      {
        week: '31',
      },
      {
        week: '32',
      },
      {
        week: '33',
      },
      {
        week: '34',
      },
      {
        week: '35',
      },
      {
        week: '36',
      },
      {
        week: '37',
      },
      {
        week: '38',
      },
      {
        week: '39',
      },
      {
        week: '40',
      },
      {
        week: '41',
      },
      {
        week: '42',
      },
    ],
    // revisit structure of the time data
    timeData: {
      Early: {
        startWeek: 0,
        endWeek: 12,
      },
      Optimal: {
        startWeek: 13,
        endWeek: 20,
      },
      Late: {
        startWeek: 21,
        endWeek: 36,
      },
    },

    winterCrop: 'Y',
    growingWeeks: 19,
    Comments: 'Orange Pear Tomato, Mini.\nFor harvesting loose; longtrusses, good flavour and high Brix.',
    Pictures:
      'https://mccullaghsimplyfresh.com/wp-content/uploads/2019/08/tomatoesplum.jpg',
  },
};

export const User = [
  {
    id: 'user_132',
    username: 'Test User',
    email: 'test@gmail.com',
    password: 'pass123',
  },
];

export const Events = [
  {
    name: 'Water',
    quantity: 20,
    unit: 'L',
    Picture: 'www',
    // itemsType will be placed in the events of type fertilizer ex: N, K, etc.
    itemsType: '',
  },
];

export const orders = [
  {
    price: 3.99,
    clientName: 'John Doe',
    deliveryDate: 'Sat Nov 26 2022',
    deliveryLocation: 'No delivery location',
    productName: 'Pepper Meyer',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Accepted',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    price: 9.99,
    clientName: 'Jane Smith',
    deliveryDate: 'Mon Dec 05 2022',
    deliveryLocation: '123 Main St.',
    productName: 'Tomato Cherry',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Delivered',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    price: 12.99,
    clientName: 'Bob Johnson',
    deliveryDate: 'Tue Dec 13 2022',
    deliveryLocation: '456 Elm St.',
    productName: 'Cucumber English',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Cancelled',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    price: 5.99,
    clientName: 'Mary Adams',
    deliveryDate: 'Wed Dec 21 2022',
    deliveryLocation: '789 Oak St.',
    productName: 'Zucchini Yellow',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Rejected',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    price: 17.99,
    clientName: 'David Lee',
    deliveryDate: 'Thu Dec 29 2022',
    deliveryLocation: '1011 Maple St.',
    productName: 'Squash Butternut',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Delivered',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
  {
    price: 33.99,
    clientName: 'Sarah Kim',
    deliveryDate: 'Fri Jan 06 2023',
    deliveryLocation: '1213 Pine St.',
    productName: 'Broccoli',
    productImage: 'https://dummyimage.com/300x200/000/fff&text=Product+Image',
    status: 'Accepted',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec tortor odio. Fusce iaculis auctor turpis at bibendum. Integer eu mi eget ipsum venenatis rhoncus nec sed justo.',
  },
];
