import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  InputAdornment,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';

const TablePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Function to handle search field change
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Function to handle row selection
  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
    }
  };

  // Function to handle actions on selected rows
  const handleActionClick = () => {
    // Perform actions on selected rows
  };

  // Function to handle menu open
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  // Function to handle menu close
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Function to handle menu item click
  const handleMenuItemClick = (action) => {
    // Perform the selected action
  };

  // Function to handle select all rows
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  // Function to handle filter button click
  const handleFilterClick = () => {
    // Perform filtering based on searchValue
  };

  const dummyData = [
    {
      id: 1,
      orderNumber: 'ORD-001',
      client: 'Client 1',
      status: 'Unfulfilled',
      paymentStatus: 'Unpaid',
      orderDate: '2023-06-01',
      totalAmount: 100.0,
      fulfilmentDate: '2023-06-10', // Added Fulfilment date
      crops: [
        {
          name: 'Tomato',
          varieties: ['Plum', 'Habib Rz f1'],
          plots: ['Tajonar'],
        },
      ],
    },
    {
      id: 2,
      orderNumber: 'ORD-002',
      client: 'Client 2',
      status: 'Open',
      paymentStatus: 'Paid',
      orderDate: '2023-06-02',
      totalAmount: 150.0,
      fulfilmentDate: '2023-06-15', // Added Fulfilment date
      crops: [
        {
          name: 'Pepper',
          varieties: ['Bell Pepper', 'Sweet Pepper'],
          plots: ['Larraga'],
        },
        {
          name: 'Cucumber',
          varieties: ['Mini Cucumber', 'Green Cucumber'],
          plots: ['Tajonar', 'Lodoso'],
        },
      ],
    },
  
    
      // {
      //   id: 3,
      //   orderNumber: 'ORD-003',
      //   client: 'Michael Johnson',
      //   status: 'Open',
      //   paymentStatus: 'Paid',
      //   orderDate: '2023-06-03',
      //   totalAmount: 75.0,
      //   fulfilmentDate: '2023-06-20',
      //   crops: [
      //     {
      //       name: 'Potato',
      //       varieties: ['Russet', 'Yukon Gold'],
      //       plots: ['Lodoso'],
      //     },
      //   ],
      // },
      // {
      //   id: 4,
      //   orderNumber: 'ORD-004',
      //   client: 'Emily Williams',
      //   status: 'Closed',
      //   paymentStatus: 'Paid',
      //   orderDate: '2023-06-04',
      //   totalAmount: 180.0,
      //   fulfilmentDate: '2023-06-25',
      //   crops: [
      //     {
      //       name: 'Lettuce',
      //       varieties: ['Romaine', 'Iceberg'],
      //       plots: ['Larraga', 'Tajonar'],
      //     },
      //     {
      //       name: 'Cabbage',
      //       varieties: ['Green Cabbage', 'Red Cabbage'],
      //       plots: ['Lodoso'],
      //     },
      //   ],
      // },
      // {
      //   id: 5,
      //   orderNumber: 'ORD-005',
      //   client: 'Robert Davis',
      //   status: 'Unfulfilled',
      //   paymentStatus: 'Unpaid',
      //   orderDate: '2023-06-05',
      //   totalAmount: 95.0,
      //   fulfilmentDate: '2023-06-30',
      //   crops: [
      //     {
      //       name: 'Carrot',
      //       varieties: ['Baby Carrot', 'Rainbow Carrot'],
      //       plots: ['Tajonar', 'Larraga', 'Lodoso'],
      //     },
      //   ],
      // },
      // {
      //   id: 6,
      //   orderNumber: 'ORD-006',
      //   client: 'Sophia Martinez',
      //   status: 'Unfulfilled',
      //   paymentStatus: 'Unpaid',
      //   orderDate: '2023-06-06',
      //   totalAmount: 120.0,
      //   fulfilmentDate: '2023-07-05',
      //   crops: [
      //     {
      //       name: 'Broccoli',
      //       varieties: ['Green Broccoli', 'Purple Broccoli'],
      //       plots: ['Lodoso'],
      //     },
      //   ],
      // },
      // {
      //   id: 7,
      //   orderNumber: 'ORD-007',
      //   client: 'James Johnson',
      //   status: 'Open',
      //   paymentStatus: 'Paid',
      //   orderDate: '2023-06-07',
      //   totalAmount: 65.0,
      //   fulfilmentDate: '2023-07-10',
      //   crops: [
      //     {
      //       name: 'Onion',
      //       varieties: ['Yellow Onion', 'Red Onion'],
      //       plots: ['Tajonar'],
      //     },
      //   ],
      // },
      // {
      //   id: 8,
      //   orderNumber: 'ORD-008',
      //   client: 'Ava Lee',
      //   status: 'Closed',
      //   paymentStatus: 'Paid',
      //   orderDate: '2023-06-08',
      //   totalAmount: 200.0,
      //   fulfilmentDate: '2023-07-15',
      //   crops: [
      //     {
      //       name: 'Spinach',
      //       varieties: ['Baby Spinach', 'Savoy Spinach'],
      //       plots: ['Lodoso', 'Larraga'],
      //     },
      //     {
      //       name: 'Kale',
      //       varieties: ['Curly Kale', 'Tuscan Kale'],
      //       plots: ['Tajonar'],
      //     },
      //   ],
      // },
      // {
      //   id: 9,
      //   orderNumber: 'ORD-009',
      //   client: 'Oliver Wilson',
      //   status: 'Unfulfilled',
      //   paymentStatus: 'Unpaid',
      //   orderDate: '2023-06-09',
      //   totalAmount: 85.0,
      //   fulfilmentDate: '2023-07-20',
      //   crops: [
      //     {
      //       name: 'Radish',
      //       varieties: ['Cherry Belle', 'French Breakfast'],
      //       plots: ['Tajonar', 'Lodoso'],
      //     },
      //   ],
      // },
      // {
      //   id: 10,
      //   orderNumber: 'ORD-010',
      //   client: 'Emma Anderson',
      //   status: 'Unfulfilled',
      //   paymentStatus: 'Unpaid',
      //   orderDate: '2023-06-10',
      //   totalAmount: 105.0,
      //   fulfilmentDate: '2023-07-25',
      //   crops: [
      //     {
      //       name: 'Eggplant',
      //       varieties: ['Black Beauty', 'Japanese Eggplant'],
      //       plots: ['Larraga'],
      //     },
      //     {
      //       name: 'Zucchini',
      //       varieties: ['Green Zucchini', 'Yellow Zucchini'],
      //       plots: ['Lodoso', 'Tajonar'],
      //     },
      //   ],
      // },
      // Add more dummy data here...

    
  ];
  
  console.log(dummyData);
  

  const filteredData = dummyData.filter((row) => {
    if (selectedTab === 0) {
      return true; // Show all rows
    }
    if (selectedTab === 1) {
      return row.status === 'Unfulfilled';
    }
    if (selectedTab === 2) {
      return row.paymentStatus === 'Unpaid';
    }
    if (selectedTab === 3) {
      return row.status === 'Open';
    }
    if (selectedTab === 4) {
      return row.status === 'Closed';
    }
    return false;
  });

  return (
    <div>
      {/* Tabs */}
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{}} TabIndicatorProps={{ style: { display: 'none' } }}>
        <Tab
          sx={{
            bgcolor: selectedTab === 0 ? 'primary.main' : undefined,
            borderRadius: '10px',
            '&.MuiTab-root': {
              height: 20, // Set the desired height here
              minHeight: 'unset',
              paddingTop: 2,
              paddingBottom: 2,
            },
          }}
          label={
            <Typography
              sx={{
                color: selectedTab === 0 ? 'white' : undefined,
                bgcolor: selectedTab === 0 ? 'primary.main' : undefined,
              }}
            >
              All
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 1 ? 'primary.main' : undefined, borderRadius: '10px',
          '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          } }}
          label={
            <Typography
              sx={{
                color: selectedTab === 1 ? 'white' : undefined,
                bgcolor: selectedTab === 1 ? 'primary.main' : undefined,
              }}
            >
              Unfulfilled
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 2 ? 'primary.main' : undefined, borderRadius: '10px', '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          },
         }}
          label={
            <Typography
              sx={{
                color: selectedTab === 2 ? 'white' : undefined,
              }}
            >
              Unpaid
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 3 ? 'primary.main' : undefined, borderRadius: '10px',
          '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          }, }}
          label={
            <Typography
              sx={{
                color: selectedTab === 3 ? 'white' : undefined,
              }}
            >
              Open
            </Typography>
          }
        />
        <Tab
          sx={{ bgcolor: selectedTab === 4 ? 'primary.main' : undefined, borderRadius: '10px',
          '&.MuiTab-root': {
            height: 20, // Set the desired height here
            minHeight: 'unset',
            paddingTop: 2,
            paddingBottom: 2,
          }, }}
          label={
            <Typography
              sx={{
                color: selectedTab === 4 ? 'white' : undefined,
              }}
            >
              Closed
            </Typography>
          }
        />
      </Tabs>

      {/* Search field and filter button */}
      {/* <div>
     
<TextField
  value={searchValue}
  onChange={handleSearchChange}
  variant="outlined"
  placeholder='Search'
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    style: { height: 40 },
  }}
  style={{ width: '50%', margin: 10, marginLeft: 0 }}
/>
        <Button style={{margin:10,marginLeft:0 }} variant="contained" onClick={handleFilterClick}>
          Filter
        </Button>
      </div> */}

      {/* Table */}
    
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox checked={selectedRows.length === filteredData.length} onChange={handleSelectAll} />
            </TableCell>
            <TableCell>Fulfilment Date</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Payment Status</TableCell>
            {/* <TableCell>Order Date</TableCell> */}
     
     
            <TableCell>Crops</TableCell>
            <TableCell>Varieties</TableCell>
            <TableCell>Plots</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox checked={selectedRows.includes(row)} onChange={(event) => handleRowSelect(event, row)} />
              </TableCell>
              <TableCell>{row.fulfilmentDate}</TableCell>
              <TableCell>{row.client}</TableCell>
              <TableCell>{row.totalAmount}</TableCell>
              <TableCell>
                <span style={{ backgroundColor: row.paymentStatus === 'Paid' ? '#dbf4b7' : '#fdc7c7', padding: 3, color: '#241c4d', borderRadius: '5px' }}> ◉ {row.paymentStatus}</span>
              </TableCell>
              {/* <TableCell>{row.orderDate}</TableCell> */}
            
          
              <TableCell>
                {row.crops.map((crop, index) => (
                  <span key={index}>{crop.name}{index !== row.crops.length - 1 ? ', ' : ''}</span>
                ))}
              </TableCell>
              <TableCell>
                {row.crops.map((crop) => (
                  <span key={crop.name}>
                    {crop.varieties.map((variety, index) => (
                      <span key={index}>{variety}{index !== crop.varieties.length - 1 ? ', ' : ''}</span>
                    ))}
                    {crop.name !== row.crops[row.crops.length - 1].name ? <br /> : ''}
                  </span>
                ))}
              </TableCell>
              <TableCell>
                {row.crops.map((crop) => (
                  <span key={crop.name}>
                    {crop.plots.map((plot, index) => (
                      <span key={index}>{plot}{index !== crop.plots.length - 1 ? ', ' : ''}</span>
                    ))}
                    {crop.name !== row.crops[row.crops.length - 1].name ? <br /> : ''}
                  </span>
                ))}
              </TableCell>
              <TableCell>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => handleMenuItemClick('Action 1')}>Delete</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('Action 2')}>Update</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Actions on selected rows */}
      {/* {selectedRows.length > 0 && (
        <div>
          <Button variant="contained" onClick={handleActionClick}>
            Perform Action
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default TablePage;
