import { Grid, Typography, Card, Input, Button, ButtonGroup, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { crops } from '../utils/DataStructures';
import Peppers from '../assets/peppers.png';

export default function InventoryProductCalendar(props) {
    // Function to generate a random value between min and max
    const getRandomValue = (min, max) => {
      return Math.random() * (max - min) + min;
    };
  
    // Generate random values for available quantity and expected price
    const availableQuantity = getRandomValue(50, 200).toFixed(0); // Random value between 50 and 200 with 2 decimal places
    const expectedPrice = getRandomValue(1, 3).toFixed(2); // Random value between 1 and 3 with 2 decimal places
    const getRandomSymbol = () => {
      return Math.random() < 0.5 ? "▲" : "▼";
    };
  
    const symbol = getRandomSymbol();
    const textStyle = {
      color: symbol === "▲" ? "#0ca488" : "red",
      fontSize: "10pt",
      lineHeight: props.viewDetails ? "10px" : "0px",
      marginTop: "5px",
      textAlign: "center",
      marginLeft: "5px",
    };  
    const getRandomSymbol2 = () => {
      return Math.random() < 0.5 ? "▲" : "▼";
    };
  
    const symbol2 = getRandomSymbol2();
    const textStyle2 = {
      color: symbol2 === "▲" ? "#0ca488" : "red",
      fontSize: "10pt",
      lineHeight: props.viewDetails ? "10px" : "0px",

      marginBottom: "5px",
      textAlign: "center",
      marginLeft: "5px",
    };  

    
  return (
    <>
      <Grid container style={{ ...props.style }}>
        <Grid style={{ ...subItemStyle, ...imageStyle }} item xs={3} sm={3} md={3} lg={3}>
          <img src={props.crop ? props.crop.Pictures : Peppers} alt="crop" />
        </Grid>
        <Grid style={{ ...itemStyle, paddingTop: '18px' }} item xs={9} sm={9} md={9} lg={9}>
          <Typography variant="span" sx={{ fontSize: '12pt', fontWeight: 'bold', lineHeight: '18px', paddingRight: 1 }}>
            <Typography
              variant="span"
              sx={{ fontSize: '12pt', fontWeight: 'bold', lineHeight: '18px', paddingRight: 1 }}
            >
              {props.crop.label ? props.crop.label : 'Unnamed'}
            </Typography>
            <Typography
              style={{ fontWeight: 'normal', fontSize: '11pt', lineHeight: '18px', marginTop: '5px', paddingRight: 1 }}
              variant="h5"
            >
              {props.crop.Variety ? props.crop.Variety : null}
              {/* Crop name goes here */}
            </Typography>
            <Grid container width={'100%'}>
              <Grid item md={4}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    pt: 1,
                    fontSize: '8pt',
                    color: '#aeaeac',
                    fontWeight: '300',
                    display: 'flex',
                    lineHeight: '12px',
                    flexDirection: 'column',
                  }}
                >
                  Available <br />
                  Quantity
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    fontSize: '9pt',
                    fontWeight: 'bold',
                    lineHeight: props.viewDetails ? '10px' : '0px',
                    textAlign: 'center',
                    paddingRight: 1,
                  }}
                >
                    {availableQuantity}  kg
                    <span style={textStyle}>{symbol}</span>
                  
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    pt: 1,
                    fontSize: '8pt',
                    color: '#aeaeac',
                    fontWeight: '300',
                    display: 'flex',
                    lineHeight: '12px',
                    flexDirection: 'column',
                  }}
                >
                  Expected <br />
                  Price
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    fontSize: '9pt',
                    fontWeight: 'bold',
                    lineHeight: props.viewDetails ? '10px' : '0px',
                    textAlign: 'center',
                  }}
                >
              ${expectedPrice}/kg
              <span style={textStyle2}>{symbol2}</span>
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    pt: 1,
                    fontSize: '8pt',
                    color: '#aeaeac',
                    fontWeight: '300',
                    display: 'flex',
                    lineHeight: '12px',
                    flexDirection: 'column',
                  }}
                >
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    fontSize: '9pt',
                    fontWeight: '300',
                    color: '#02A78B',
                    lineHeight: props.viewDetails ? '10px' : '0px',
                    textAlign: 'center',
                  }}
                >
                  View Details
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
        {/* <Grid style={itemStyle} item xs={3} sm={3} md={3} lg={3}>
          <Typography
            variant="span"
            sx={{
              fontSize: '9pt',
              lineHeight: '18px',
              color: '#409d40',
              fontWeight: 'bold',
              textAlign: 'center',
              marginLeft: '-10px',paddingRight:1 
              
            }}
          >
            Available Quantity
          </Typography>
          <Typography
            variant="span"
            sx={{ fontSize: '11pt', fontWeight: 'bold', lineHeight: props.viewDetails?'10px':'0px', textAlign: 'center',paddingRight:1  }}
          >
        {props.sq*110/2+100} kg
            <span
              style={{
                color: '#0ca488',
                fontSize: '12pt',
                lineHeight: props.viewDetails?'10px':'0px',
                marginBottom: '5px',
                textAlign: 'center',
              }}
            >
              ▲
            </span>
          </Typography>
        </Grid>
        <Grid style={itemStyle} item xs={3} sm={3} md={3} lg={3}>
          <Typography
            variant="span"
            sx={{ fontSize: '9pt', lineHeight: '18px', textAlign: 'center', color: '#aeaeac', fontWeight: 'bold' }}
          >
            Expected {props.viewDetails && <br />}Price
          </Typography>
          <Typography
            variant="span"
            sx={{ fontSize: '11pt', fontWeight: 'bold', lineHeight: props.viewDetails?'10px':'0px', textAlign: 'center' }}
          >
            $1.29/kg
            <span style={{ color: '#0ca488', fontSize: '12pt', lineHeight: props.viewDetails?'10px':'0px', marginBottom: '5px' }}>▲</span>
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}

const itemStyle = {
  height: 140,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  rowGap: '20px',
};
const subItemStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '-10px',
};

const imageStyle = {
  padding: 1,
  maxWidth: 110,
};
