import { Chip } from '@mui/material';
import React, { useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';

export default function ChipFilter({ label, onClick, rate, leftIcon, selectedProp, selectedPropAll, row, all }) {
  const [selected, setSelected] = React.useState(false);

  useEffect(() => {
    if (selectedProp && !all) {
      setSelected(false);
    }
    if (all) {
      setSelected(selectedPropAll);
    }
  }, [selectedProp, selectedPropAll]);

  const onClickChip = () => {
    if (!(all && selected)) {
      onClick(!selected, row);
      setSelected(!selected);
    }
  };

  return (
    <Chip
      onDelete={rate && onClickChip}
      avatar={leftIcon}
      deleteIcon={<StarIcon style={selected ? rateStyleSelected : rateStyle} />}
      onClick={onClickChip}
      label={getWordAfterComma(label)}
      style={selected ? chipStyleSelected : chipStyle}
      variant={'outlined'}
    />
  );
}

function getWordAfterComma(input) {
  const commaIndex = input.indexOf(",");
  let output;

  if (commaIndex !== -1) {
    output = input.substring(commaIndex + 1).trim();
  } else {
    output =input;
  }

  return output;
}

const chipStyle = {
  borderColor: '#02A78B',
  color: '#02A78B',
  borderWidth: 1,
  cursor: 'pointer',
  margin: 2,
  marginLeft: 0,
  fontSize: '11px',
  padding: '0px',

  height:'25px'
};

const chipStyleSelected = {
  minWidth: 0,
  padding: '0px',
  borderColor: '#02A78B',
  backgroundColor: '#02A78B',
  color: 'white',
  borderWidth: 1,
  cursor: 'pointer',
  margin: 2,
  marginLeft: 0,

  fontSize: '11px',
  height:'25px',

};

const rateStyle = {
  color: '#02A78B',
  marginLeft: '-7px',
  width: '20px',
};

const rateStyleSelected = {
  color: 'white',
  marginLeft: '-7px',
  width: '20px',
};
