import { ToastContainer, toast } from 'react-toastify';
import firebase from '../firebase';

// Firestore database reference
const db = firebase.firestore();

export const addCropData = async (userId, farmId, cropData) => {
  try {
    const cropId = db.collection('Users').doc().id;
    const cropRef = await db
      .collection('Users')
      .doc(userId)
      .collection('farms')
      .doc(farmId)
      .collection('crops')
      .doc(cropId);
      
      await cropRef.set({ ...cropData, id: cropId });

    console.log('Crop data added with ID:', cropRef.id);
    return cropRef.id;
  } catch (error) {
    console.error('Error adding Crop data:', error);
    throw error;
  }
};

// Function to update Crop data
export const updateCropData = async (userId, farmId, cropId, updatedData) => {
  try {
    await db
      .collection('Users')
      .doc(userId)
      .collection('farms')
      .doc(farmId)
      .collection('crops')
      .doc(cropId)
      .update(updatedData);
      toast.success('Crop Data Updated', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    console.log('Crop data updated successfully');
  } catch (error) {
    console.error('Error updating Crop data:', error);
    toast.success('Something Went Wrong', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    throw error;
  }
};

// Function to delete Crop data
export const deleteCropData = async (userId, farmId, cropId) => {
  try {
    await db
      .collection('Users')
      .doc(userId)
      .collection('farms')
      .doc(farmId)
      .collection('crops')
      .doc(cropId)
      .delete();
      toast.success('Crop Deleted Successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    console.log('Crop data deleted successfully');
  } catch (error) {
    toast.success('Something Went Wrong', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    console.error('Error deleting Crop data:', error);
    throw error;
  }
};

// Function to get all Crops
export const getAllCrops = async (userId, farmId) => {
  try {
    const snapshot = await db
      .collection('Users')
      .doc(userId)
      .collection('farms')
      .doc(farmId)
      .collection('crops')
      .get();
    const crops = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return crops;
  } catch (error) {
    toast.success('Something Went Wrong', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    console.error('Error getting Crops:', error);
    throw error;
  }
};
