/* eslint-disable */
/* @ts-nocheck */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import rootReducer from './reducers';

const middleware = [...getDefaultMiddleware(), reduxImmutableStateInvariant()];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
