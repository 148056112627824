import { getYear, getMonth, getDate, getTime } from "date-fns"

const getSelectedWeekIndex = (selectedDate, weeks, startTime) => {
    const _year = getYear(selectedDate)
    const _month = getMonth(selectedDate)
    const _day = getDate(selectedDate)

    return weeks.reduce((position, week, index) => {
        if (week.find((day) => getTime(day) === getTime(new Date(_year, _month, _day, startTime, 0, 0)))) {
            position = index
        }
        return position
    }, 0)
}

export default getSelectedWeekIndex
