/* eslint-disable */
/* @ts-nocheck */

import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Grid,
  Container,
  Typography,
  MenuItem,
  Chip,
  Slider,
  Select,
  OutlinedInput,
  Box,
  InputLabel,
  FormControl,
} from '@mui/material';

// import { Grid, Container, Typography, LinearProgress, FormControl } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import FlexBox from '../components/FlexBox';

import weekData from '../utils/weekData';
import 'react-calendar/dist/Calendar.css';
import GoogleCalendar from 'src/components/google-calendar/src/App';
import Chart from 'src/components/Chart';

function valuetext(value) {
  return `${value}°C`;
}

export default function DashboardAppPage() {
  const [plotSelectedFilters, setPlotSelectedFilters] = React.useState([]);

  const farmData = useSelector((state) => state.farms);
  function filterData() {
    const newFilteredArray = {};
    Object.keys(weekData).forEach((crop) => {
      if (filterArray.length !== 0) {
        if (filterArray.includes(crop)) {
          newFilteredArray[crop] = weekData[crop].slice(parseInt(value[0], 10) - 1, value[1]);
        }
      } else {
        newFilteredArray[crop] = weekData[crop].slice(parseInt(value[0], 10) - 1, value[1]);
      }
    });
    return newFilteredArray;
  }

  function filterFarms() {
    if (selectedFarm.length > 0) {
      const filteredFarms = farmData.farms.filter((farm) => {
        return selectedFarm.some((filter) => filter === farm.label);
      });
      return filteredFarms;
    }
    return farmData.farms;
  }

  const theme = useTheme();
  const [value, setValue] = React.useState([20, 37]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [filterArray, setFilterArray] = React.useState([]);
  const [filteredArray, setFilteredArray] = React.useState([]);
  const [selectedFarm, setSelectedFarm] = React.useState([]);
  const [selectedCrop, setSelectedCrop] = React.useState([]);
  const [listOfCropFilter, setListOfCropFilter] = React.useState([]);
  const [cropSelectedFilters, setCropSelectedFilters] = React.useState([]);

  const [statusfilter,setStatusFilters] = React.useState([]);
  // useEffect(() => {
  //   setFilteredArray(filterFarms());
  // }, [selectedFarm]);
  console.log(filteredArray);

 
  useEffect(() => {
    setFilteredArray(filterFarms());
    // const allCrops = filteredArray.flatMap((farm) => farm.crops.map((crop) => ({ ...crop, label: crop.label })));
    // console.log(allCrops);
    // setListOfCropFilter(allCrops);
  }, [selectedFarm]);

  useEffect(() => {
    const allCrops = filteredArray.flatMap((farm) => farm.crops.map((crop) => ({ ...crop, label: crop.label })));
    console.log(allCrops);
    setListOfCropFilter(allCrops);
  }, [filteredArray]);

  console.log(listOfCropFilter);
  console.log(selectedFarm);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFarmArrayChange = (event) => {
    const newValues = event.target.value;
    setSelectedFarm((prevValues) => {
      let updatedValues = [...prevValues];

      if (!updatedValues.includes(newValues)) {
        updatedValues.push(newValues);
      } else {
        updatedValues = updatedValues.filter((item) => {
          return newValues !== item;
        });
      }
      return updatedValues;
    });
  };

  const handleCropArrayChange = (event) => {
    const newValues = event.target.value;
    setSelectedCrop((prevValues) => {
      let updatedValues = [...prevValues];

      if (!updatedValues.includes(newValues)) {
        updatedValues.push(newValues);
      } else {
        updatedValues = updatedValues.filter((item) => {
          return newValues !== item;
        });
      }
      return updatedValues;
    });
  };

  const allWeeksEvents = React.useRef({});

  const [pageView, setPageView] = React.useState('Crops');
  function areArraysEqual(array1, array2) {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Check if each element in the arrays is equal
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
  
    // If all elements are equal and the lengths match, the arrays are equal
    return true;
  }
  const handleViewChange = (event) => {};
  const handleViewChangeSelect = (event) => {
    setPageView(event);
  };
  const setListOfCropFilterFunction =(e)=>{
    const isInList =  listOfCropFilter.length === e.length;
    if(!isInList)
    setListOfCropFilter(e)
  }
  const setFilteredDataFunction =(e)=>{
    const isInList = filteredData.length === e.length;
    if(!isInList)
    setFilteredData(e)
  }
  const setPlotSelectedFiltersFunction =(e)=>{
    const isInList = plotSelectedFilters.length === e.length;
    if(!isInList){
      setPlotSelectedFilters(e)
    }

  }
  const setCropSelectedFiltersFunction =(e)=>{
    const isInList = cropSelectedFilters.length === e.length;
    if(!isInList)
    setCropSelectedFilters(e)
  }
  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <GoogleCalendar
    
        pageView={pageView}

        handleViewChangeSelect={handleViewChangeSelect}
        handleViewChange={handleViewChange}
        selectedFarm={selectedFarm}
        handleFarmArrayChange={handleFarmArrayChange}
        farmData={farmData}
        selectedCrop={selectedCrop}
        handleCropArrayChange={handleCropArrayChange}
   
        filteredArray={filteredArray}
        value={value}
      />
    </>
  );
}
