import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { grey, red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SubjectIcon from "@mui/icons-material/Subject";
import { Slider, styled } from "@mui/material";
import { createStyles, createTheme } from "@mui/material/styles";
import { format, getTime } from "date-fns";
import TimeSelect from "../engine_components/TimeSelect";
import Datepicker from "../engine_components/Datepicker";
import { CalendarContext } from "../common/CalendarContext";
import YourTableComponent from "./Table";
import InventoryProduct from "../../../InventoryProduct";
import MultiColorRangeInput from "../../../RangeWithColors";


const Transition = React.forwardRef((props, ref)=> {
    return <Slide direction='down' ref={ref} {...props} />
})

const theme = createTheme();
const useStyles = makeStyles(() =>  createStyles({
    divTitleButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    fullScreenButton: {
        color: theme.palette.grey[900],
    },
    closeButton: {
        // color: theme.palette.grey[900],
        // color: theme.palette.secondary.light,
        // color: red[500],
        // "&:hover": {
        // backgroundColor: red[100],
        // },
    },
    cancelButton: {
        // color: red[500],
        // "&:hover": {
        //     backgroundColor: red[100],
        // },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        // marginTop: -30,
        // width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        // minWidth: 120,
    },
    formControlFlex: {
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    title: {
        marginTop: 0,
    },
    descriptionIcon: {
        // marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    betweenDates: {
        textAlign: "center",
        fontSize: 16,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    datepicker: {
        width: 130,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        "&:hover": {
            backgroundColor: grey[100],
        },
    },
    dayOfWeek: {
        marginLeft: theme.spacing(1),
        color: grey[500],
    },
}))

const interval = 30
const timeOptions = Array.from(Array(24).keys()).reduce((time, hour) => {
    Array.from(Array(60 / interval).keys()).map(i => {
        const timeItem = (+(hour + "." + i * interval)).toFixed(2).replace(".", ":") // eslint-disable-line 
        time.push({ value: timeItem, label: timeItem })
        return null
    })
    return time
}, [])

function CalendarEventDialog(props) {
    const classes = useStyles()

    // const { stateCalendar, setStateCalendar } = useContext(CalendarContext)
    const { stateCalendar, setStateCalendar } = useContext(CalendarContext)
    const {
        modal = false,
        eventDialogMaxWidth = "lg",
        fullscreen = false,
        allowFullScreen = false,
        withCloseIcon = true,
        eventID = 0,
        title,
        description,
        openDialog,
        eventBeginDate,
        eventBeginTime,
        eventEndDate,
        eventEndTime,
        // minutes,
        locale,
    } = stateCalendar
    const handleCloseDialog = () => {
        setStateCalendar({ ...stateCalendar, openDialog: false, openViewDialog: false })
    }

    const [fullScreen, setFullScreen] = useState(false)

    // useEffect(() => {
    //     console.log("useEffect for eventBeginDate", eventBeginDate)
    //     console.log("useEffect for eventEndDate", eventEndDate)
    // }, [eventBeginDate, eventEndDate])

    // useEffect(() => {
    //     console.log("useEffect for eventBeginDate", eventBeginDate)
    //     console.log("useEffect for eventEndDate", eventEndDate)
    // }, [eventBeginDate, eventEndDate])

    const textFieldTitle = useRef(null)
    const [titleTF, setTitleTF] = useState(title)
    const [descriptionTF, setDescriptionTF] = useState(description)

    useEffect(() => {
        setTitleTF(title)
    }, [title])

    useEffect(() => {
        setDescriptionTF(description)
    }, [description])

    return useMemo(() => {
        // console.group('CalendarEventDialog')
        // console.log('memo?!')
        // console.groupEnd()

        const onExited = () => {
            setFullScreen(false)
            setDescriptionTF("")
            setTitleTF("")
        }

        const handleOnClose = () => {
             if(!modal) handleClose()
        }

        const handleClose = () => {
            handleCloseDialog()
        }

        const handleFullScreen = () => {
            setFullScreen(!fullScreen)
        }

        const handleOk = () => {
            const localStorageMarckers = window.localStorage.getItem("markers")
            const markers = (localStorageMarckers && JSON.parse(localStorageMarckers)) || []
            console.log({eventBeginDate},{eventBeginTime})
            const marker = {
                id: eventID > 0 ? eventID : getTime(new Date()),
                title: titleTF,
                begin: format(formatDateTime(eventBeginDate, eventBeginTime.value), "YYYY/MM/DD HH:mm:ss"),
                end: format(formatDateTime(eventEndDate, eventEndTime.value), "YYYY/MM/DD HH:mm:ss"),
                description: descriptionTF,
            }

            console.log({ eventID })
            window.localStorage.setItem(
                "markers",
                JSON.stringify([...markers.filter((markEvent) => markEvent.id !== eventID), marker]),
            )

            handleClose()
        }

        // function handleCancel() {
        //     handleClose()
        // }

        const formatDateTime = (newDate, newTime) => {
            // console.log("formatDateTime: newDate", newDate)
            // console.log("formatDateTime: newTime", newTime)
            const dateTxt = format(newDate, "YYYY/MM/DD")
            return new Date(dateTxt + " " + newTime)  // eslint-disable-line
        }

        const onChangeBeginDate = (newDate) => {
            setStateCalendar({
                ...stateCalendar,
                eventBeginDate: newDate,
                eventEndDate: new Date(format(newDate, "YYYY/MM/DD ") + format(eventEndDate, "HH:mm")),
            })
        }
        const onChangeEndDate = (newDate) => {
            setStateCalendar({ ...stateCalendar, eventEndDate: newDate })
        }

        const onChangeBeginTime = (newValue) => {
            setStateCalendar({ ...stateCalendar, eventBeginTime: newValue })
        }

        const onChangeEndTime = (newValue) => {
            setStateCalendar({ ...stateCalendar, eventEndTime: newValue })
        }

        const dateFormat = "DD/MM/YYYY"

        // const buttonDisabled = titleTF.length <= 0 || eventBeginDate > eventEndDate
        const buttonDisabled =
            eventBeginTime && eventEndTime
                ? formatDateTime(eventBeginDate, eventBeginTime.value) >
                  formatDateTime(eventEndDate, eventEndTime.value)
                : false
              
                const yellowWidth=30
                const greenWidth=40
                const redWidth= 25
                const greyWidth=5
                const trackStyle = {
 
                    '& .MuiSlider-rail': {
                      opacity:1,
                      backgroundImage: `linear-gradient(to right, 
                        #04a78b 0%, 
                        #04a78b ${ greenWidth}%, 
                        #f2bb42  ${ greenWidth}%, 
                        #f2bb42 ${yellowWidth + greenWidth}%, 
                        #cc1c00 ${yellowWidth + greenWidth}%, 
                        #cc1c00 ${greenWidth + yellowWidth + redWidth}%, 
                        #b1b0b0 ${greenWidth + yellowWidth + redWidth}%, 
                        #b1b0b0 ${yellowWidth + greenWidth + redWidth + greyWidth}%)`,
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: 'red', // Change the track color
                    },
                    '& .MuiSlider-thumb': {
                        display:'none',
                      backgroundColor: 'blue', // Change the thumb color
                    },
                   
                 
                
                };
        return (
            <Dialog
            onEntered={() => {
                setTitleTF(title)
                setDescriptionTF(description)
                 if(textFieldTitle.current) textFieldTitle.current.focus()
            }}
            onExited={onExited}
            fullScreen={fullscreen || fullScreen}
            fullWidth
            maxWidth="md" // Adjust the maxWidth as needed
            open={openDialog}
            onClose={handleOnClose}
            aria-labelledby='max-width-dialog-title'
            TransitionComponent={Transition}
            keepMounted={false}

            >
                <DialogTitle>
                    {title}
                    <div className={classes.divTitleButton}>
                        {allowFullScreen ? (
                            <IconButton
                                aria-label='Close'
                                className={classes.fullScreenButton}
                                onClick={handleFullScreen}
                            >
                                {!fullScreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
                            </IconButton>
                        ) : null}
                        {withCloseIcon ? (
                            <IconButton aria-label='Close' className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </div>
                </DialogTitle>
                <DialogContent >
                <div style={{minHeight: '700px'}}>
                <InventoryProduct  {...stateCalendar} />
                <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '-15px',
          marginRight: '-10px',
          fontSize: '10px',
        }}
      >
        <Typography style={{ textAlign: 'start', width: `${20}%`, fontSize: '15px', }}>Sold</Typography>
        <Typography style={{ textAlign: 'end', width: `${yellowWidth}%` , fontSize: '15px', marginRight: '-100px',}}>
        Expected Sales
        </Typography>
        <Typography style={{ textAlign: 'end', width: `${greenWidth}%`, fontSize: '15px', marginRight: '-100px',}}>
        Available for Sales
        </Typography>
        <Typography style={{ textAlign: 'end', width: `${redWidth + 10}%`, fontSize: '15px',marginLeft:'-30px' }}>
   Surplus
        </Typography>
      </div>
                <Slider
        min={0}

        max={stateCalendar.crop?.late?.percentage ? stateCalendar.crop.late.percentage : 15}
        step={1}
    
       
        disabled
        track={false}
        sx={{
          ...trackStyle,
          height: 15,
          borderRadius: 15,
          margin: '10px 0',
        }}
    
      />
        <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '-15px',
          marginRight: '40px',
          fontSize: '10px',
        }}
      >
        <Typography style={{ textAlign: 'start', width: `${20}%`, fontSize: '12px', }}>4tkg</Typography>
        <Typography style={{ textAlign: 'end', width: `${yellowWidth}%` , fontSize: '12px'}}>
        8t
        </Typography>
        <Typography style={{ textAlign: 'end', width: `${greenWidth}%`, fontSize: '12px'}}>
        5.5t
        </Typography>
        <Typography style={{ textAlign: 'end', width: `${redWidth + 10}%`, fontSize: '12px',marginLeft:'-30px' }}>
       2t
        </Typography>
      </div>
              <div style={{width:'60%',marginBottom:'20px'}}>
              {/* <MultiColorRangeInput
                {...stateCalendar}
                    yellowWidth={stateCalendar.crop?.early?.percentage ? stateCalendar.crop.early.percentage : 30}
                    greenWidth={stateCalendar.crop?.optimal?.percentage ? stateCalendar.crop.optimal.percentage : 40}
                    redWidth={stateCalendar.crop?.late?.percentage ? stateCalendar.crop.late.percentage : 15}
                    greyWidth={0}
                    data={stateCalendar.crop}
                 
                  /> */}
              </div>
             
                <YourTableComponent/>
               
                </div>
       
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color='primary' disabled={buttonDisabled}>
                        Close
                    </Button>
                    {/* <Button onClick={handleCancel} className={classes.cancelButton}> */}
                    {/* cancel */}
                    {/* </Button> */}
                </DialogActions>
            </Dialog>
        )
        // ....
        // ....
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        openDialog,
        titleTF,
        descriptionTF,
        eventBeginDate,
        eventBeginTime,
        eventEndDate,
        eventEndTime,
        allowFullScreen,
        classes,
        eventDialogMaxWidth,
        eventID,
        fullScreen,
        fullscreen,
        handleCloseDialog,
        locale,
        modal,
        title,
        description,
        withCloseIcon,
    ])
}

export default CalendarEventDialog
