import { Grid, Paper, styled, Typography, Card, CardContent, Container, Button, Box, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SideOrder = (props) => {
  const [selected, setSelected] = React.useState(false);

  const handleSelect = () => {
    setSelected(!selected);
  };
  return (
    <Card
      style={{
        marginBottom: 15,
        backgroundColor: selected ? 'rgba(254, 231, 231, 0.8)' : 'white',
        border: selected ? '1px solid red' : 'none',
        cursor: 'pointer',
      }}
      onClick={() => handleSelect()}
    >
      <CardContent>
        <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Grid item xs={6} md={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="span" sx={{ fontWeight: '300' }}>
              {props.data.productName}
            </Typography>
            <br />
            <Typography variant="span" sx={{ fontWeight: '300', fontSize: '10pt' }}>
              {props.data.clientName}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '15px' }}>
              <Typography variant="span" sx={{ fontWeight: '300' }}>
                {props.data.status}
              </Typography>
              <span
                style={{
                  width: '10px',
                  backgroundColor:
                    props.data.status === 'Accepted'
                      ? 'limegreen'
                      : props.data.status === 'Rejected'
                      ? 'red'
                      : props.data.status === 'Cancelled'
                      ? 'orange'
                      : 'cyan',
                  height: '10px',
                  borderRadius: '10px',
                }}
              />
            </span>

            <br />
            <Typography variant="span" sx={{ fontWeight: '300', fontSize: '9pt' }}>
              {props.data.deliveryDate}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SideOrder;
