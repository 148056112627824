import { useDispatch, useSelector, connect } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Grid, Paper, styled, Typography, Box, LinearProgress, TextField, Button, Card, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteFarm } from '../store/farms';
import FilterContainer from '../components/FilterContainer';

import SliderDate from '../components/slider-date-filter';
import AddPlot from '../components/add-plot';
import PlotsCrops from '../components/plots-crops';
import MultiSelect from '../components/chip-multi-select';
import { deleteFarmData, getAllFarmsByUserId } from '../firebase/fucntions';

function FarmPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listOfFilter, setListOfFilter] = React.useState([]);
  const [listOfCropFilter, setListOfCropFilter] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const farmData = useSelector((state) => state.farms);
  const [plotSelectedFilters, setPlotSelectedFilters] = React.useState([]);
  const [cropSelectedFilters, setCropSelectedFilters] = React.useState([]);
  const [ratingSelectedFilters, setRatingSelectedFilters] = React.useState([]);
  const [minWeek, setMinWeek] = React.useState(0);
  const [maxWeek, setMaxWeek] = React.useState(52);
  const location = useLocation();
  useEffect(() => {
    setListOfFilter(farmData.farms);
    setFilteredData(farmData.farms);
  }, [farmData]);

  useEffect(() => {
    setFilteredData(filterFarmsByWeek(onFilterRatingChange(filterCrops(onFilterPlotChange()))));
    const allCrops = onFilterPlotChange().flatMap((farm) =>
      farm?.crops ? farm?.crops?.map((crop) => ({ ...crop, label: crop.label })) : []
    );
    setListOfCropFilter(allCrops);
  }, [ratingSelectedFilters, plotSelectedFilters, cropSelectedFilters, minWeek, maxWeek]);

  const onFilterPlotChange = () => {
    if (plotSelectedFilters.length > 0) {
      const filteredFarms = farmData.farms.filter((farm) => {
        return plotSelectedFilters.some((filter) => filter.label === farm.label);
      });
      return filteredFarms;
    }
    return farmData.farms;
  };

  const filterCrops = (filteredData) => {
    if (cropSelectedFilters.length > 0) {
      const filteredFarms = filteredData
        .filter((farm) => {
          const filteredCrops = farm.crops.filter((crop) => {
            return cropSelectedFilters.some((filter) => filter.label === crop.label);
          });
          return filteredCrops.length > 0;
        })
        .map((farm) => {
          return {
            ...farm,
            crops: farm.crops.filter((crop) => {
              return cropSelectedFilters.some((filter) => filter.label === crop.label);
            }),
          };
        });
      return filteredFarms;
    }
    return filteredData;
  };

  function filterFarmsByWeek(farms) {
    return farms.filter((farm) => farm.weekStart >= minWeek && (farm.weekEnd ? farm.weekEnd <= maxWeek : true));
  }
  const onFilterRatingChange = (filteredData) => {
    if (ratingSelectedFilters.length > 0) {
      const filteredFarms = filteredData.filter((farm) => {
        return ratingSelectedFilters.some((filter) => filter.label === farm.rating);
      });
      return filteredFarms;
    }
    return filteredData;
  };
  function getWeek(date) {
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const diff = date - yearStart + (yearStart.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    const week = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return week;
  }
  const onDateChange = (date) => {
    const minWeek = getWeek(new Date(date[0]));
    const maxWeek = getWeek(new Date(date[1]));
    setMinWeek(minWeek);
    setMaxWeek(maxWeek);
    console.log(minWeek, maxWeek);
  };

  const [selectedCrop, setSelectedCrop] = React.useState(null);
  const myRef = React.useRef(null);
  const user = useSelector((state) => state.user.user);
  const handleRefChange = async (crop) => {
    // myRef.current.style.display = 'flex';
    setSelectedCrop(crop);
  };

  const handleClick = async (farmid) => {
    const confirmed = window.confirm('Are you sure you want to delete?');
    if (confirmed) {
      await deleteFarmData(user.uid, farmid);

      dispatch(deleteFarm(farmid));
      // alert(JSON.stringify(props.data))
      // setOrdersList(prev => [{...props.data,status:'Pending'}, ...prev]);
      // Delete the item
    } else {
      // Do nothing
    }
  };

  return (
    <Grid container spacing={7} sx={{ ...gridStyles, ...tallGrid }}>
      <Grid item xs={11} md={2}>
        <Contents>
          <Typography sx={typographyStyle} variant="span">
            Plots
          </Typography>
          <MultiSelect onFilterChange={setPlotSelectedFilters} listOfFilterProp={listOfFilter} />
        </Contents>
        <Divider sx={{ marginBottom: 1 }} />
        <Contents>
          <Typography sx={typographyStyle} variant="span">
            Crops
          </Typography>
          <MultiSelect onFilterChange={setCropSelectedFilters} listOfFilterProp={listOfCropFilter} />
        </Contents>
        <Divider sx={{ marginBottom: 1 }} />
        <Contents>
          <Typography variant="span">Ratings</Typography>
          <MultiSelect onFilterChange={setRatingSelectedFilters} listOfFilterProp={ratingFilters} rate />
        </Contents>
        <Divider sx={{ marginBottom: 1 }} />
        <Contents>
          <Typography variant="span">Date Range</Typography>
          <SliderDate onChange={onDateChange} />
        </Contents>
      </Grid>
      <Grid item xs={11} md={10} sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        {filteredData.reverse().map((farm) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px', width: 'auto' }}>
           
              <Grid
                container
                sx={{ display: 'flex', flexDirection: 'column', marginBottom: 0, paddingBottom: 0, maxWidth: '600px' }}
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',

                    maxWidth: '800px',
                  }}
                >
                  <Box style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', alignItems: 'center' }}>
                    <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{`${farm.value} (${farm.Area} ha)`}</span>
                    {farm.Coordinates}
                  </Box>
                </Grid>

                <Grid item sm={12} sx={{ alignSelf: 'flex-start' }}>
                  <Button
                    onClick={() => {
                      navigate('/dashboard/farm_detail', { state: { myFarm: farm,farmID:farm.id } });
                    }}
                  >
                    Edit <EditIcon style={{ marginRight: 5, marginLeft: 5 }} />
                    {/* <RemoveRedEyeOutlinedIcon /> */}
                  </Button>
                  {/* <Button onClick={() => handleClick(farm.id)}>
                  View Details <RemoveRedEyeOutlinedIcon  style={{marginRight:5,marginLeft:5}} />
                  </Button> */}
                </Grid>
              </Grid>
              <Box
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -50, paddingTop: 0 }}
              >
                {farm.crops
                  .flatMap((singleCrop) => (singleCrop.Area ? [singleCrop.Area] : []))
                  .reduce((acc, curr) => Number(acc) + Number(curr), 0)}{' '}
                ha planted,{' '}
                {farm.Area -
                  farm.crops
                    .flatMap((singleCrop) => (singleCrop.Area ? [singleCrop.Area] : []))
                    .reduce((acc, curr) => Number(acc) + Number(curr), 0)<0?0:farm.Area -
                    farm.crops
                      .flatMap((singleCrop) => (singleCrop.Area ? [singleCrop.Area] : []))
                      .reduce((acc, curr) => Number(acc) + Number(curr), 0)}{' '}
                ha unplanted
              </Box>
              {farm?.crops.length === 0 && (
                <Box sx={{ color: '#02A78B' }}>
                  <AddPlot
                    onClick={() => {
                      navigate(`/dashboard/step2`, { state: { uid: farm.id, farmName: farm.value } });
                    }}
                    name="Crop"
                    color={'#E0F0ED'}
                  />
                </Box>
              )}
              {farm?.crops && (
                <Grid
                  className="d-flex"
                  style={{
                    overflowX: 'scroll',
                    display: 'flex',
                    flexDirection: 'row',
                    borderRadius: '0px',
                    padding: '20px',
                    paddingLeft: 0,
                    width: '95%',
                  }}
                >
                  {farm?.crops.map((crop, i) => {
                    console.log(i);
                    return (
                      <Card
                        sx={{
                          flexGrow: '1',
                          flexBasis: i % 3 === 0 ? '22%' : 'calc(22% - 20px)',
                          flexShrink: 0,
                          marginBottom: '0px',
                          height: '140px',
                          color: '#02A78B',
                          display: 'flex',
                          flexDirection: 'row',
                          maxWidth: '300px',
                          alignItems: 'center',
                          padding: '10px',
                          columnGap: '10px',
                          borderTopLeftRadius: i % 3 === 0 ? '15px' : 'none',
                          borderBottomLeftRadius: i % 3 === 0 ? '15px' : 'none',
                          borderTopRightRadius: (i + 1) % 3 === 0 ? '15px' : 'none',
                          borderBottomRightRadius: (i + 1) % 3 === 0 ? '15px' : 'none',
                          borderRadius: '0px',
                        }}
                        className="shadow"
                      >
                        <div style={{ maxWidth: '80px' }}>
                          <img src={crop.Pictures} alt="" />
                        </div>
                        <div
                          style={{
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span style={{ color: '#02A78B', fontWeight: 'bold' }}>{crop.Variety}</span>
                            <Box
                              sx={{
                                border: '1px solid #02A78B',
                                borderRadius: '10px',
                                width: '40%',
                                height: '40px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                color: '#02A78B',
                                marginTop: 1,
                              }}
                            >
                              <span>{crop.Area}</span>
                              <span>ha</span>
                            </Box>
                          </div>

                          <div style={{ width: '100%' }}>{/* LinearProgress and other elements */}</div>
                        </div>
                      </Card>
                    );
                  })}

                  {farm?.crops.length > 0 && (
                    <Card
                      sx={{
                        flexGrow: '1',
                        flexBasis: 'calc(22% - 20px)',
                        flexShrink: 0,
                        marginBottom: '0px',
                        marginRight: '10px',
                        height: '140px',
                        color: '#02A78B',
                        display: 'flex',
                        flexDirection: 'row',
                        maxWidth: '300px',
                        alignItems: 'center',
                        columnGap: '10px',
                        borderRadius: '0px',
                      }}
                      className="shadow"
                    >
                      <AddPlot
                        onClick={() => {
                          navigate(`/dashboard/step2`, { state: { uid: farm.id, farmName: farm.value } });
                        }}
                        style={{ borderRadius: '0px' }}
                        name="Crop"
                        color={'#E0F0ED'}
                      />
                    </Card>
                  )}
                </Grid>
              )}

              <Grid container sx={{ width: '95%', display: 'flex', justifyContent: 'end' }}>
                <Button
                  onClick={() => {
                    navigate('/dashboard/farm_detail', { state: { myFarm: farm } });
                  }}
                  sx={{
                    width: '150px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-center',

                    border: '1px solid #E0F0ED',
                    borderRadius: '5px',
                    padding: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',

                      columnGap: '5px',
                      textAlign: 'end',
                    }}
                  >
                    View Details <RemoveRedEyeOutlinedIcon />
                  </Box>
                </Button>
              </Grid>

              <Divider />
            </div>
          );
        })}
        <AddPlot
          onClick={() => {
            navigate(`/dashboard/create`);
          }}
          name="Plot"
          color={'#EEEEEE'}
        />

        <br />
        <br />
      </Grid>
    </Grid>
  );
}
export default FarmPage;

const ratingFilters = [
  {
    label: '1',
  },
  {
    label: '2',
  },
  {
    label: '3',
  },
  {
    label: '4',
  },
  {
    label: '5',
  },
];
const gridStyles = {
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 1,
  paddingBottom: 1,
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const Contents = styled(Paper)(({ theme }) => ({
  marginBottom: 20,
}));

const typographyStyle = {
  fontWeight: '350',
  marginBottom: 5,
};
const tallGrid = {
  height: '100%',
};
