import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Typography, TextField, Button, Link, Container, Card } from '@mui/material';
import { registerUser } from '../firebase/fucntions';

const Register = () => {
    const navigate = useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform registration logic here
    registerUser(formData).then(()=>{
    
        navigate('/dashboard/app')
    })
    console.log(formData);
  };

  return (
    <>
      <Helmet>
        <title>Register | Minimal UI</title>
      </Helmet>

      <Container maxWidth="sm" sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card sx={{ padding: 5 }}>
          <Typography variant="h4" gutterBottom>
            Register
          </Typography>

          <Typography variant="body2" sx={{ mb: 5 }}>
            Already have an account?{' '}
            <Link href="/login" variant="subtitle2" sx={{ color: '#02A78B' }}>
              Login
            </Link>
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              margin="normal"
              fullWidth
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              margin="normal"
              fullWidth
              required
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              fullWidth
              required
            />
            <TextField
              label="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              margin="normal"
              fullWidth
              required
              type="password"
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              margin="normal"
              fullWidth
              required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Register
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
};

export default Register;
